import {FilterType} from "../filter-type";
import {ApiService} from "../../api/api.service";
import {MenuItem} from "primeng/api";
import {Observable} from "rxjs";
import {FilterResult} from "../filter-result";
import {HttpErrorResponse} from "@angular/common/http";
import {KeyValue} from "@angular/common";
import {FilterResultType} from "../filter-result-type";

export abstract class AbstractFilterLoader<T> {
    type: FilterType;
    apiService: ApiService;
    notifier: Observable<any>;
    filterResult: FilterResult;
    onStateChanged: () => void;
    onSelectionChanged: (needsUpdate:FilterType[]) => void;
    protected result: MenuItem;


    constructor(type: FilterType,
                apiService: ApiService,
                notifier: Observable<any>,
                filterResult: FilterResult,
                onStateChanged: () => void,
                onSelectionChanged: (needsUpdate:FilterType[]) => void) {
        this.type = type;
        this.apiService = apiService;
        this.notifier = notifier;
        this.filterResult = filterResult;
        this.onStateChanged = onStateChanged;
        this.onSelectionChanged = onSelectionChanged;
    }

    protected abstract getDataFromApi(onSuccess: (data: T) => void): void;

    protected abstract convertDataToMenuItems(data: T, filterResult: FilterResult): MenuItem;

    protected abstract getFiltersToUpdateAfterChange():FilterType[];

    loadData(filterResult: FilterResult, onSuccess: (data: MenuItem) => void) {
        this.getDataFromApi(data => {
            onSuccess(this.convertDataToMenuItems(data, filterResult));
        })
    }

    updateWithError(error: HttpErrorResponse) {
        this.filterResult.error = error;
        this.onStateChanged();
    }

    updateProgress(progress: boolean) {

        this.filterResult.updateProgress({key: this.type, value: progress});
        this.onStateChanged();
    }

    onItemSelectedManually(){
        this.onSelectionChanged(this.getFiltersToUpdateAfterChange());
    }

    onItemSelected(){
        this.onStateChanged();
    }
}
