<p-dialog [(visible)]="display"
          (visibleChange)="displayChange.emit(display)"
          [modal]="true">
    <ng-template pTemplate="header">
        <h2>{{'select_date'|etotranslate}}</h2>
    </ng-template>
    <div class="calendar-container">
        <p-calendar
                    [inputStyle]="{width:'100%'}"
                    [firstDayOfWeek]="getFirstDayIndex()"
                    [(ngModel)]="date"
                    [inline]="true"
                    (onSelect)="onSelected($event)"
                    [showWeek]="false"></p-calendar>
    </div>

    <ng-template pTemplate="footer"></ng-template>
</p-dialog>
