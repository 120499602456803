import {SiteType} from "../../model/api/response/site-type";
import {EtoTranslation} from "../translation/eto-translation";
import * as moment from "moment";
import {DateFormatter} from "./date-formatter";
import {ReservationState} from "../../model/api/response/reservation-state";
import {Job} from "../../model/api/response/job";
import {OpeningHour} from "../../model/api/response/opening-hour";
import {DayIndex} from "../../model/api/response/day-index";

export class LabelUtil {
    public static formatCarServiceTypes(types: SiteType[]): string {
        return types.map(value => this.typeToString(value)).join(", ");
    }

    public static typeToString(type: SiteType): string {
        switch (type) {
            case SiteType.MOT:
                return EtoTranslation.getInstant('mot');
            case SiteType.CAR_SERVICE:
                return EtoTranslation.getInstant('carservice');
            case SiteType.TIRE_SERVICE:
                return EtoTranslation.getInstant('tireservice');
            default:
                return EtoTranslation.getInstant('empty');
        }
    }

    public static formatFullAddress(address: string, zipCode: string, city: string) {
        return [address, zipCode, city].join(", ");
    }

    public static formatDistance(distance: number) {
        return !distance ? EtoTranslation.getInstant('empty') : EtoTranslation.getInstant('distance_from_you', {distance: distance.toFixed(2)});
    }

    public static formatNearestDistance(distance: number) {
        return !distance ? EtoTranslation.getInstant('empty') : EtoTranslation.getInstant('nearest_distance_from_you', {distance: distance.toFixed(2)});
    }

    public static formatPromoTime(date: Date) {
        let reservationDate = moment(date);
        if (LabelUtil.isToday(reservationDate.toDate())) {
            return EtoTranslation.getInstant('today').toLowerCase();
        }

        if (LabelUtil.isTomorrow(reservationDate.toDate())) {
            return EtoTranslation.getInstant('tomorrow').toLowerCase();
        }

        if (LabelUtil.isSoon(reservationDate.toDate())) {
            return EtoTranslation.getInstant('soon').toLowerCase();
        }
        return DateFormatter.dateToStringWithYear(date);
    }

    public static formatReadableDate(date: Date):string{
        if (this.isToday(date)){
            return EtoTranslation.getInstant('today');
        }
        if (this.isTomorrow(date)){
            return EtoTranslation.getInstant('tomorrow');
        }
        else {
            return this.getReadableDay(date);
        }
    }

    public static getReadableDay(date: Date): string{
        return this.capitalize(moment(date).locale(EtoTranslation.getSelectedLang()).format('dddd'));
    }

    public static isTomorrow(reservationDate: Date) {
        let now = moment();
        return moment(reservationDate).isAfter(moment(now).startOf('day'))
            && moment(reservationDate).isBefore(moment(now).add(2, 'days').startOf("day"));
    }

    public static isSameDay(reservationDate: Date, targetDate: Date) {
        return moment(reservationDate).isAfter(moment(targetDate).startOf('day'))
            && moment(reservationDate).isBefore(moment(targetDate).add(1, 'days').startOf("day"));
    }

    public static isThisWeek(reservationDate: Date) {
        let now = moment();
        return moment(reservationDate).isAfter(moment(now).locale(EtoTranslation.getSelectedLang()).startOf('week'))
            && moment(reservationDate).isBefore(moment(now).locale(EtoTranslation.getSelectedLang()).endOf('week'));
    }

    public static isNextWeek(reservationDate: Date) {
        let now = moment();
        return moment(reservationDate).isAfter(moment(now).locale(EtoTranslation.getSelectedLang()).add(1, 'week').startOf('week'))
            && moment(reservationDate).isBefore(moment(now).locale(EtoTranslation.getSelectedLang()).add(1, 'week').endOf('week'));
    }

    public static isThisMonth(reservationDate: Date) {
        let now = moment();
        return moment(reservationDate).isAfter(moment(now).startOf('month'))
            && moment(reservationDate).isBefore(moment(now).endOf('month'));
    }

    public static isSoon(reservationDate: Date) {
        let now = moment();
        return moment(reservationDate).isAfter(moment(now).startOf('day'))
            && moment(reservationDate).isBefore(moment(now).add(7, 'days').startOf("day"));
    }

    public static isToday( reservationDate: Date) {
        let now = moment();
        return moment(reservationDate).isAfter(moment(now).startOf('day'))
            && moment(reservationDate).isBefore(moment(now).add(1, 'days').startOf("day"));
    }

    public static capitalize(input: string): string{
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    public static formatPrice(price: string): string{
        return EtoTranslation.getInstant('price_value', {price: price});
    }

    public static formatPriceFrom(price: string): string{
        return EtoTranslation.getInstant('min_price_value', {price: price});
    }

    static getReservationStateLabel(state: ReservationState) {
        switch (state) {
            case ReservationState.CANCELLED:
                return EtoTranslation.getInstant('reservation_state_cancelled');
            case ReservationState.DONE:
                return EtoTranslation.getInstant('reservation_state_done');
            case ReservationState.IN_PROGRESS:
                return EtoTranslation.getInstant('reservation_state_in_progress');
            case ReservationState.PENDING:
            default:
                return EtoTranslation.getInstant('reservation_state_pending');

        }
    }

    public static getReservationDateFull(date: Date): string{
        let dateFormatted = DateFormatter.toUIDayFormat(date);
        let day = LabelUtil.getReadableDay(date);
        let time = DateFormatter.timeToString(date);
        return `${dateFormatted} (${day}) | ${time}`;
    }

    public static getReservationDatShort(date: Date): string{
        let dateFormatted = DateFormatter.toUIDayFormat(date);
        let time = DateFormatter.timeToString(date);
        return `${dateFormatted} | ${time}`;
    }

    public static getJobTitle(job: Job): string{
        let result = job.name
        if (!!job.description) {
            result = `${result}(${job.description})`;
        }
        return result;
    }

    static formatOpeningHour(hour: OpeningHour) {
        const day = this.toDayText(hour.dayIndex);
        const hours = !hour.from && !hour.to
            ? EtoTranslation.getInstant('closed')
            : `${this.minutesToHours(hour.from)} - ${this.minutesToHours(hour.to)}`
        return `${day}: ${hours}`
    }

    public static minutesToHours(minutes: number):string{
        const hours = (minutes/60).toLocaleString(undefined, {minimumIntegerDigits:2, maximumFractionDigits:0});
        const mins = (minutes%60).toLocaleString(undefined, {minimumIntegerDigits:2, maximumFractionDigits:0});
        return `${hours}:${mins}`;
    }

    public static toDayText(dayIndex:DayIndex):string{
        switch (dayIndex) {
            case DayIndex.MONDAY:
                return this.getReadableDay(moment().set("weekday",1).toDate())
            case DayIndex.TUESDAY:
                return this.getReadableDay(moment().set("weekday",2).toDate())
            case DayIndex.WEDNESDAY:
                return this.getReadableDay(moment().set("weekday",3).toDate())
            case DayIndex.THURSDAY:
                return this.getReadableDay(moment().set("weekday",4).toDate())
            case DayIndex.FRIDAY:
                return this.getReadableDay(moment().set("weekday",5).toDate())
            case DayIndex.SATURDAY:
                return this.getReadableDay(moment().set("weekday",6).toDate())
            case DayIndex.SUNDAY:
            default:
                return this.getReadableDay(moment().set("weekday",0).toDate())

        }
    }

}
