<p-dialog [(visible)]="display"
          (visibleChange)="displayChange.emit(display)"
          [modal]="true">
    <ng-template pTemplate="header">
        <h2>{{getTitle()}}</h2>
    </ng-template>
    <div class="vehicle-modal">
        <div *ngIf="!isLocalVehicle()">
            <p style="margin-bottom:8px">{{appendAsterisk('vehicle_license'|etotranslate)}}</p>
            <input style="width:100%" type="text" [placeholder]="'vehicle_license_hint'|etotranslate"
                   [(ngModel)]="license" pInputText>
            <p style="margin-top:16px;margin-bottom:8px">{{appendAsterisk('vehicle_description'|etotranslate)}}</p>
            <input style="width:100%" type="text" [placeholder]="'vehicle_description_hint'|etotranslate"
                   [(ngModel)]="description" pInputText>
        </div>
        <div *ngFor="let option of optionsMenuItems">
            <div style="display:flex;flex-direction:row">
            <p style="margin-top:16px;margin-bottom:8px" [pTooltip]="option.tooltip">{{option.label}}</p>
                <i class="pi pi-info-circle font-icon" style="margin-top:16px;margin-left:8px;margin-bottom:8px;font-size:1rem" [pTooltip]="option.tooltip" *ngIf="!!option.tooltip"></i>
            </div>
            <p-dropdown [appendTo]="'body'" [options]="option.options" [(ngModel)]="option.selected" optionLabel="label">
                <ng-template let-option pTemplate="item">
                    <div [pTooltip]="option.tooltip" tooltipPosition="right">
                        {{ option.label }}
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple style="width:100%" type="button"
                [loading]="loadingOptions"
                [label]="'save'|etotranslate"
                [disabled]="loadingOptions || !saveEnabled()"
                (click)="onSaveClicked()"
                class="p-button">
        </button>
    </ng-template>
</p-dialog>

