export enum TimeWindow{
    //0.00 - 23.59
    ALL_DAY = 'ALL_DAY',
    //0.00 - 9.59
    MORNING = 'MORNING',
    //10.00 - 11.59
    LATE_MORNING = 'LATE_MORNING',
    //12.00 - 15.59
    AFTERNOON = 'AFTERNOON',
    //16.00 - 23.59
    EVENING = 'EVENING'
}
