import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {VacancyResult} from "../../../model/api/response/vacancy-result";
import {LabelUtil} from "../../../service/util/label-util";
import {DateFormatter} from "../../../service/util/date-formatter";
import {EtoTranslation} from "../../../service/translation/eto-translation";
import {Vacancy} from "../../../model/api/response/vacancy";
import {VacancyCard, VacancyItemType} from "../../../model/ui/vacancy/vacancy-card";
import {SingleVacancyCard} from "../../../model/ui/vacancy/single-vacancy-card";
import {MultiVacancyCard} from "../../../model/ui/vacancy/multi-vacancy-card";
import {GroupVacancyCard} from "../../../model/ui/vacancy/group-vacancy-card";
import {VacancyUtil} from "../../../service/util/vacancy-util";
import {MarketingVacancyCard} from "../../../model/ui/vacancy/marketing-vacancy-card";
import {PromoMessageUtil} from "../../../service/promo/promo-message-util";

@Component({
  selector: 'app-vacancy-item',
  templateUrl: './vacancy-item.component.html',
  styleUrls: ['./vacancy-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VacancyItemComponent extends EtoComponent{

  @Input()
  vacancyCard: VacancyCard;
  @Input()
  vehicleCreated: boolean;

  @Output()
  onMultiCardClicked: EventEmitter<MultiVacancyCard> = new EventEmitter<MultiVacancyCard>();
  @Output()
  onGroupCardClicked: EventEmitter<GroupVacancyCard> = new EventEmitter<GroupVacancyCard>();
  @Output()
  onSingleCardClicked: EventEmitter<SingleVacancyCard> = new EventEmitter<SingleVacancyCard>();
  @Output()
  onMarketingCardClicked: EventEmitter<MarketingVacancyCard> = new EventEmitter<MarketingVacancyCard>();

  shouldDisplaySimpleCard() {
    return !this.shouldDisplayShimmerCard() && this.vacancyCard.type == VacancyItemType.SINGLE_CARD;
  }

  shouldDisplayMultiCard() {
    return !this.shouldDisplayShimmerCard() && this.vacancyCard.type == VacancyItemType.MULTI_CARD;
  }

  shouldDisplayMarketingCard() {
    return !this.shouldDisplayShimmerCard() && this.vacancyCard.type == VacancyItemType.MARKETING_CARD;
  }

  shouldDisplayShimmerCard() {
    return !this.vacancyCard;
  }

  getReadableDate() {
    if (this.shouldDisplaySimpleCard()){
      const vacancy = (this.vacancyCard as SingleVacancyCard).vacancy;
      if (LabelUtil.isToday(vacancy.startDate) || LabelUtil.isTomorrow(vacancy.startDate)){
        return `${LabelUtil.formatReadableDate(vacancy.startDate)} ${this.getDate(vacancy.startDate)}`
      }
      else{
        return this.getDate(vacancy.startDate)
      }
    }
    else if (this.shouldDisplayMultiCard()){
      const vacancy = (this.vacancyCard as MultiVacancyCard).cards[0].vacancy;
      if (LabelUtil.isToday(vacancy.startDate) || LabelUtil.isTomorrow(vacancy.startDate)){
        return `${LabelUtil.formatReadableDate(vacancy.startDate)} ${this.getDate(vacancy.startDate)}`
      }
      else{
        return this.getDate(vacancy.startDate)
      }
    }
    return EtoTranslation.getInstant('na');
  }

  getDate(date: Date) {
    if (this.isHandsetPortrait){
      return DateFormatter.dateToStringShort(date);
    }
    return DateFormatter.dateToStringWithYear(date);
  }

  getTime() {
    if (this.shouldDisplaySimpleCard()){
      const vacancy = (this.vacancyCard as SingleVacancyCard).vacancy;
      return DateFormatter.timeToString(vacancy.startDate);
    }
    else if (this.shouldDisplayMultiCard()){
      const vacancy = (this.vacancyCard as MultiVacancyCard).cards[0].vacancy;
      return DateFormatter.timeToString(vacancy.startDate);
    }
    return EtoTranslation.getInstant('na');
  }

  getPrice(): string{
    if (this.shouldDisplaySimpleCard()){
      const vacancy = (this.vacancyCard as SingleVacancyCard).vacancy;
      return this.determinePrice(vacancy);
    }
    if (this.shouldDisplayMultiCard()){
      return LabelUtil.formatPriceFrom((this.vacancyCard as MultiVacancyCard).minPrice.toString());
    }
    if (this.shouldDisplayGroupCard()){
      return LabelUtil.formatPriceFrom((this.vacancyCard as GroupVacancyCard).minPrice.toString());
    }
    return EtoTranslation.getInstant('na');
  }

  private determinePrice(vacancy: Vacancy) {
    const guaranteedPrice = !!vacancy.priceMetadata && vacancy.priceMetadata.guaranteedPrice;
    if (!guaranteedPrice || !this.vehicleCreated) {
      return LabelUtil.formatPriceFrom(vacancy.price.toString());
    } else {
      return LabelUtil.formatPrice(vacancy.price.toString());
    }
  }

  getServiceName() {
    if (this.shouldDisplaySimpleCard()){
      return (this.vacancyCard as SingleVacancyCard).vacancy.carService.name;
    }
    return EtoTranslation.getInstant('na');
  }

  getAddress() {
    if (this.shouldDisplaySimpleCard()){
      const site = (this.vacancyCard as SingleVacancyCard).vacancy.carService;
      return LabelUtil.formatFullAddress(site.address, site.zipCode, site.city);
    }
    return EtoTranslation.getInstant('na');
  }


  onVacancyClicked() {
    if (this.shouldDisplayGroupCard()){
      this.onGroupCardClicked.emit((this.vacancyCard as GroupVacancyCard));
    }
    if (this.shouldDisplayMultiCard()){
      this.onMultiCardClicked.emit((this.vacancyCard as MultiVacancyCard));
    }
    if (this.shouldDisplaySimpleCard()){
      this.onSingleCardClicked.emit((this.vacancyCard as SingleVacancyCard));
    }
    if (this.shouldDisplayMarketingCard()){
      this.onMarketingCardClicked.emit((this.vacancyCard as MarketingVacancyCard));
    }
  }

  shouldDisplayGroupCard() {
    return !this.shouldDisplayShimmerCard() && this.vacancyCard.type == VacancyItemType.GROUP_CARD;
  }

  getSitesCountText() {
    if (this.shouldDisplayMultiCard()){
      let count = (this.vacancyCard as MultiVacancyCard).cards.length;
      return EtoTranslation.getInstant(count<5 ? 'sites_for_date' : '5_sites_for_date', {count: count})
    }
    return EtoTranslation.getInstant('na');
  }

  getGroupTitle() {
    if (this.shouldDisplayGroupCard()){
      return (this.vacancyCard as GroupVacancyCard).title;
    }
    return EtoTranslation.getInstant('na');
  }

  getGroupVacanciesCount() {
    if (this.shouldDisplayGroupCard()){
      return VacancyUtil.getVacanciesCountText((this.vacancyCard as GroupVacancyCard).vacanciesCount);
    }
    return EtoTranslation.getInstant('na');
  }

  shouldRenderSmallMarketingCard() {
    return this.shouldDisplayMarketingCard() && (this.vacancyCard as MarketingVacancyCard).small;
  }

  getBackgroundImageUrl() {
    return PromoMessageUtil.getBackgroundImageUrl((this.vacancyCard as MarketingVacancyCard).message);
  }

  getMarketingCardTitle() {
    return PromoMessageUtil.getTitle((this.vacancyCard as MarketingVacancyCard).message);
  }

  getMarketingCardText() {
    return PromoMessageUtil.getText((this.vacancyCard as MarketingVacancyCard).message);
  }
}
