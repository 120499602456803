import {Destroy} from "ngx-reactivetoolkit";
import {AfterViewInit, Component, Injector, Input, OnInit, ViewContainerRef} from "@angular/core";
import {ApiService} from "../service/api/api.service";
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {finalize, takeUntil} from "rxjs/operators";
import {ConfirmationService, MessageService, PrimeNGConfig} from "primeng/api";
import {EtoTranslation} from "../service/translation/eto-translation";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {Location} from "@angular/common";
import {AnalyticsService} from "../service/analytics/analytics-service";
import {NgxSmartBannerService} from "@netcreaties/ngx-smart-banner";
import {PromoMessageService} from "../service/promo/promo-message-service";
import {Constants} from "../constants/constants";
import {ReCaptchaV3Service} from "ng-recaptcha";
declare let fbq:Function;

@Component({
    template: '',
    providers:[MessageService]
})

export abstract class EtoComponent implements OnInit{
    @Destroy() destroyed$: any;

    @Input()
    isLoading: boolean = false;

    protected apiService: ApiService;
    protected responsive: BreakpointObserver;
    protected messageService: MessageService;
    protected route: ActivatedRoute;
    protected router: Router;
    protected location: Location;
    protected confirmationService: ConfirmationService
    protected analyticsService: AnalyticsService
    protected promoService: PromoMessageService
    protected recaptchaService: ReCaptchaV3Service;

    protected primengConfig: PrimeNGConfig;
    protected ngxSmartBannerService: NgxSmartBannerService
    protected viewContainerRef: ViewContainerRef;

    isHandsetPortrait: boolean;
    isSmallerScreen: boolean;


    constructor(injector: Injector) {
        this.apiService = injector.get(ApiService);
        this.responsive = injector.get(BreakpointObserver)
        this.messageService = injector.get(MessageService)
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.location = injector.get(Location);
        this.confirmationService = injector.get(ConfirmationService);
        this.analyticsService = injector.get(AnalyticsService);
        this.primengConfig = injector.get(PrimeNGConfig);
        this.ngxSmartBannerService = injector.get(NgxSmartBannerService);
        this.viewContainerRef = injector.get(ViewContainerRef);
        this.promoService = injector.get(PromoMessageService);
        this.recaptchaService = injector.get(ReCaptchaV3Service);
    }

    ngOnInit(): void {
        this.responsive.observe(Breakpoints.HandsetPortrait)
            .pipe(takeUntil(this.destroyed$), finalize(() => {
            }))
            .subscribe(result => {
                this.isHandsetPortrait = result.matches;
            });

        this.responsive
            .observe(['(max-width: 1048px)'])
            .subscribe((state: BreakpointState) => {
                this.isSmallerScreen = state.matches;
            });
    }

    ngOnDestroy(): void {
    }

    appendAsterisk(value: string){
        return `* ${value}`;
    }

    showGenericError(){
        this.messageService.add(
            {
                severity:'error',
                summary:EtoTranslation.getInstant('oops'),
                detail:EtoTranslation.getInstant('error_generic')
            });
    }

    updateURLWithNewParamsWithoutReloading(key: string, param: string) {
        const params = new HttpParams().appendAll({
            [key]: param,
        });

        this.location.replaceState(
            location.pathname,
            params.toString()
        );
    }

    onBack() {
        this.location.back()
    }

    logClickEvent(eventName: string) {
        this.analyticsService.onClickEvent(eventName);
        switch (eventName) {
            case Constants.Analytics.clickEventBookedDate:
                fbq('track', 'Schedule');
                break
            case Constants.Analytics.clickEventSelectedVacancy:
                fbq('track', 'InitiateCheckout');
                break
            default:
                console.log("Unknown analytics event " + eventName)
        }
    }
}
