import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";


@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                "Accept-Type": "application/json",
            }
        });
        request = request.clone({
            setHeaders: {
                "Content-Type": "application/json",
            }
        });
        return next.handle(request);
    }
}
