<p-toast></p-toast>
<app-vehicle-modal [vehicle]="vehicle" [(display)]="showVehicleModal"
                   (onVehicleCreatedOrUpdate)="onVehicleUpdated($event)">
</app-vehicle-modal>
<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                 [style]="{width: '50vw'}"
                 [closable]="false"
                 [acceptLabel]="'yes'|etotranslate"
                 [rejectLabel]="'no'|etotranslate"
                 icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<div style="display:flex; flex-direction:column; justify-content: center;align-items: center">
    <div class="profile-container" *ngIf="!showReservationsOnly">
        <div style="display:flex;flex-direction:row;align-items:center">
            <button pButton pRipple type="button" icon="pi pi-angle-left"
                    class="p-button-rounded p-button-text"
                    (click)="onBack()"
                    style="margin-right:8px">
            </button>
            <h2>{{'menu_profile'|etotranslate}}</h2>
            <i style="margin-left: 8px" *ngIf="loadingProfile" class="pi pi-spin pi-spinner font-icon"></i>
        </div>
        <div class="profile-section-container">
            <div style="margin:32px">
                <i [ngClass]="'rounded-image'" class="pi pi-user"></i>
            </div>
            <div class="profile-section-data-container">
                <p style="margin-top:32px">{{'your_name'|etotranslate}}</p>
                <h3 *ngIf="!personEditMode" style="margin-top:0">{{getUserName()}}</h3>
                <input *ngIf="personEditMode" style="margin-bottom:16px" type="text"
                       [placeholder]="'profile_name_hint'|etotranslate"
                       [(ngModel)]="profile.name" (blur)="onEditProfileDone()" pInputText autofocus>
                <div class="profile-section-items-container">
                    <div class="profile-section-data-item">
                        <p>{{'your_phone_number'|etotranslate}}</p>
                        <h4 style="margin-top:4px; margin-bottom:4px">{{getPhoneNumber()}}</h4>
                        <p class="phone-disclaimer">{{'change_phone_disclaimer'|etotranslate}}</p>
                    </div>
                </div>
                <button pButton pRipple type="button" icon="pi pi-trash"
                        [loading]="loadingProfile"
                        style="width:fit-content; margin-bottom:8px"
                        [label]="'user_profile_delete'|etotranslate"
                        (click)="onDeleteProfileClicked()"
                        class="p-button-secondary p-button-outlined"></button>
            </div>
            <div class="profile-section-edit-container">
                <button *ngIf="!loadingProfile && !personEditMode" pButton pRipple type="button" icon="pi pi-pencil"
                        class="p-button-outlined p-button-rounded" (click)="onEditProfileClicked()"></button>
                <i *ngIf="loadingProfile" class="pi pi-spin pi-spinner font-icon"></i>
            </div>
        </div>


        <div class="profile-section-container">
            <div style="margin:32px">
                <i [ngClass]="'rounded-image'" class="vehicle-icon"></i>
            </div>
            <div class="profile-section-data-container" *ngIf="!shouldDisplayAddVehicle()">
                <p style="margin-top:32px">{{getVehicleDescription()}}</p>
                <h3 style="margin-top:0">{{getVehicleName()}}</h3>
                <div class="profile-section-items-container" *ngIf="!!vehicle">
                    <div class="profile-section-data-item" *ngFor="let item of vehicle.categories">
                        <p>{{getCategoryName(item)}}</p>
                        <h4 style="margin-top:4px">{{getCategoryValue(item)}}</h4>
                    </div>
                </div>
            </div>
            <div *ngIf="shouldDisplayAddVehicle()" style="display:flex;height:auto;flex-direction:column;justify-content:center">
                <button *ngIf="!loadingVehicle" pButton pRipple type="button" icon="pi pi-plus"
                        (click)="showVehicleDialog()"
                        [label]="'add_vehicle'|etotranslate"
                        class="p-button-primary"></button>
            </div>
            <div class="profile-section-edit-container">
                <button *ngIf="!loadingVehicle && !shouldDisplayAddVehicle()" pButton pRipple type="button" icon="pi pi-pencil"
                        (click)="showVehicleDialog()"
                        class="p-button-outlined p-button-rounded"></button>
                <i *ngIf="loadingVehicle" class="pi pi-spin pi-spinner font-icon"></i>
            </div>
        </div>
    </div>
    <div class="profile-container" *ngIf="!showReservationsOnly">
        <div style="display:flex;flex-direction:row;align-items:center">
            <h2>{{'menu_notifications'|etotranslate}}</h2>
            <i style="margin-left: 8px" *ngIf="loadingMessages" class="pi pi-spin pi-spinner font-icon"></i>
        </div>
        <div *ngFor="let message of promoMessages">
            <app-message-item [message]="message" (onMessageClicked)="onPromoMessageClicked(message)"></app-message-item>
        </div>
    </div>
    <div class="profile-container">
        <div style="display:flex;flex-direction:row;align-items:center">
            <button *ngIf="showReservationsOnly" pButton pRipple type="button" icon="pi pi-angle-left"
                    class="p-button-rounded p-button-text"
                    (click)="onBack()"
                    style="margin-right:8px">
            </button>
            <h2>{{'menu_reservations'|etotranslate}}</h2>
            <i style="margin-left: 8px" *ngIf="loadingReservations" class="pi pi-spin pi-spinner font-icon"></i>
        </div>
        <app-empty-state *ngIf="!!reservationGroups && reservationGroups.length==0"
                         [text]="'reservations_empty_text'|etotranslate" [buttonText]="'vacancies'|etotranslate" (onEmptyStateButtonClicked)="onEmptyStateButtonClicked()"></app-empty-state>
        <div *ngFor="let group of reservationGroups">
            <h3>{{getNameForReservationGroup(group)}}</h3>
            <div class="reservation-list-wrapper">
                <app-reservations-list [items]="group.reservations"
                                       (onReservationSelected)="onReservationSelected($event)"></app-reservations-list>
            </div>
        </div>
    </div>
</div>
