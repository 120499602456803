<app-auth-modal *ngIf="showAuthModal"
                [(display)]="showAuthModal"
                [state]="authModalState"
                (onAuthorisationDone)="tokenReceived($event)">
</app-auth-modal>
<div class="main-toolbar">
    <div class="column-left">
        <img *ngIf="!isHandsetPortrait" src="assets/images/logo-text-blue.svg" alt="Ernest.to" style="cursor: pointer" (click)="logoClicked()"/>
        <img *ngIf="isHandsetPortrait" src="assets/images/logo-blue.svg" alt="Ernest.to" style="cursor: pointer; width: 44px; height:44px" (click)="logoClicked()"/>
    </div>
    <div class="column-center">
        <p-overlayPanel #op [dismissable]="false" class="search-overlay">
            <ng-template pTemplate>
                <app-sites-list [title]="'searching'|etotranslate:{query: searchText}" [isLoading]="searchLoading"
                                (onSiteClicked)="onSiteClicked($event);op.hide()"
                                [sites]="searchResults"></app-sites-list>
            </ng-template>
        </p-overlayPanel>
        <span class="p-input-icon-left p-input-icon-right" #search>
            <i class="pi pi-search"></i>
            <i class="pi pi-times-circle" style="cursor: pointer"
               [ngStyle]="{'display': !searchText ? 'none' : 'block'}" (click)="onTextCleared(search, op, $event)"></i>
            <input type="text" pInputText
                   [placeholder]="'ernestto_search'|etotranslate"
                   [(ngModel)]="searchText"
                   [ngClass]="isHandsetPortrait ? 'p-inputtext-sm' : ''"
                   (ngModelChange)="onTextChanged(search, op, $event)"/>
        </span>
    </div>
    <div class="toolbar-right-container column-right">
        <div *ngIf="!sessionActive && !isSmallerScreen">
            <button style="margin:2px"  pButton pRipple type="button" [label]="'login'|etotranslate" (click)="onLogin()"></button>
            <button style="margin:2px" pButton pRipple type="button" [label]="'signup'|etotranslate" class="p-button-outlined" (click)="onSignUp()"></button>
        </div>
        <div *ngIf="!sessionActive && isSmallerScreen">
            <button style="margin:2px" pButton pRipple type="button" icon="pi pi-user" class="p-button-outlined p-button-rounded"
                    (click)="menu.toggle($event)"></button>
            <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </div>
        <app-language-switch style="margin:2px" #language [hidden]="sessionActive || isSmallerScreen"></app-language-switch>
        <div *ngIf="!isSmallerScreen">
            <button style="margin:2px" pButton pRipple type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text "
                    (click)="menu.toggle($event)"></button>
            <p-menu #menu [popup]="true" [model]="aboutItems"></p-menu>
        </div>
        <div *ngIf="sessionActive">
            <button style="margin:2px" pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded"
                    (click)="menu.toggle($event)"></button>
            <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </div>
    </div>
</div>
