import {AbstractFilterLoader} from "./abstract-filter-loader";
import {Site} from "../../../model/api/response/site";
import {FilterType} from "../filter-type";
import {FilterResult} from "../filter-result";
import {MenuItem} from "primeng/api";
import {FilterMenuProvider} from "../../menu/filter/filter-menu-provider";
import {EtoTranslation} from "../../translation/eto-translation";

export class SitesFilterLoader extends AbstractFilterLoader<Site[]>{
    protected convertDataToMenuItems(data: Site[], filterResult: FilterResult): MenuItem {
        let items = FilterMenuProvider.getDestinationMenu(data, this.filterResult.filter.selectedRegionName, jobTemplate => {
            this.siteSelected(jobTemplate, true);
        });
        this.result =  {
            automationId: FilterType.DESTINATION,
            icon:'destination-icon',
            items: items
        };
        if (data.length == 0){
            this.siteSelected(null, false);
        }
        let selectedSiteId = this.filterResult.filter.selectedSiteId;
        const selection = !!selectedSiteId && data.flatMap(value => value.id).indexOf(selectedSiteId) !=-1
            ? data.filter(value => value.id == selectedSiteId)[0]
            : null;
        this.siteSelected(selection, false);
        return this.result;
    }

    protected getDataFromApi(onSuccess: (data: Site[]) => void): void {
        this.updateProgress(true);
        this.apiService.getSites(this.filterResult.filter.selectedRegionId, this.filterResult.filter.selectedType, null, null).execute(this.notifier, data => {
            this.updateProgress(false);
            onSuccess(data);
        }, apiError => {
            this.updateWithError(apiError)
            this.updateProgress(false);
        })
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [FilterType.JOB];
    }

    private siteSelected(site: Site, manual: boolean) {
        this.result.items.forEach(value => value.disabled = false);
        if (!!site) {
            let selected = this.result.items.filter(value => value.id == site.id.toString());
            if (selected.length > 0) {
                selected[0].disabled = true;
                this.filterResult.filter.selectedSiteId = Number.parseInt(selected[0].id);
                this.result.label = selected[0].label;
            }
        }
        else {
            this.result.items[0].disabled = true;
            this.result.label = this.result.items[0].label;
            this.filterResult.filter.selectedSiteId = null;
        }
        if (manual){
            this.onItemSelectedManually();
        }
        else{
            this.onItemSelected();
        }
    }
}
