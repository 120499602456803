import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {AuthState} from "../../model/ui/auth-state";
import {AuthRequest} from "../../model/api/request/auth-request";
import {VerificationRequest} from "../../model/api/request/verification-request";
import {UserState} from "../../model/api/response/user-state";
import {Token} from "../../model/api/response/token";
import {shakeAnimation} from "../../app-animations";
import {HttpErrorResponse} from "@angular/common/http";
import {interval} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {EtoTranslation} from "../../service/translation/eto-translation";
import {ResendCodeRequest} from "../../model/api/request/resend-code-request";
import {Captcha} from "../../model/api/request/captcha";

const RESEND_AVAILABLE_AFTER_SECONDS = 30;

@Component({
  animations:[shakeAnimation],
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent extends EtoComponent{

  @Input()
  state: AuthState = AuthState.LOGIN;
  @Input()
  display: boolean;
  @Output()
  displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onAuthorisationDone: EventEmitter<Token> = new EventEmitter<Token>();

  authRequest: AuthRequest = new AuthRequest();
  verificationRequest: VerificationRequest = new VerificationRequest();
  error: string;
  resendCodePossible: boolean;
  resendAvailableIn = RESEND_AVAILABLE_AFTER_SECONDS;
  showMessageAfterResendSuccess: boolean;

  isRegistration() {
    return this.state == AuthState.REGISTRATION;
  }

  isLogin() {
    return this.state == AuthState.LOGIN;
  }

  isSmsVerification() {
    return this.state == AuthState.SMS_VERIFICATION;
  }

  getTitle() {
    switch (this.state){
      case AuthState.LOGIN:
        return EtoTranslation.getInstant("login_title");
      case AuthState.REGISTRATION:
        return EtoTranslation.getInstant("signup_title");
      case AuthState.SMS_VERIFICATION:
        return EtoTranslation.getInstant("enter_code");
    }
  }

  isLoginButtonEnabled(){
    return this.state == AuthState.LOGIN
        && !!this.authRequest.phoneNumber;
  }

  isSignupButtonEnabled(){
    return this.state == AuthState.REGISTRATION
        && !!this.authRequest.phoneNumber
        && !!this.authRequest.name
        && !!this.authRequest.termsConsent;
  }

  isResendButtonEnabled(){
    return this.state == AuthState.SMS_VERIFICATION
        && this.resendCodePossible;
  }

  onSignUpClicked() {
    this.error = null;
    this.isLoading = true;
    this.recaptchaService.execute('phone_login')
        .subscribe((token: string) => {
          this.authRequest.captcha = new Captcha();
          this.authRequest.captcha.token = token;
          this.apiService.verifyPhone(this.authRequest).execute(this.destroyed$, data => {
            this.isLoading = false;
            if (data.userState == UserState.BLOCKED){
              this.showErrorForBlocked();
              return;
            }
            this.verificationRequest.verificationKey = data.verificationKey;
            this.verificationRequest.phoneNumber = this.authRequest.phoneNumber;
            this.switchToSMSVerification();
          }, apiError => {
            this.isLoading = false;
            this.processError(apiError);
          })

        });
  }


  onLoginClicked() {
    this.error = null;
    this.isLoading = true;
    this.recaptchaService.execute('phone_login')
        .subscribe((token: string) => {
          this.authRequest.captcha = new Captcha();
          this.authRequest.captcha.token = token;
          this.apiService.verifyPhone(this.authRequest).execute(this.destroyed$, data => {
            this.isLoading = false;
            if (data.userState == UserState.BLOCKED){
              this.showErrorForBlocked();
              return;
            }
            if (data.userState == UserState.NON_REGISTERED){
              this.showErrorForNonRegistered();
              return;
            }
            this.verificationRequest.verificationKey = data.verificationKey;
            this.verificationRequest.phoneNumber = this.authRequest.phoneNumber;
            this.switchToSMSVerification();
          }, apiError => {
            this.isLoading = false;
            this.processError(apiError);
          })
        });
  }

  private switchToSMSVerification() {
    this.resendAvailableIn = RESEND_AVAILABLE_AFTER_SECONDS;
    this.resendCodePossible = false;
    this.state = AuthState.SMS_VERIFICATION;
    let subscription = interval(1000).pipe(takeUntil(this.destroyed$))
        .subscribe(x => {
            this.resendAvailableIn--;
            if (this.resendAvailableIn <= 20){
              this.showMessageAfterResendSuccess = false;
            }
            if (this.resendAvailableIn <= 0){
              this.resendCodePossible = true;
              subscription.unsubscribe();
            }
        });
  }

  private processError(apiError: HttpErrorResponse) {
    if (apiError.status == 406) {
      this.showErrorForInvalidCode()
    } else {
      this.showGenericError();
    }
  }

  onResendClicked() {
    this.isLoading = true;
    let resendCodeRequest = new ResendCodeRequest();
    resendCodeRequest.verificationKey = this.verificationRequest.verificationKey;
    this.recaptchaService.execute('phone_login')
        .subscribe((token: string) => {
          resendCodeRequest.captcha = new Captcha();
          resendCodeRequest.captcha.token = token;
          this.apiService.resendCode(resendCodeRequest).execute(this.destroyed$, data => {
            this.verificationRequest.verificationKey = data.verificationKey;
            this.isLoading = false;
            this.showMessageAfterResendSuccess = true;
            this.switchToSMSVerification();
          }, apiError => {
            this.isLoading = false;
            this.showGenericError();
          })

        });
  }

  onCodeCompleted(code: string) {
    this.verificationRequest.verificationCode = code;
    this.isLoading = true;
    this.apiService.authorize(this.verificationRequest).execute(this.destroyed$, data => {
      this.onAuthorisationDone.emit(data);
      this.display = false;
      this.displayChange.emit(this.display);
      this.isLoading = false;
      this.apiService.updateSession(true);
    }, apiError => {
      this.isLoading = false;
      this.processError(apiError);
    })
  }

  onSwitchToSignUpClicked() {
    this.state = AuthState.REGISTRATION;
  }

  onPhoneChanged(phone: string) {
    this.authRequest.phoneNumber = phone;
  }

  onTermsCheckChanged(value: any) {
    this.authRequest.termsConsent = value.checked.length == 0 ? null : value.checked[0];
  }

  onMarketingCheckChanged(value: any) {
    this.authRequest.marketingConsent = value.checked.length == 0 ? null : value.checked[0];
  }

  private showErrorForBlocked() {
    this.error = EtoTranslation.getInstant("blocked_user_error");
  }

  private showErrorForNonRegistered() {
    this.error = EtoTranslation.getInstant("no_user_found");
  }

  private showErrorForInvalidCode() {
    this.error = EtoTranslation.getInstant("verification_code_invalid_error");
  }

  codeChanged() {
    this.error = null;
  }

  getResendMessage() {
    if (this.showMessageAfterResendSuccess){
      return EtoTranslation.getInstant("new_code_sent");
    }
    return EtoTranslation.getInstant("resend_code_in", {phone: this.resendAvailableIn});
  }
}
