/* tslint:disable:variable-name */

export class Constants {

    static Route = class {

        public static root = '/';
        public static home = 'home';
        public static you = 'you';
        public static reservations = 'reservations';
        public static reservation = 'reservation';
        public static site = 'site';
        public static confirmation = 'confirmation';
        public static widget = 'widget';
    };

    static Keys = class {
        public static token = 'token';
        public static filter = 'filter';
        public static regionId = 'regionId';
        public static installDate = 'installDate';
        public static installId = 'installId';
        public static localVehicle = 'local-vehicle';
        public static seenIds = 'seen-ids';
    };

    static ApiUrl = class {
        public static auth = 'auth';
        public static profile = 'profile';
        public static verify = 'verify';
        public static authorize = 'authorize';
        public static resend = 'resend';
        public static carservice = 'carservice';
        public static regions = 'regions';
        public static region = 'region';
        public static sites = 'sites';
        public static site = 'site';
        public static search = 'search';
        public static refresh = 'refresh';
        public static promoMessages = 'promo-messages';
        public static promoMessage = 'promo-message';
        public static reservation = 'reservation';
        public static vacancies = 'vacancies';
        public static vacancy = 'vacancy';
        public static check = 'check';
        public static jobTemplates = 'job-templates';
        public static vehicle = 'vehicle';
        public static list = 'list';
        public static options = 'options';
    };

    static QueryParam = class  {
        public static filter = 'filter';
        public static vacancy = 'vacancy';
    };

    static PathParam = class  {
        public static id = 'id';
    };

    static Analytics = class  {
        public static clickEventSelectedVacancy = 'click_event_selected_vacancy';
        public static clickEventBookedDate = 'click_event_booked_date';
    };
}
