export enum FilterType {
    REGION ,
    SITE_TYPE,
    VEHICLE,
    JOB,
    DESTINATION,
    START_DATE,
    TIME_WINDOW

}
