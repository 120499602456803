import {RegionListener} from "./region-listener";
import {MenuItem} from "primeng/api";
import {Region} from "../../../model/api/response/region";
import {EtoTranslation} from "../../translation/eto-translation";

export class RegionsMenuProvider {

    private listener: RegionListener;
    private regions: Region[] = [];


    constructor(listener: RegionListener) {
        this.listener = listener;
    }

    getMenu(regions: Region[], selectedRegionId: number): MenuItem[] {
        this.regions = regions;
        const result: MenuItem[] = [];
        result.push({
            label: EtoTranslation.getInstant("detect_from_gps"),
            id: null,
            disabled: false,
            command: () => {
                this.listener.onRegionSelected(null, true);
            }
        })
        for (const region of regions) {
            result.push(
                {
                    label: region.name,
                    id: region.id.toString(),
                    disabled: !!selectedRegionId && region.id == selectedRegionId,
                    command: () => {
                        this.listener.onRegionSelected(region, true);
                    }
                }
            )
        }
        return result;
    }

    getRegionsWithOneFoundFromGps(region: Region):MenuItem[] {
        this.regions.unshift( region);
        return this.getMenu(this.regions, region.id);
    }
}
