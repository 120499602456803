import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import * as moment from "moment";
import {Moment} from "moment";
import {environment} from "../../../environments/environment";
import {Constants} from "../../constants/constants";
import {catchError, finalize, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Token} from "../../model/api/response/token";
import {UrlBuilder} from "./helper/url-builder";
import {RefreshToken} from "../../model/api/request/refresh-token";
import {ResponseData} from "../../model/api/response/response-data";
import {MessageService} from "primeng/api";
import {EtoTranslation} from "../translation/eto-translation";

export class TokenApiService {

  protected tokenSubject: BehaviorSubject<Token>;
  public token: Observable<Token>;

  private activeSessionSubject: Subject<boolean>

  constructor(
    private router: Router,
    protected http: HttpClient,
    private messageService: MessageService
  ) {
    this.tokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem(Constants.Keys.token)));
    this.token = this.tokenSubject.asObservable();
    this.activeSessionSubject = new Subject<boolean>();
  }

  private tokenExpiration: Moment;
  private THRESHOLD_ACCESS_LIFETIME_MILLIS = 10000;

  public get tokenValue(): Token {
    return this.tokenSubject.value;
  }

  refreshAccessToken(refreshToken: RefreshToken) {
    const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
      .appendPathParam(Constants.ApiUrl.auth)
      .appendPathParam(Constants.ApiUrl.refresh)
      .build();

    return this.http.post<ResponseData<Token>>(url, refreshToken)
      .pipe(map(responseData => {
        const restoredToken = this.getToken();
        this.updateTokenExpiration(responseData.data.accessLifeTime);
        this.storeToken(responseData.data);
        this.tokenSubject.next(responseData.data);
        return responseData.data;
      }), catchError(error => {
        this.onRefreshTokenError();
        return throwError(error);
      }), finalize(() => {
        if (!this.isActiveSession() && this.tokenSubject.value != null){
          this.onRefreshTokenError();
        }
      }));
  }

  onRefreshTokenError() {
    this.logout();
    this.messageService.add(
        {
          severity: 'error',
          summary: EtoTranslation.getInstant('oops'),
          detail: EtoTranslation.getInstant('auth_error')
        });
  }

  logout() {
    localStorage.removeItem(Constants.Keys.token);
    localStorage.removeItem(Constants.Keys.localVehicle);
    localStorage.removeItem(Constants.Keys.seenIds);
    this.tokenSubject.next(null);
  }

  protected updateTokenExpiration(accessLifeTime: number) {
    this.tokenExpiration = moment()
      // accessLifeTime is millis from now
      .add(accessLifeTime, 'milliseconds')
      // subtract threshold due to conn delays and so
      .subtract(this.THRESHOLD_ACCESS_LIFETIME_MILLIS, 'milliseconds');
  }

  isUserLoggedIn() {
    return !!this.tokenValue;
  }

  private isTokenExpired() {
    return this.isUserLoggedIn() && (!this.tokenExpiration || moment().isSameOrAfter(this.tokenExpiration));
  }

  isActiveSession() {
    return this.isUserLoggedIn() && !this.isTokenExpired();
  }

  getToken() {
    return this.tokenValue;
  }

  getRefreshToken() {
    return this.tokenValue?.refreshToken;
  }

  storeToken(token: Token) {
    localStorage.setItem(Constants.Keys.token, JSON.stringify(token));
    this.tokenSubject.next(token);
  }

  getObservableSession(){
    return this.activeSessionSubject.asObservable();
  }

  updateSession(sessionActive: boolean){
    this.activeSessionSubject.next(sessionActive);
  }
}
