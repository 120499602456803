<p-accordion [activeIndex]="isLoading ? -1 : 0">
    <p-accordionTab header="">
        <div class="wizard-layout">
            <p class="wizard-question">{{'wizard_message'|etotranslate}}</p>
            <div *ngFor="let option of optionsMenuItems">
                <div class="wizard-layout" style="margin-bottom:64px" *ngIf="hasSelection(option) || isFirstUnselected(option)" @revealAnimation>
                    <h4 class="wizard-option-question">{{option.label}}</h4>
                    <p style="margin-top:16px" *ngIf="!!option.tooltip">{{option.tooltip}}</p>
                    <div class="wizard-options-layout" *ngIf="!hasSelection(option)">
                        <div *ngFor="let item of option.options">
                            <div class="wizard-option" (click)="onOptionSelected(option, item)" [pTooltip]="item.tooltip">
                                <div class="wizard-vehicle-icon"></div>
                                <p class="wizard-option-text">{{item.label}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="wizard-selected-layout" *ngIf="hasSelection(option)">
                        <div class="wizard-selection">
                            <div class="wizard-selection-vehicle-icon"></div>
                            <p class="wizard-selection-text">{{option.selected.label}}</p>
                        </div>
                        <div class="link-container">
                            <button pButton pRipple type="button"
                                    [label]="'change'|etotranslate"
                                    class="p-button-link change-link" (click)="onSelectionChangedClicked(option)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>
