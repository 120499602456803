<p-dialog [(visible)]="display"
          [title]="getTitle()"
          (visibleChange)="displayChange.emit(display)"
          [modal]="true">
    <ng-template pTemplate="header">
        <h2>{{getTitle()}}</h2>
    </ng-template>
    <div>
        <div style="height:26px">
            <p *ngIf="!!error" [@shakeAnimation] style="text-align: center;padding: 4px;max-width:324px" class="inline-error">{{error}}</p>
        </div>
        <div *ngIf="isRegistration() || isLogin()" class="auth-modal">
            <h5 style="max-width: 264px;text-align: center;" *ngIf="isLogin()">{{'login_by_phone'|etotranslate}}</h5>
            <h5 style="max-width: 264px;text-align: center;" *ngIf="isRegistration()">{{'we_need_only'|etotranslate}}</h5>
            <div *ngIf="isRegistration()" style="margin-bottom:24px">
                <p style="margin-bottom:8px">{{appendAsterisk('profile_name'|etotranslate)}}</p>
                <span *ngIf="isRegistration()">
                <input style="width:100%" type="text" [placeholder]="'profile_name_hint'|etotranslate"
                       (keyup.enter)="onSignUpClicked()"
                       [(ngModel)]="authRequest.name" pInputText>
            </span>
            </div>
            <div>
                <p style="margin-bottom:8px">{{appendAsterisk('profile_phone'|etotranslate)}}</p>
                <app-phone-input (onValidPhoneProvided)="onPhoneChanged($event)"
                                 (keyup.enter)="isRegistration() ? onSignUpClicked() : onLoginClicked()"
                                 (onPhoneInvalidated)="onPhoneChanged($event)"
                                 style="width:100%"></app-phone-input>
            </div>
            <div *ngIf="isRegistration()">
                <div class="field-checkbox consent-checkbox" style="">
                    <p-checkbox class="consent-checkbox-check" [value]="'consent_terms'|etotranslate"
                                (onChange)="onTermsCheckChanged($event)" inputId="tc"></p-checkbox>
                    <p class="consent-text" [innerHTML]="appendAsterisk('consent_terms'|etotranslate)"></p>

                </div>
                <div class="field-checkbox consent-checkbox">
                    <p-checkbox class="consent-checkbox-check" [value]="'consent_marketing'|etotranslate"
                                (onChange)="onMarketingCheckChanged($event)" inputId="mc"></p-checkbox>
                    <p class="consent-text" [innerHTML]="'consent_marketing'|etotranslate"></p>
                </div>
            </div>
            <div *ngIf="isLogin()">
                <div>
                    <div class="divider-container">
                        <div class="divider"></div>
                        <p style="white-space: nowrap">{{'or_sign_up'|etotranslate}}</p>
                        <div class="divider"></div>
                    </div>

                    <button pButton pRipple style="width:100%" type="button"
                            *ngIf="isLogin()"
                            [label]="'signup'|etotranslate"
                            (click)="onSwitchToSignUpClicked()"
                            class="p-button-secondary">
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isSmsVerification()">
            <div class="auth-modal">
                <h5 style="text-align: center">{{'sms_was_sent_to'|etotranslate}}</h5>
                <h5 style="text-align: center; font-family:CeraPro-Bold">{{authRequest.phoneNumber}}</h5>
                <app-code-input (codeChanged)="codeChanged()" (codeCompleted)="onCodeCompleted($event)"></app-code-input>
                <p *ngIf="!resendCodePossible" style="text-align: center;margin-top: 32px;">{{getResendMessage()}}</p>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="auth-buttons-container">
            <button pButton pRipple style="width:100%" type="button"
                    *ngIf="isRegistration()"
                    [loading]="isLoading"
                    [label]="'signup'|etotranslate"
                    [disabled]="!isSignupButtonEnabled()"
                    (click)="onSignUpClicked()"
                    class="p-button">
            </button>
            <button pButton pRipple style="width:100%" type="button"
                    *ngIf="isLogin()"
                    [loading]="isLoading"
                    [label]="'login'|etotranslate"
                    [disabled]="!isLoginButtonEnabled()"
                    (click)="onLoginClicked()"
                    class="p-button">
            </button>
            <button pButton pRipple style="width:100%" type="button"
                    *ngIf="isSmsVerification()"
                    [loading]="isLoading"
                    [label]="'resend_code'|etotranslate"
                    [disabled]="!isResendButtonEnabled()"
                    (click)="onResendClicked()"
                    class="p-button-outlined">
            </button>
        </div>
    </ng-template>
</p-dialog>

