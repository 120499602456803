export abstract class VacancyCard {
    type: VacancyItemType;


    constructor(type: VacancyItemType) {
        this.type = type;
    }
}
export enum VacancyItemType{
    SINGLE_CARD, MULTI_CARD, GROUP_CARD, MARKETING_CARD
}
