import {VehicleOptionMenuItem} from "./vehicle-option-menu-item";
import {VehicleCategory} from "../api/response/vehicle-category";

export class VehicleCategoryMenuItem{
    label: string;
    tooltip: string;
    value: VehicleCategory;
    selected: VehicleOptionMenuItem;
    options: VehicleOptionMenuItem[];
}
