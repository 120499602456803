import {Injectable} from "@angular/core";
import {ApiRequestBuilder} from "./helper/api-request-builder";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiFactoryService {

  createBuilder<T>(observableRequest: Observable<T>){
    return ApiRequestBuilder.create(observableRequest);
  }
}
