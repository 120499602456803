import {KeyValue} from "@angular/common";

export class UrlBuilder {
  private baseUrl: string;
  private versionSuffix: string;
  private paths: string[] = [];
  private queryParams: KeyValue<string, string>[] = [];

  public static create(base: string, version: string): UrlBuilder {
    let urlBuilder = new UrlBuilder();
    urlBuilder.baseUrl = base;
    urlBuilder.versionSuffix = version;
    return urlBuilder;
  }

  private safePiece(piece: string): string {
    if (piece) {
      return piece.replace("/", "");
    }
    return "";
  }

  appendPathParam(path: string) {
    this.paths.push(path);
    return this;
  }

  appendQueryParam(keyValue: KeyValue<string, string>) {
    if (keyValue.value) {
      this.queryParams.push(keyValue);
    }
    return this;
  }

  build(): string {
    let result = `/${this.safePiece(this.baseUrl)}/${this.safePiece(this.versionSuffix)}`;
    for (const path of this.paths) {
      result = result.concat("/");
      result = result.concat(this.safePiece(path));
    }
    for (let i = 0; i < this.queryParams.length; i++) {
      const queryParam = this.queryParams[i];
      if (i == 0) {
        result = result.concat("?");
      }
      result = result.concat(queryParam.key);
      result = result.concat("=");
      result = result.concat(queryParam.value);
      if (i < (this.queryParams.length - 1)) {
        result = result.concat("&");
      }
    }
    return encodeURI(result);
  }

}
