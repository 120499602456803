import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent extends EtoComponent{

  @Input()
  text: string;
  @Input()
  buttonText: string;

  @Output()
  onEmptyStateButtonClicked: EventEmitter<any> = new EventEmitter<any>();

}
