<div style="margin:44px;display:flex;flex-direction:column">
    <h2>{{vacancyPreview.vacancy.carService.name}}</h2>
    <p class="preview-label">{{'address'|etotranslate}}</p>
    <p class="preview-value">{{getAddress()}}</p>
    <p class="preview-label">{{'date_time'|etotranslate}}</p>
    <p class="preview-value">{{getDateTime()}}</p>
    <p class="preview-label">{{'reservation_job'|etotranslate}}</p>
    <p class="preview-value">{{getJobName()}}</p>
    <app-checklist-list *ngIf="!!vacancyPreview.vacancy.priceMetadata && !!vacancyPreview.vacancy.priceMetadata.checkListItems"
                        [items]="vacancyPreview.vacancy.priceMetadata.checkListItems"></app-checklist-list>
    <div class="separator-preview"></div>
    <div style="display:flex;flex-direction:row;align-items: center">
        <p [pTooltip]="getPriceTooltip()" style="margin-top:0px">{{getPriceLabel()}}</p>
        <i [pTooltip]="getPriceTooltip()" class="pi pi-info-circle" style="font-size:1rem; margin-left:8px"></i>
    </div>
    <h2>{{getPrice()}}</h2>
    <div class="separator-preview"></div>
    <div style="display:flex;flex-direction:row;margin-top:32px">
    <button (click)="onVacancyConfirmed.emit(vacancyPreview)"
            pButton pRipple type="button"
            style="width:100%;padding:16px"
            [label]="'book_date'|etotranslate"
            class="p-button-primary"></button>
    </div>
</div>
