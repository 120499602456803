import {AbstractFilterLoader} from "./abstract-filter-loader";
import {FilterDate} from "../../../model/ui/filter-date";
import {FilterResult} from "../filter-result";
import {MenuItem} from "primeng/api";
import {FilterType} from "../filter-type";
import {FilterDateType} from "../../../model/ui/filter-date-type";
import * as moment from "moment";
import {FilterMenuProvider} from "../../menu/filter/filter-menu-provider";
import {DateFormatter} from "../../util/date-formatter";
import {EtoTranslation} from "../../translation/eto-translation";

export class DateFilterLoader extends AbstractFilterLoader<FilterDate[]> {
    protected convertDataToMenuItems(data: FilterDate[], filterResult: FilterResult): MenuItem {
        let items = FilterMenuProvider.getDateMenu(data, date => {
            this.dateSelected(date, true);
        });
        this.result = {
            automationId: FilterType.START_DATE,
            icon:'date-icon',
            items: items
        };
        const selection = new FilterDate();
        if (!!this.filterResult.filter.selectedDay) {
            selection.date = this.filterResult.filter.selectedDay;
            selection.type = FilterDateType.EXACT_DATE;
        } else {
            selection.type = FilterDateType.NOW;
            selection.date = moment().toDate();
        }
        this.dateSelected(selection, false);
        return this.result;
    }

    protected getDataFromApi(onSuccess: (data: FilterDate[]) => void): void {
        const result: FilterDate[] = [];
        this.updateProgress(true);
        for (const type of Object.values(FilterDateType)) {
            let filterDate = new FilterDate();
            switch (type) {
                case FilterDateType.NOW:
                    filterDate.type = FilterDateType.NOW;
                    filterDate.date = moment().toDate();
                    break;
                case FilterDateType.IN_A_WEEK:
                    filterDate.type = FilterDateType.IN_A_WEEK;
                    filterDate.date = moment().add(1, 'week').startOf('day').toDate();
                    break;
                case FilterDateType.IN_A_MONTH:
                    filterDate.type = FilterDateType.IN_A_MONTH;
                    filterDate.date = moment().add(1, "month").startOf('day').toDate();
                    break;
                case FilterDateType.EXACT_DATE:
                    filterDate.type = FilterDateType.EXACT_DATE;
                    break;
                default:
                    continue;
            }
            result.push(filterDate);
        }
        this.updateProgress(false);
        onSuccess(result);
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [];
    }

    private dateSelected(date: FilterDate, manual: boolean) {
        if (date.type == FilterDateType.EXACT_DATE && manual){
            //will be overriden in component
            return;
        }
        if (moment(date.date).isBefore(moment())){
            date.date = moment().toDate();
        }
        this.result.items.forEach(value => value.disabled = false);
        this.result.label = date.type == FilterDateType.EXACT_DATE
            ? EtoTranslation.getInstant('from_date', {from: DateFormatter.dateToString(date.date)})
            : FilterMenuProvider.getDateLabel(date.type);
        this.filterResult.filter.selectedDay = date.date;
        if (manual) {
            this.onItemSelectedManually();
        } else {
            this.onItemSelected();
        }
    }


}
