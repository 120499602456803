import {Component, Injector} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {Constants} from "../../constants/constants";
import {LabelUtil} from "../../service/util/label-util";
import {VacancySelection} from "../../model/ui/vacancy-selection";
import {UserProfile} from "../../model/api/response/user-profile";
import {EtoTranslation} from "../../service/translation/eto-translation";
import {BillItem} from "../../model/ui/bill-item";
import {Token} from "../../model/api/response/token";
import {takeUntil} from "rxjs/operators";
import {Vehicle} from "../../model/api/response/vehicle";
import {VehicleCreate} from "../../model/api/request/vehicle-create";
import {VacancyCheck} from "../../model/api/response/vacancy-check";
import {VacancyCheckResult} from "../../model/api/response/vacancy-check-result";
import {ReservationCreate} from "../../model/api/request/reservation-create";

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.screen.html',
    styleUrls: ['./confirmation.screen.scss']
})
export class ConfirmationScreen extends EtoComponent {

    loadingProfile: boolean;
    loadingCheck: boolean;
    creatingReservation: boolean;
    showAuthModal: boolean;
    showVehicleModal: boolean;

    vacancySelection: VacancySelection;
    userProfile: UserProfile;
    vehicle: Vehicle;
    check: VacancyCheck;

    billItems: BillItem[] = [];


    constructor(injector: Injector) {
        super(injector);
        this.apiService.getObservableSession().pipe(takeUntil(this.destroyed$)).subscribe(sessionActive => {
            this.ngOnInit();
        })
    }

    override ngOnInit() {
        super.ngOnInit();
        const vacancyParam = this.route.snapshot.queryParamMap.get(Constants.QueryParam.vacancy);
        try {
            this.vacancySelection = JSON.parse(decodeURIComponent(escape(window.atob(vacancyParam))));
        } catch (e) {
            console.log(e);
        }
        if (!this.vacancySelection || this.vacancySelection.success){
            return;
        }
        if (this.apiService.isUserLoggedIn()) {
            this.loadProfile();
        }
        this.createSummaryItems();
    }

    private createSummaryItems() {
        this.billItems = [
            {
                value: this.getDate(),
                label: EtoTranslation.getInstant('date_time'),
                onButtonClicked: null,
                icon: 'summary-date-icon',
                buttonText: null
            },
            {
                value: this.getPrice(),
                label: this.vacancySelection.jobTemplateName,
                onButtonClicked: null,
                icon: 'summary-job-icon',
                buttonText: null
            }
        ];
    }

    getAddress() {
        let site = this.vacancySelection.vacancy.carService;
        return LabelUtil.formatFullAddress(site.address, site.zipCode, site.city);
    }

    getPrice() {
        return !!this.vacancySelection.vacancy.priceMetadata && this.vacancySelection.vacancy.priceMetadata.guaranteedPrice
        ? LabelUtil.formatPrice(this.vacancySelection.vacancy.price.toString())
            : LabelUtil.formatPriceFrom(this.vacancySelection.vacancy.price.toString());
    }

    private loadProfile() {
        this.loadingProfile = true;
        this.apiService.getProfile().execute(this.destroyed$, data => {
            this.userProfile = data;
            this.loadingProfile = false;
            if (!!data.vehicle){
                this.vehicle = data.vehicle;
                this.checkVacancy();
            }
            else {
                this.loadLocalVehicle()
            }
            this.createSummaryItems();
        }, apiError => {
            this.showGenericError();
            this.loadingProfile = false;
        })
    }

    getName() {
        return !!this.userProfile ? this.userProfile.name : '';
    }

    getPhone() {
        return !!this.userProfile ? `+${this.userProfile.phoneNumber}` : '';
    }

    getLicense() {
        return !!this.userProfile && !!this.userProfile.vehicle ? this.userProfile.vehicle.license : '';
    }

    getDescription() {
        return !!this.userProfile && !!this.userProfile.vehicle ? this.userProfile.vehicle.description : '';
    }

    getDate() {
        return this.isHandsetPortrait
            ? LabelUtil.getReservationDatShort(this.vacancySelection.vacancy.startDate)
            : LabelUtil.getReservationDateFull(this.vacancySelection.vacancy.startDate);
    }

    getType() {
        return LabelUtil.typeToString(this.vacancySelection.siteType);
    }

    onLoginClicked() {
        this.recaptchaService.execute('dialog_login_click').subscribe(value => {})
        this.showAuthModal = true;
    }

    tokenReceived(token: Token) {
        this.apiService.storeToken(token);
    }

    onAddVehicleClicked() {
        this.showVehicleModal = true;
    }

    private loadLocalVehicle() {
        this.vehicle = JSON.parse(localStorage.getItem(Constants.Keys.localVehicle));
    }

    onVehicleUpdated(vehicle: Vehicle) {
        this.recaptchaService.execute('confirm_vehicle_update').subscribe(value => {})
        let vehicleCreate = new VehicleCreate();
        vehicleCreate.description = vehicle.description;
        vehicleCreate.license = vehicle.license;
        vehicleCreate.itemTemplatedIds = vehicle.categories.flatMap(value => value.items[0].id);
        this.loadingProfile = true;
        this.apiService.createVehicle(vehicleCreate).execute(this.destroyed$, data => {
            this.userProfile.vehicle = data;
            this.loadingProfile = false;
            localStorage.removeItem(Constants.Keys.localVehicle);
            this.checkVacancy();
        }, apiError => {
            this.showGenericError();
            this.loadingProfile = false;
        })
    }

    getPromptMessage() {
        if (!this.userProfile){
            return EtoTranslation.getInstant('reservation_prompt_login');
        }
        else if (!this.userProfile.vehicle){
            return EtoTranslation.getInstant( 'reservation_prompt_vehicle');
        }
        return '';
    }

    checkVacancy(){
        this.loadingCheck = true;
        this.apiService.checkVacancy(
            this.vacancySelection.jobTemplateId,
            this.vacancySelection.vacancy.startDate,
            this.vacancySelection.vacancy.price,
            !!this.vacancySelection.vacancy.priceMetadata && this.vacancySelection.vacancy.priceMetadata.guaranteedPrice,
            this.userProfile.vehicle.id,
            this.vacancySelection.vacancy.carService.id)
            .execute(this.destroyed$, data => {
                this.check = data;
                this.processCheckResult(this.check);
                this.loadingCheck = false;
            }, apiError => {
                this.showGenericError();
                this.loadingCheck = false;
            })
    }

    private processCheckResult(check: VacancyCheck) {
        switch (check.result) {
        case VacancyCheckResult.OK:
                return;
        case VacancyCheckResult.PRICE_CHANGED:
                this.showCheckResultPriceWarning(check);
            break;
        default:
            this.showCheckResultError(check);
        }
    }

    confirmDisabled() {
        return this.isLoading
            || this.loadingProfile
            || this.loadingCheck
            || !this.userProfile
            || !this.userProfile.vehicle
            || !this.check
            || this.check.result != VacancyCheckResult.OK;
    }

    private showCheckResultPriceWarning(check: VacancyCheck) {
        let priceText = check.priceGuaranteed ? LabelUtil.formatPrice(check.updatedPrice.toString()): LabelUtil.formatPriceFrom(check.updatedPrice.toString());
        this.confirmationService.confirm({
            closeOnEscape: false,
            icon: 'pi pi-info-circle',
            header: EtoTranslation.getInstant('price_change'),
            message: EtoTranslation.getInstant('price_change_text',{price: priceText}),
            rejectLabel: EtoTranslation.getInstant('different_dates'),
            rejectIcon:'pi pi-calendar',
            acceptLabel: EtoTranslation.getInstant('accept'),
            accept: () => {
                this.vacancySelection.vacancy.price = check.updatedPrice;
                this.vacancySelection.vacancy.priceMetadata.guaranteedPrice = check.priceGuaranteed;
                this.check.result = VacancyCheckResult.OK;
                this.updateVacancySelectionInQueryParam();
                this.createSummaryItems();
            },
            reject: () => {
                this.goHome();
            }
        });
    }

    private showCheckResultError(check: VacancyCheck) {
        const title = EtoTranslation.getInstant(check.result == VacancyCheckResult.VACANCY_TAKEN ? 'reservation_conflict' : 'error_generic');
        const text = EtoTranslation.getInstant(check.result == VacancyCheckResult.VACANCY_TAKEN ? 'reservation_conflict_text' : 'vacancy_not_available_for_vehicle');
        this.confirmationService.confirm({
            closeOnEscape: false,
            icon: 'pi pi-exclamation-triangle',
            header: title,
            message: text,
            rejectVisible: false,
            acceptIcon:'pi pi-calendar',
            acceptLabel: EtoTranslation.getInstant('different_dates'),
            accept: () => {
                this.onBack();
            }
        });
    }

    getButtonLabel() {
        return EtoTranslation.getInstant(this.creatingReservation ? 'just_a_moment' : 'confirm_reservation')
    }

    onReservationConfirmClicked() {
        this.recaptchaService.execute('reservation_confirm_click').subscribe(value => {})
        let reservationCreate = new ReservationCreate();
        reservationCreate.startDate = this.vacancySelection.vacancy.startDate;
        reservationCreate.serviceId = this.vacancySelection.vacancy.carService.id;
        reservationCreate.jobTemplatedId = this.vacancySelection.jobTemplateId;
        reservationCreate.vehicleId = this.userProfile.vehicle.id;
        this.creatingReservation = true;
        this.logClickEvent(Constants.Analytics.clickEventBookedDate);
        this.apiService.createReservation(reservationCreate).execute(this.destroyed$, data => {
            this.vacancySelection.success = true;
            this.creatingReservation = false;
            this.updateVacancySelectionInQueryParam();
        }, apiError => {
            this.creatingReservation = false;
            if (apiError.status == 409){
                let vacancyCheck = new VacancyCheck();
                vacancyCheck.result = VacancyCheckResult.VACANCY_TAKEN;
                this.showCheckResultError(vacancyCheck);
                return;
            }
            this.showGenericError();
        })
    }
    updateVacancySelectionInQueryParam(){
        let param = btoa(unescape(encodeURIComponent(JSON.stringify(this.vacancySelection))));
        this.updateURLWithNewParamsWithoutReloading(Constants.QueryParam.vacancy, param);
    }

    goToReservations() {
        this.router.navigate([Constants.Route.you, Constants.Route.reservations]);
    }

    goHome() {
        this.recaptchaService.execute('nav_home').subscribe(value => {})
        this.router.navigate([Constants.Route.home]);
    }

    onSiteClicked() {
        this.router.navigate([Constants.Route.site, this.vacancySelection.vacancy.carService.id.toString()]);
    }

    getPriceLabel() {
        const guaranteedPrice = !!this.vacancySelection.vacancy.priceMetadata && this.vacancySelection.vacancy.priceMetadata.guaranteedPrice;
        return EtoTranslation.getInstant(!guaranteedPrice ? 'expected_price' : 'guaranteed_price');
    }

    getPriceTooltip() {
        const guaranteedPrice = !!this.vacancySelection.vacancy.priceMetadata && this.vacancySelection.vacancy.priceMetadata.guaranteedPrice;
        return EtoTranslation.getInstant(!guaranteedPrice ? 'summary_expected_price_tooltip' : 'summary_guaranteed_price_tooltip');
    }
}
