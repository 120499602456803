<div *ngFor="let section of sections">
    <div class="vacancy-header" *ngIf="!loadingAll && section.cards.length > 0">
        <h3 *ngIf="!isHandsetPortrait" class="vacancy-header-item">{{section.title}}</h3>
        <h4 *ngIf="isHandsetPortrait" class="vacancy-header-item">{{section.title}}</h4>
    </div>
    <div class="vacancy-empty" *ngIf="!loadingAll && section.cards.length ==0">
        <h3 *ngIf="!isHandsetPortrait" class="vacancy-header-item" style="text-align:center;">{{section.title}}</h3>
        <h4 *ngIf="isHandsetPortrait" class="vacancy-header-item" style="text-align:center;">{{section.title}}</h4>
        <p style="padding:16px">{{section.subtitle}}</p>
    </div>
        <div class="vacancies-list" *ngIf="!loadingAll">
            <div *ngFor="let item of section.cards">
                <app-vacancy-item [vacancyCard]="item"
                                  (onSingleCardClicked)="onSingleCardClicked.emit($event)"
                                  (onMultiCardClicked)="onMultiCardClicked.emit($event)"
                                  (onGroupCardClicked)="onGroupCardClicked.emit($event)"
                                  (onMarketingCardClicked)="onMarketingCardClicked.emit($event)"
                                  [vehicleCreated]="vehicleCreated">
                </app-vacancy-item>
            </div>
    </div>
</div>

<div class="vacancies-list" *ngIf="loadingAll">
    <div class="vacancy-header">
        <div>
            <h3 *ngIf="!isHandsetPortrait" class="vacancy-header-item">{{'just_a_moment'|etotranslate}}</h3>
            <h4 *ngIf="isHandsetPortrait" class="vacancy-header-item">{{'just_a_moment'|etotranslate}}</h4>
        </div>
    </div>
    <div *ngFor="let item of [null, null, null, null, null, null, null, null, null]">
        <app-vacancy-item [vacancyCard]="item"></app-vacancy-item>
    </div>
</div>
<div style="display: flex;justify-content: center;">
    <button *ngIf="shouldShowLoadMore()" pButton pRipple type="button"
            [loading]="isLoading"
            [label]="'show_next'|etotranslate"
            class="p-button-primary p-button-sm load-more-button" (click)="onShowMoreClicked.emit($event)"></button>
</div>
