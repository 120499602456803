import {Component, Input, OnInit} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {ChecklistItem} from "../../../model/api/response/checklist-item";
import {EtoTranslation} from "../../../service/translation/eto-translation";

@Component({
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss']
})
export class ChecklistItemComponent extends EtoComponent{

  @Input()
  item: ChecklistItem;


  getText() {
    return EtoTranslation.getName(this.item);
  }
}
