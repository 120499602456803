import {MenuItem} from "primeng/api";
import {JobTemplate} from "../../../model/api/response/job-template";
import {EtoTranslation} from "../../translation/eto-translation";
import {Site} from "../../../model/api/response/site";
import {FilterDate} from "../../../model/ui/filter-date";
import {FilterDateType} from "../../../model/ui/filter-date-type";
import {TimeWindow} from "../../../model/api/request/time-window";
import {TooltipOptions} from "primeng/tooltip";

export class FilterMenuProvider{

    public static getJobTemplatesMenu(data: JobTemplate[], onSelected: (jobTemplate: JobTemplate) => void):MenuItem[]{
        const result: MenuItem[] = [];
        for (const template of data) {
            let item: MenuItem = {
                label: EtoTranslation.getName(template),
                id: template.id.toString(),
                command: () => {
                    onSelected(template)
                }
            };

            let subtitle = EtoTranslation.getSubtitle(template);
            if (!!subtitle){
                item.tooltipOptions = {tooltipLabel: subtitle};
                item.title = '{tooltipLabel: subtitle};'
                item.tooltip = '{tooltipLabel: subtitle}';
            }
            result.push(item);
        }
        return result;
    }

    public static getDestinationMenu(data: Site[], selectedRegionName: string, onSelected: (site: Site) => void):MenuItem[]{
        const result: MenuItem[] = [];
        result.push({
            label: EtoTranslation.getInstant('anywhere_in_region', {region: selectedRegionName}),
            id: null,
            command:()=>{
                onSelected(null)
            }
        })
        for (const site of data) {
            result.push(
                {
                    label: site.name,
                    id: site.id.toString(),
                    command: () => {
                        onSelected(site)
                    }
                }
            )
        }
        return result;
    }

    public static getDateMenu(data: FilterDate[],  onSelected: (date: FilterDate) => void):MenuItem[]{
        const result: MenuItem[] = [];
        for (const date of data) {
            result.push(
                {
                    label: this.getDateLabel(date.type),
                    id: Object.values(FilterDateType).indexOf(date.type).toString(),
                    command: () => {
                        onSelected(date)
                    }
                }
            )
        }
        return result;
    }

    public static getTimeMenu(data: TimeWindow[],  onSelected: (timeWindow: TimeWindow) => void):MenuItem[]{
        const result: MenuItem[] = [];
        for (const timeWindow of data) {
            result.push(
                {
                    label: this.getTimeLabel(timeWindow),
                    id: Object.values(TimeWindow).indexOf(timeWindow).toString(),
                    command: () => {
                        onSelected(timeWindow)
                    }
                }
            )
        }
        return result;
    }

    public static getDateLabel(type: FilterDateType):string {
        switch (type){
            case FilterDateType.NOW:
                return EtoTranslation.getInstant('asap');
            case FilterDateType.IN_A_WEEK:
                return EtoTranslation.getInstant('in_a_week');
            case FilterDateType.IN_A_MONTH:
                return EtoTranslation.getInstant('in_a_month');
            case FilterDateType.EXACT_DATE:
            default:
                return EtoTranslation.getInstant('select_date');
        }
    }

    public static getTimeLabel(timeWindow: TimeWindow):string {
        switch (timeWindow){
            case TimeWindow.ALL_DAY:
                return EtoTranslation.getInstant('all_day');
            case TimeWindow.MORNING:
                return EtoTranslation.getInstant('morning');
            case TimeWindow.LATE_MORNING:
                return EtoTranslation.getInstant('late_morning');
            case TimeWindow.AFTERNOON:
                return EtoTranslation.getInstant('afternoon');
            case TimeWindow.EVENING:
                return EtoTranslation.getInstant('evening');
            default:
                return EtoTranslation.getInstant('na');
        }
    }
}
