import {AbstractFilterLoader} from "./abstract-filter-loader";
import {Region} from "../../../model/api/response/region";
import {HttpErrorResponse} from "@angular/common/http";
import {MenuItem} from "primeng/api";
import {RegionsMenuProvider} from "../../menu/region/regions-menu-provider";
import {RegionListener} from "../../menu/region/region-listener";
import {FilterType} from "../filter-type";
import {FilterResult} from "../filter-result";
import {EtoTranslation} from "../../translation/eto-translation";

export class RegionFilterLoader extends AbstractFilterLoader<Region[]> implements RegionListener {
    private regionsMenuProvider = new RegionsMenuProvider(this);

    protected getDataFromApi(onSuccess: (data: Region[]) => void): void {
        this.updateProgress(true);
        this.apiService.getRegions(this.filterResult.filter.lat, this.filterResult.filter.lng).execute(this.notifier, data => {
            this.updateProgress(false);
            onSuccess(data.filter(value => value.sitesCount > 0));
        }, apiError => {
            this.updateWithError(apiError)
            this.updateProgress(false);
        })
    }

    protected convertDataToMenuItems(data: Region[], filterResult: FilterResult): MenuItem {
        this.result = {
            automationId: FilterType.REGION,
            label: 'Region',
            items: this.regionsMenuProvider.getMenu(data, filterResult.filter.selectedRegionId)
        };
        let selectedRegionId = this.filterResult.filter.selectedRegionId;
        const selection = !!selectedRegionId && data.flatMap(value => value.id).indexOf(selectedRegionId) != -1
            ? data.filter(value => value.id == selectedRegionId)[0]
            : data[0];
        this.onRegionSelected(selection, false);
        return this.result;
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [FilterType.SITE_TYPE, FilterType.DESTINATION, FilterType.JOB];
    }


    onRegionSelected(region: Region, manual: boolean): void {
        this.result.items.forEach(value => value.disabled = false);
        if (!region) {
            if (manual) {
                this.detectRegionFromGPS();
            } else {

                this.result.label = EtoTranslation.getInstant('select_region');
                this.filterResult.filter.selectedRegionId = null;
                this.filterResult.filter.selectedRegionName = null;
                this.filterResult.filter.selectedRegionHasSites = false;
            }
            return;
        }
        let selected = this.result.items.filter(value => value.id == region.id.toString());
        if (selected.length > 0) {
            selected[0].disabled = true;
            this.filterResult.filter.selectedRegionId = Number.parseInt(selected[0].id);
            this.filterResult.filter.selectedRegionName = selected[0].label;
            this.filterResult.filter.selectedRegionHasSites = region.sitesCount > 0;
        }
        if (manual) {
            this.onItemSelectedManually();
        } else {
            this.onItemSelected();
        }
    }

    private detectRegionFromGPS() {
        this.updateProgress(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const longitude = position.coords.longitude;
                const latitude = position.coords.latitude;
                this.getRegionByGPS(latitude, longitude);
            }, positionError => {
                console.error(positionError);
                this.updateProgress(false);
                let locationError = new HttpErrorResponse({status: 0, statusText: "Localization declined"});
                this.updateWithError(locationError);
            });
        } else {
            this.updateProgress(false);
            let locationError = new HttpErrorResponse({status: 0, statusText: "Localization declined"});
            this.updateWithError(locationError);
        }
    }

    private getRegionByGPS(latitude: number, longitude: number) {
        this.updateProgress(true);
        this.filterResult.filter.lat = latitude;
        this.filterResult.filter.lng = longitude;
        this.apiService.getRegion(latitude, longitude).execute(this.notifier, data => {
            this.updateProgress(false);
            let foundInRegions = this.result.items.filter(value => value.id == data.id.toString()).length > 0;
            if (!foundInRegions) {
                this.result.items = this.regionsMenuProvider.getRegionsWithOneFoundFromGps(data);
            }
            this.onRegionSelected(data, true);
        }, apiError => {
            this.updateProgress(false);
            if (apiError.status == 404) {
                this.onRegionSelected(null, false);
                return;
            }
            this.updateWithError(apiError);
        })
    }


}
