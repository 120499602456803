import {AbstractFilterLoader} from "./abstract-filter-loader";
import {SiteType} from "../../../model/api/response/site-type";
import {FilterResult} from "../filter-result";
import {MenuItem} from "primeng/api";
import {FilterType} from "../filter-type";
import {Site} from "../../../model/api/response/site";
import {TypeMenuProvider} from "../../menu/type/type-menu-provider";

export class TypesFilterLoader extends AbstractFilterLoader<Site[]> {
    private typesMenuProvider: TypeMenuProvider = new TypeMenuProvider();
    protected convertDataToMenuItems(data: Site[], filterResult: FilterResult): MenuItem {
        let items = this.typesMenuProvider.getTypesMenu(data, type => {
            this.onSiteTypeSelected(type, true)
        });
        this.result =  {
            automationId: FilterType.SITE_TYPE,
            icon:'job-icon',
            label: 'SiteTypes',
            items: items
        };
        let selectedType = this.filterResult.filter.selectedType;
        const selection = !!selectedType && items.flatMap(value => Number.parseInt(value.id)).indexOf(Object.values(SiteType).indexOf(selectedType)) !=-1
            ? selectedType
            : Object.values(SiteType)[Number.parseInt(items[0].id)];
        this.onSiteTypeSelected(selection, false);
        return this.result;
    }

    protected getDataFromApi(onSuccess: (data: Site[]) => void): void {
        this.updateProgress(true);
        this.apiService.getSites(this.filterResult.filter.selectedRegionId, null, null, null).execute(this.notifier, data => {
            this.updateProgress(false);
            onSuccess(data);
        }, apiError => {
            this.updateWithError(apiError)
            this.updateProgress(false);
        })
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [FilterType.DESTINATION, FilterType.JOB];
    }

    onSiteTypeSelected(type: SiteType, manual: boolean): void {
        this.result.items.forEach(value => value.disabled = false);
        let selected = this.result.items.filter(value => value.id == this.getId(type));
        if (selected.length > 0) {
            selected[0].disabled = true;
            this.filterResult.filter.selectedType = Object.values(SiteType)[Number.parseInt(selected[0].id)];
            this.result.label = selected[0].label;
        }
        if (manual){
            this.onItemSelectedManually();
        }
        else{
            this.onItemSelected();
        }
    }

    private getId(type: SiteType): string {
        return Object.values(SiteType).indexOf(type).toString();
    }
}
