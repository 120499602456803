import {VacancyCard, VacancyItemType} from "./vacancy-card";
import {SingleVacancyCard} from "./single-vacancy-card";

export class MultiVacancyCard extends VacancyCard{
    cards:SingleVacancyCard[] = [];
    minPrice: number;


    constructor() {
        super(VacancyItemType.MULTI_CARD);
    }
}
