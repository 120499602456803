import {ApiService} from "../api/api.service";
import {FilterType} from "./filter-type";
import {AbstractFilterLoader} from "./loader/abstract-filter-loader";
import {MenuItem} from "primeng/api";
import {RegionFilterLoader} from "./loader/region-filter-loader";
import {Observable} from "rxjs";
import {FilterResult} from "./filter-result";
import {TypesFilterLoader} from "./loader/types-filter-loader";
import {JobsFilterLoader} from "./loader/jobs-filter-loader";
import {SitesFilterLoader} from "./loader/sites-filter-loader";
import {DateFilterLoader} from "./loader/date-filter-loader";
import {TimeFilterLoader} from "./loader/time-filter-loader";
import {VehicleFilterLoader} from "./loader/vehicle-filter-loader";

export class FilterFactory {
    apiService: ApiService;
    result: Map<FilterType, MenuItem> = new Map<FilterType, MenuItem>();
    allFilterType:FilterType[] = [FilterType.REGION, FilterType.SITE_TYPE, FilterType.VEHICLE, FilterType.JOB,   FilterType.START_DATE, FilterType.TIME_WINDOW, FilterType.DESTINATION];


    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    loadAllFilterOptions(notifier: Observable<any>,
                         filterResult: FilterResult,
                         onSuccess: (filterMenu: MenuItem[]) => void,
                         onStateChanged: () => void,
                         onSelectionChanged: (needsUpdate:FilterType[]) => void) {
        this.loadFilterOptionsSelected(this.allFilterType, filterResult, notifier, onSuccess,  onStateChanged, onSelectionChanged)
    }

    loadFilterOptionsSelected(filterTypes: FilterType[],
                              filterResult: FilterResult,
                              notifier: Observable<any>,
                              onSuccess: (filterMenu: MenuItem[]) => void,
                              onStateChanged: () => void,
                              onSelectionChanged: (needsUpdate:FilterType[]) => void) {
        this.executeLoaderForIndex(filterTypes, 0, filterResult, onSuccess, notifier,  onStateChanged, onSelectionChanged);
    }

    private executeLoaderForIndex(filterTypes: FilterType[], index: number, filterResult: FilterResult, onSuccess: (filterMenu: MenuItem[]) => void, notifier: Observable<any>, onStateChanged: () => void, onSelectionChanged: (needsUpdate:FilterType[]) => void) {
        if (filterTypes.length <= index) {
            onSuccess(Array.from(this.result.values()));
            return;
        }

        let filterType = filterTypes[index];
        if (filterType!=FilterType.REGION && !filterResult.filter.selectedRegionHasSites){
            return;
        }
        let loaderForType = this.getLoadersForType(filterType, notifier, filterResult,  onStateChanged, onSelectionChanged);

        if (!loaderForType){
            this.executeLoaderForIndex(filterTypes, ++index, filterResult, onSuccess, notifier,  onStateChanged, onSelectionChanged);
            return;
        }
        loaderForType.loadData(filterResult, data => {
            this.result.set(filterType, data);
            this.executeLoaderForIndex(filterTypes, ++index, filterResult, onSuccess, notifier,  onStateChanged, onSelectionChanged);
        })
    }

    private getLoadersForType(filterType: FilterType, notifier: Observable<any>, filterResult: FilterResult, onStateChanged: () => void, onSelectionChanged: (needsUpdate:FilterType[]) => void): AbstractFilterLoader<any> {
        switch (filterType) {
            case FilterType.REGION:
                return new RegionFilterLoader(FilterType.REGION, this.apiService, notifier, filterResult, onStateChanged, onSelectionChanged);
            case FilterType.SITE_TYPE:
                return new TypesFilterLoader(FilterType.SITE_TYPE, this.apiService, notifier, filterResult,  onStateChanged, onSelectionChanged);
            case FilterType.JOB:
                return new JobsFilterLoader(FilterType.JOB, this.apiService, notifier, filterResult,  onStateChanged, onSelectionChanged);
            case FilterType.DESTINATION:
                return new SitesFilterLoader(FilterType.DESTINATION, this.apiService, notifier, filterResult,  onStateChanged, onSelectionChanged);
            case FilterType.START_DATE:
                return new DateFilterLoader(FilterType.START_DATE, this.apiService, notifier, filterResult,  onStateChanged, onSelectionChanged);
            case FilterType.TIME_WINDOW:
                return new TimeFilterLoader(FilterType.TIME_WINDOW, this.apiService, notifier, filterResult,  onStateChanged, onSelectionChanged);
            case FilterType.VEHICLE:
                return new VehicleFilterLoader(FilterType.VEHICLE, this.apiService, notifier, filterResult,  onStateChanged, onSelectionChanged);
            default:
                return undefined;
        }
    }

}
