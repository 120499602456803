import {Component, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {Reservation} from "../../model/api/response/reservation";
import {Constants} from "../../constants/constants";
import {LabelUtil} from "../../service/util/label-util";
import {EtoTranslation} from "../../service/translation/eto-translation";
import {ReservationState} from "../../model/api/response/reservation-state";

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.screen.html',
  styleUrls: ['./reservation.screen.scss'],
})
export class ReservationScreen extends EtoComponent{
  reservation: Reservation;

  override ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;
    const id = this.route.snapshot.paramMap.get(Constants.PathParam.id);
    this.loadReservation(id);
  }

  private loadReservation(id: string) {

    this.apiService.getReservation(id).execute(this.destroyed$, data => {
      this.reservation = data;
      this.isLoading = false;
    }, apiError => {
      this.showGenericError();
      this.isLoading = false;
    })
  }

  getReservationDateText() {
    return LabelUtil.getReservationDateFull(this.reservation.startDate)
  }

  getReservationStateText() {
    return LabelUtil.getReservationStateLabel(this.reservation.state);
  }

  getPrice() {
    const guaranteedPrice = !!this.reservation.priceMetadata && this.reservation.priceMetadata.guaranteedPrice;
    if (!guaranteedPrice ){
      return LabelUtil.formatPriceFrom(this.reservation.price.toString());
    }
    else{
      return LabelUtil.formatPrice(this.reservation.price.toString());
    }
  }

  getAddress() {
    let site = this.reservation.carService;
    return LabelUtil.formatFullAddress(site.address, site.zipCode, site.city);
  }
  getJobTitle() {
    return LabelUtil.getJobTitle(this.reservation.job);
  }

  onCancelReservationClicked() {
      this.confirmationService.confirm({
        message: EtoTranslation.getInstant('cancel_reservation_prompt'),
        accept: () => {
          let id = this.reservation.id.toString();
          this.isLoading = true;
          this.apiService.cancelReservation(id).execute(this.destroyed$, data => {
            this.isLoading = false;
            this.loadReservation(id);
          }, apiError => {
            this.isLoading = false;
            this.showGenericError();
          })
        }
      });
  }

  onSiteDetailClicked() {
    this.router.navigate([Constants.Route.site, this.reservation.carService.id.toString()]);
  }

  canDisplayCancelButton(): boolean{
    return this.reservation.state == ReservationState.PENDING;
  }

  shouldDisplayChecklist() {
      return !!this.reservation.priceMetadata && !!this.reservation.priceMetadata.checkListItems && this.reservation.priceMetadata.checkListItems.length > 0;
  }

  getPriceLabel() {
    const guaranteedPrice = !!this.reservation.priceMetadata && this.reservation.priceMetadata.guaranteedPrice;
    return EtoTranslation.getInstant(!guaranteedPrice ? 'expected_price' : 'guaranteed_price');
  }

  getPriceTooltip() {
    const guaranteedPrice = !!this.reservation.priceMetadata && this.reservation.priceMetadata.guaranteedPrice;
    return EtoTranslation.getInstant(!guaranteedPrice ? 'summary_expected_price_tooltip' : 'summary_guaranteed_price_tooltip');
  }
}
