import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Constants} from "../../constants/constants";
import {EtoTranslation} from "../../service/translation/eto-translation";
import {Site} from "../../model/api/response/site";
import {PromoMessage} from "../../model/api/response/promo-message";
import {DateFormatter} from "../../service/util/date-formatter";
import {PromoMessageType} from "../../model/api/response/promo-message-type";
import {takeUntil} from "rxjs/operators";
import {VacanciesComponent} from "../../components/vacancies-component";
import {Filter} from "../../model/ui/filter";
import {FilterResult} from "../../service/filter/filter-result";
import {FilterType} from "../../service/filter/filter-type";
import {KeyValue} from "@angular/common";
import {Token} from "../../model/api/response/token";
import {AuthState} from "../../model/ui/auth-state";
import {MarketingVacancyCard} from "../../model/ui/vacancy/marketing-vacancy-card";

@Component({
    selector: 'app-home',
    templateUrl: './home.screen.html',
    styleUrls: ['./home.screen.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeScreen extends VacanciesComponent implements OnInit {
    loadingRegions: boolean;
    loadingSites: boolean;
    loadingTypes: boolean;

    regionsMenu: MenuItem;
    typesMenu: MenuItem[];

    typesActiveIndex: number = 0;

    promoMessages: PromoMessage[];
    sites: Site[] = [];
    showAuthModal: boolean;
    authState: AuthState = AuthState.REGISTRATION;


    constructor(injector: Injector) {
        super(injector);
    }


    private observeSessionStateChange() {
        this.apiService.getObservableSession().pipe(takeUntil(this.destroyed$)).subscribe(sessionActive => {
            this.initPromoMessages();
            this.initFilter();
            this.loadVehicle();
        })
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.observeSessionStateChange();
        this.filterService.getFilterResultObservable().pipe(takeUntil(this.destroyed$)).subscribe(value => {
            this.processFilterResult(value);
        })
        this.initPromoMessages();
        this.initFilter();
        this.loadVehicle();
    }


    getRegionLabel() {
        if (this.hasSelectedRegion()) {
            return (this.regionsMenu.items.filter(value => value.disabled))[0].label;
        } else {
            return this.regionsMenu.label
        }
    }

    hasSelectedRegion() {
        return this.regionsMenu && this.regionsMenu.items.filter(value => value.disabled).length > 0;
    }
    private initPromoMessages() {
        let installDate = DateFormatter.fromAPIFormat(localStorage.getItem(Constants.Keys.installDate));
        if (!!installDate) {
            installDate = new Date();
            localStorage.setItem(Constants.Keys.installDate, DateFormatter.toApiDateFormat(installDate))
        }
        this.apiService.getPromoMessages(DateFormatter.toApiDateTimeFormat(new Date()), null).execute(this.destroyed$, data => {
            this.promoMessages = data;
            this.promoService.checkAndShowNotifications(this.promoMessages);
        }, apiError => {
            this.showGenericError();
        })
    }

    onPromoItemClicked(promoMessage: PromoMessage) {
        switch (promoMessage.type) {
            case PromoMessageType.RESERVATION_UPCOMING:
                this.showReservation(Number.parseInt(promoMessage.args[3]));
                break;
            case PromoMessageType.FUNCTIONAL_NO_USER_PROFILE:
                this.showSignUp();
                break;
            case PromoMessageType.FUNCTIONAL_NO_VEHICLE_ON_PROFILE:
                this.showVehicleScreen();
                break;
            case PromoMessageType.MARKETING_NEW_REGION:
            case PromoMessageType.MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE:
            case PromoMessageType.MARKETING_GENERIC:
            case PromoMessageType.VOUCHER_PENDING:
            case PromoMessageType.VOUCHER_READY:
            case PromoMessageType.VOUCHER_PROMO:
                window.location.href = promoMessage.args[0];
                break;
            case PromoMessageType.MARKETING_FIRST_LOGIN:
            case PromoMessageType.MARKETING_SEASONAL_AUTUMN:
            case PromoMessageType.MARKETING_SEASONAL_SPRING:
                this.scrollToVacancies();
                break;
        }
    }

    private showSignUp() {
        this.showAuthModal = true;
    }

    private showVehicleScreen() {
        this.vehicleModalDisplayed = true;
    }

    private showReservation(reservationId: number) {
        this.router.navigate([Constants.Route.reservation, reservationId])
    }

    private scrollToVacancies() {
        console.log('scrolling');
        this.router.navigate([], { fragment: 'vacanciesList' }).then(res => {
            const element = document.getElementById('vacanciesList');
            if (element != undefined) element.scrollIntoView({behavior: 'smooth' });
        });
    }


    onTabChanged() {
        this.typesMenu[this.typesActiveIndex].command();
    }

    onSiteClicked(site: Site) {
        this.router.navigate([Constants.Route.site, site.id.toString()]);
    }

    private checkRegionChange(filter: Filter) {
        if (!filter.selectedRegionId){
            return;
        }
        if (this.sites.length == 0 || this.sites[0].region.id != filter.selectedRegionId) {
            this.loadingSites = true;
            this.apiService.getSites(filter.selectedRegionId, null, filter.lat, filter.lng).execute(this.destroyed$, data => {
                this.sites = data;
                this.loadingSites = false;
            }, apiError => {
                this.showGenericError();
                this.loadingSites = false;
            })
        }
    }


    protected override processFilterResult(result: FilterResult) {
        super.processFilterResult(result);
        this.checkRegionChange(result.filter);
    }


    protected override bindProgress(progress: KeyValue<FilterType, boolean>[]) {
        super.bindProgress(progress);
        for (const progressItem of progress) {
            switch (progressItem.key) {
                case FilterType.REGION:
                    this.loadingSites = progressItem.value;
                    this.loadingRegions = progressItem.value;
                    break;
                case FilterType.SITE_TYPE:
                    this.loadingTypes = progressItem.value;
                    break;
            }
        }
    }


    protected override bindMenuItems(items: MenuItem[]) {
        super.bindMenuItems(items);
        this.filterMenu = [];
        items.forEach(item => {
            switch (item.automationId) {
                case FilterType.REGION:
                    this.regionsMenu = item;
                    break;
                case FilterType.SITE_TYPE:
                    this.typesMenu = item.items;
                    if (!!this.typesMenu) {
                        let selected = this.typesMenu.filter(value => value.disabled == true);
                        this.typesActiveIndex = selected.length > 0 ? this.typesMenu.indexOf(selected[0]) : 0;
                    }
                    break;
                default:
                    this.filterMenu.push(item);
            }
        })
    }

    isRegionValid() {
        return this.filterService.getFilter().selectedRegionHasSites && !!this.filterService.getFilter().selectedRegionId;
    }

    getUnknownRegionTitle() {
        let selectedRegionName = this.filterService.getFilter().selectedRegionName;
        let selectedRegionId = this.filterService.getFilter().selectedRegionId;
        if (!!selectedRegionName && !!selectedRegionId){
            return EtoTranslation.getInstant('no_sites_region', {region: selectedRegionName});
        }
        return EtoTranslation.getInstant('unknown_region', {region: selectedRegionName})
    }

    tokenReceived(token: Token) {
        this.apiService.storeToken(token);
    }


    protected override getPromoMessages(): PromoMessage[] {
        return this.promoMessages;
    }

    onMarketingCardClicked(card: MarketingVacancyCard) {
        this.onPromoItemClicked(card.message);
    }

    onToastMessageClosed(event: any) {
        this.promoService.toastClosed(event.message.id);
    }

    onToastMessageClicked(event: any) {
        if (event.target.className.toString().includes('close')){
            return;
        }
        let messageId = event.target.offsetParent.id;
        const matchingMessages = this.getPromoMessages().filter(value => value.id == messageId);
        if (matchingMessages.length == 1){
            this.onPromoItemClicked(matchingMessages[0]);
        }
    }

    isWizardVisible() {
        return !this.isHandsetPortrait && !this.isVehicleCreated()
    }
}
