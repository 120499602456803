<p-toast (onClose)="onToastMessageClosed($event)" (click)="onToastMessageClicked($event)"></p-toast>
<app-calendar-modal [(display)]="calendarDisplayed" (onDateSelected)="onDateSelected($event)"></app-calendar-modal>
<app-vehicle-modal [vehicle]="vehicle" [(display)]="vehicleModalDisplayed" (onVehicleCreatedOrUpdate)="onVehicleUpdated($event)"></app-vehicle-modal>
<app-auth-modal *ngIf="showAuthModal"
                [state]="authState"
                [(display)]="showAuthModal"
                (onAuthorisationDone)="tokenReceived($event)">
</app-auth-modal>
<p-sidebar [dismissible]="true" [blockScroll]="true" *ngIf="!!groupSections"
           [(visible)]="groupVacanciesResultVisible" position="right" [baseZIndex]="10000">
    <app-vacancies-list [loadingAll]="false"
                        [vehicleCreated]="isVehicleCreated()"
                        (onSingleCardClicked)="onSingleCarClicked($event)"
                        (onMultiCardClicked)="onMultiCardClicked($event)"
                        (onGroupCardClicked)="onGroupCardClicked($event)"
                        [sections]="groupSections">
    </app-vacancies-list>
</p-sidebar>
<p-sidebar [dismissible]="true" [blockScroll]="true" *ngIf="vacancyPreviewVisible"
           [(visible)]="vacancyPreviewVisible" position="right" [baseZIndex]="10000">
    <app-vacancy-preview [vacancyPreview]="vacancyPreview" (onVacancyConfirmed)="onVacancySelected($event)">
    </app-vacancy-preview>
</p-sidebar>

<div xmlns:style="http://www.w3.org/1999/xhtml">
    <div style="display:flex;flex-direction:row">
        <div class="column-left" style="align-items: end" [pTooltip]="null">
            <button pButton pRipple [label]="getRegionLabel()" type="button" icon="pi pi-chevron-down" iconPos="right"
                    class="p-button-outlined"
                    style="height:fit-content; margin-bottom:32px"
                    [loading]="loadingRegions"
                    (click)="menu.toggle($event)">
                <i style="padding-right:8px" class="pi pi-map-marker font-icon"></i>
            </button>
            <p-menu #menu [popup]="true" [model]="regionsMenu.items">
                <ng-template let-item pTemplate="item">
                    <div [pTooltip]="item.title" tooltipPosition="right">
                    </div>
                </ng-template>
            </p-menu>
            <button pButton pRipple type="button" icon="pi pi-angle-double-right"
                    *ngIf="hasSelectedRegion()"
                    class="p-button-rounded p-button-text"
                    (click)="op.toggle($event)"
                    style="height:40px;width:40px;margin-bottom:32px;margin-left:8px"
                    [loading]="loadingRegions || loadingSites">
            </button>
            <p-overlayPanel #op [dismissable]="true" class="search-overlay">
                <ng-template pTemplate>
                    <app-sites-list [title]="getRegionLabel()" [isLoading]="loadingSites"
                                    (onSiteClicked)="onSiteClicked($event)"
                                    [sites]="sites"></app-sites-list>
                </ng-template>
            </p-overlayPanel>
        </div>
        <div *ngIf="!isHandsetPortrait && (loadingRegions || isRegionValid())" class="column-center"
             style="flex-grow:3">
            <p-tabView styleClass="tabview-custom" [(activeIndex)]="typesActiveIndex"
                       (activeIndexChange)="onTabChanged()">
                <p-tabPanel *ngFor="let type of typesMenu" [disabled]="loadingTypes">
                    <ng-template pTemplate="header">
                        <i [ngClass]="type.icon"></i>
                        <span>{{type.label}}</span>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div class="column-right" *ngIf="!isHandsetPortrait"></div>
    </div>
    <app-empty-state *ngIf="!loadingRegions && !isRegionValid()" [text]="getUnknownRegionTitle()"
                     [buttonText]="''"></app-empty-state>
    <app-vehicle-wizard *ngIf="isWizardVisible()"
                        (onWizardCompleted)="onVehicleUpdated($event)"></app-vehicle-wizard>
    <div style="display:flex;flex-direction:column" *ngIf="loadingRegions || isRegionValid()">
        <p-menubar [model]="filterMenu" id="vacanciesList">
            <ng-template pTemplate="end">
                <i class="pi pi-spin pi-spinner font-icon" *ngIf="loadingFilter"></i>
            </ng-template>
        </p-menubar>
        <app-vacancies-list style="min-height: 50vh"
                            [sections]="vacancySections"
                            [vehicleCreated]="isVehicleCreated()"
                            [loadMoreEnabled]="true"
                            (onMultiCardClicked)="onMultiCardClicked($event)"
                            (onGroupCardClicked)="onGroupCardClicked($event)"
                            (onSingleCardClicked)="onSingleCarClicked($event)"
                            (onMarketingCardClicked)="onMarketingCardClicked($event)"
                            (onShowMoreClicked)="loadMoreVacancies()"
                            [isLoading]="loadingFilter"
                            [loadingAll]="loadingAllVacancies">
        </app-vacancies-list>
    </div>
    <div class="tabs-mobile" *ngIf="isHandsetPortrait">
        <div style="display:flex; justify-content:center">
            <p-tabView [(activeIndex)]="typesActiveIndex" (activeIndexChange)="onTabChanged()">
                <p-tabPanel *ngFor="let type of typesMenu" [disabled]="loadingTypes">
                    <ng-template pTemplate="header">
                        <i [ngClass]="type.icon"></i>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>

