import {NgModule} from "@angular/core";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {MenuModule} from "primeng/menu";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SitesListComponent} from './list/sites-list/sites-list.component';
import {SiteItemComponent} from './item/site-item/site-item.component';
import {TableModule} from "primeng/table";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {LanguageSwitchComponent} from "./widget/language-switch/language-switch.component";
import {SelectButtonModule} from "primeng/selectbutton";
import { PhoneInputComponent } from './widget/phone-input/phone-input.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { CodeInputComponent } from './widget/code-input/code-input.component';
import {CodeInputModule} from "angular-code-input";
import {TranslationModule} from "../service/translation/translation-module";
import { VacanciesListComponent } from './list/vacancies-list/vacancies-list.component';
import { VacancyItemComponent } from './item/vacancy-item/vacancy-item.component';
import {ShimmerModule} from "@sreyaj/ng-shimmer";
import { ReservationsListComponent } from './list/reservations-list/reservations-list.component';
import { ChecklistListComponent } from './list/checklist-list/checklist-list.component';
import { ChecklistItemComponent } from './item/checklist-item/checklist-item.component';
import { FilterWidgetComponent } from './widget/filter-widget/filter-widget.component';
import {MenubarModule} from "primeng/menubar";
import { MessageItemComponent } from './item/message-item/message-item.component';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        InputTextModule,
        RippleModule,
        ButtonModule,
        MenuModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        TableModule,
        SelectButtonModule,
        NgxIntlTelInputModule,
        CodeInputModule,
        TranslationModule,
        ShimmerModule,
        MenubarModule
    ],
    exports: [
        BrowserAnimationsModule,
        InputTextModule,
        RippleModule,
        ButtonModule,
        MenuModule,
        FormsModule,
        OverlayPanelModule,
        SitesListComponent,
        SiteItemComponent,
        ProgressSpinnerModule,
        TableModule,
        LanguageSwitchComponent,
        SelectButtonModule,
        PhoneInputComponent,
        CodeInputComponent,
        VacanciesListComponent,
        ReservationsListComponent,
        ChecklistListComponent,
        MessageItemComponent

    ],
    declarations: [
        SitesListComponent,
        SiteItemComponent,
        LanguageSwitchComponent,
        PhoneInputComponent,
        CodeInputComponent,
        VacanciesListComponent,
        VacancyItemComponent,
        ReservationsListComponent,
        ChecklistListComponent,
        ChecklistItemComponent,
        FilterWidgetComponent,
        MessageItemComponent
    ]
})

export class ComponentsModule {
}
