import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {ApiService} from "../api/api.service";
import {PromoMessageUtil} from "./promo-message-util";
import {PromoMessage} from "../../model/api/response/promo-message";
import {Constants} from "../../constants/constants";

@Injectable()
export class PromoMessageService{

    constructor(private messageService: MessageService, private apiService: ApiService) {
    }

    checkAndShowNotifications(messages: PromoMessage[]){
        if (!this.apiService.isUserLoggedIn()){
            return;
        }
        this.apiService.getToken();
        let promoMessages = PromoMessageUtil.importantMessageForNotifications(messages);
        let seenIdsItem = localStorage.getItem(Constants.Keys.seenIds);
        if (!!seenIdsItem){
            const seenIds:string[] = JSON.parse(seenIdsItem);
            promoMessages = promoMessages.filter(value => seenIdsItem.indexOf(value.id) == -1)
        }
        this.displayNotifications(promoMessages);
    }

    private displayNotifications(promoMessages: PromoMessage[]) {
        this.messageService.clear();
        this.messageService.addAll(promoMessages.map(value => {
            return {
                severity:'success',
                id:value.id,
                data: value,
                sticky:true,
                summary:PromoMessageUtil.getTitle(value),
                detail:PromoMessageUtil.getText(value),
                closable:true,
            }
        }));
    }

    toastClosed(id: string) {
        let seenIdsItem = localStorage.getItem(Constants.Keys.seenIds);
        if (!!seenIdsItem){
            const seenIds:string[] = JSON.parse(seenIdsItem);
            seenIds.push(id)
            localStorage.setItem(Constants.Keys.seenIds, JSON.stringify(seenIds));
        }
        else{
            localStorage.setItem(Constants.Keys.seenIds, JSON.stringify([id]));
        }
    }
}
