import {TranslatePipe, TranslateService} from "@ngx-translate/core";
import {Pipe, PipeTransform} from "@angular/core";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";
import {MultiLanguageEntity} from "../../model/api/response/multi-language-entity";
import {PrimeNGConfig} from "primeng/api";

const SELECTED_LNG_KEY = 'def_lng';

const SUPPORTED_LANGUAGE_CODES = ['en', 'sk'];

export class EtoTranslation {

    private static instance: EtoTranslation;
    private translateService: TranslateService;

    private constructor(translateService: TranslateService) {
        this.translateService = translateService;
    }

    public static createInstance(translateService: TranslateService): Observable<any> {
        this.instance = new EtoTranslation(translateService);
        const selectedLanguage = this.loadSelectedLanguage(translateService);
        return EtoTranslation.setLanguage(selectedLanguage);
    }

    private static loadSelectedLanguage(translateService: TranslateService): string {
        let langCode = localStorage.getItem(SELECTED_LNG_KEY);
        if (langCode && SUPPORTED_LANGUAGE_CODES.indexOf(langCode.toLowerCase()) >= 0) {
            return langCode;
        }
        // getBrowserCultureLang ==> user want to localize pages as...
        // getBrowserLang ==> user want to localize browser UI as...
        langCode = translateService.getBrowserCultureLang() || translateService.getBrowserLang();
        if (langCode && SUPPORTED_LANGUAGE_CODES.indexOf(langCode.substring(0, 2).toLowerCase()) >= 0) {
            return langCode.substring(0, 2);
        }
        // Default
        return SUPPORTED_LANGUAGE_CODES[0];
    }

    public static get(key: string, interpolateParams?: Object): Observable<string> {
        if (key == null) {
            // noinspection JSDeprecatedSymbols
            key = Observable.create('error.na');
        }
        return this.instance.translateService.getStreamOnTranslationChange(key, interpolateParams);
    }

    public static getInstant(key: string, interpolateParams?: Object): string {
        if (key == null) {
            key = "error.na";
        }
        return this.instance.translateService.instant(key, interpolateParams);
    }

    public static setLanguage(selectedLanguage: string): Observable<any> {
        localStorage.setItem(SELECTED_LNG_KEY, selectedLanguage);
        this.instance.translateService.setDefaultLang(selectedLanguage);
        return this.instance.translateService.use(selectedLanguage);

    }

    public static getSelectedLang(): string {
        return this.instance.translateService.currentLang;
    }

    public static getName(entity:MultiLanguageEntity):string{
        if (this.getSelectedLang().toLowerCase().startsWith('sk')){
            return entity.skName;
        }
        else{
            return entity.enName;
        }
    }

    public static getSubtitle(entity:MultiLanguageEntity):string{
        if (this.getSelectedLang().toLowerCase().startsWith('sk')){
            return entity.skSubtitle;
        }
        else{
            return entity.enSubtitle;
        }
    }

    public static onSelectedLang(): Observable<string> {
        return this.instance.translateService.onLangChange
            .pipe(
                filter(value => !!value && !!value.lang),
                map((event, index) => event.lang)
            );
    }

    static registerPrimengLocalization(primengConfig: PrimeNGConfig) {
        this.instance.translateService.get('primeng').subscribe(res => primengConfig.setTranslation(res));

    }
}

@Pipe({
    name: 'etotranslate',
    pure: false
})
export class EtoTranslationPipe extends TranslatePipe implements PipeTransform {

    override transform(key: string, interpolatePrams?: Object): string {
        // this pipe is non-pure, it will update value by changed lng
        return EtoTranslation.getInstant(key, interpolatePrams);
    }
}

