import {Vacancy} from "./vacancy";

export class VacancyResult{
    startDate: Date;
    endDate: Date;
    minPrice: number;
    minDistance: number;
    items: Vacancy[];

    section: string;
}
