import {enableProdMode, Injectable} from "@angular/core";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
// @ts-ignore
import versionJson from "src/environments/version.json";
import {environment} from "../../../environments/environment";
import {ApiService} from "../api/api.service";
import {Constants} from "../../constants/constants";
import {PixelService} from "ngx-pixel";
import smartlookClient from 'smartlook-client';
import * as uuid from 'uuid';
import {ReCaptchaV3Service} from "ng-recaptcha";




@Injectable()
export class AnalyticsService{
    deviceId: string;


    constructor(private firebaseAnalytics: AngularFireAnalytics, private facebookAnalytics: PixelService, private apiService: ApiService, private captchaService: ReCaptchaV3Service) {
        this.ensureDeviceId();
        let etoBuild = environment.production ? 'release' : 'debug';
        firebaseAnalytics.setUserProperties({
            "eto_platform": "WEB",
            "eto_version": versionJson.version,
            "eto_build": etoBuild
        })
        this.facebookAnalytics.initialize();
        smartlookClient.init('c0b9b396a47d205f3d7b9275d24cfd464be190cf')
        smartlookClient.record({});
    }

    private ensureDeviceId() {
        if (!localStorage.getItem(Constants.Keys.installId)) {
            localStorage.setItem(Constants.Keys.installId, uuid.v4());
        }
        this.deviceId = localStorage.getItem(Constants.Keys.installId);
    }

    public onClickEvent(eventName: string){
        const event = {
            [Keys.VALUE]: eventName,
            [Keys.USER_LOGGED]: this.apiService.isUserLoggedIn(),
            [Keys.DEVICE_ID]: this.deviceId
        };
        this.firebaseAnalytics.logEvent(eventName, event);
        this.facebookAnalytics.trackCustom(eventName, event);
        smartlookClient.track(eventName,event);
        this.captchaService.execute(eventName)
            .subscribe((token: string) => {
                //
            });
    }
}

export enum Keys {
    VALUE = "value",
    DEVICE_ID = "device_id",
    USER_LOGGED = "logged_user"
}
