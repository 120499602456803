import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {VehicleCategoryMenuItem} from "../../model/ui/vehicle-category-menu-item";
import {Vehicle} from "../../model/api/response/vehicle";
import {VehicleOptionsMenuProvider} from "../../service/menu/options/vehicle-options-menu-provider";
import {VehicleOptionMenuItem} from "../../model/ui/vehicle-option-menu-item";
import {VehicleCategory} from "../../model/api/response/vehicle-category";
import {VehicleOption} from "../../model/api/response/vehicle-option";
import {listAnimation, revealAnimation, shakeAnimation, test} from "../../app-animations";

@Component({
    animations: [revealAnimation, shakeAnimation, listAnimation, test],
    selector: 'app-vehicle-wizard',
    templateUrl: './vehicle-wizard.component.html',
    styleUrls: ['./vehicle-wizard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VehicleWizardComponent extends EtoComponent {

    @Output()
    onWizardCompleted: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
    optionsMenuItems: VehicleCategoryMenuItem[];

    vehicle = new Vehicle();

    override ngOnInit() {
        super.ngOnInit();
        this.loadOptions();
    }

    private loadOptions() {
        this.isLoading = true;
        this.apiService.getVehicleOptions().execute(this.destroyed$, data => {
                this.isLoading = false;
                this.optionsMenuItems = VehicleOptionsMenuProvider.getMenu(null, data);
                this.clearDefaultSelections();
            },
            apiError => {
                this.isLoading = false;
                this.showGenericError()
            })
    }

    private clearDefaultSelections() {
        this.optionsMenuItems.forEach(value => {
            value.selected = null;
            value.options.forEach(value1 => {
                value1.selected = false;
            })
        })
    }

   onOptionSelected(category: VehicleCategoryMenuItem, item: VehicleOptionMenuItem) {
       this.recaptchaService.execute('filter_option_select').subscribe(value => {})
        category.selected = item;
        if (this.allSelected()){
            this.menuItemsToVehicleItems();
            this.isLoading = true;
            setTimeout(()=>{
                this.onWizardCompleted.emit(this.vehicle);
            }, 600)
        }
    }

    hasSelection(category: VehicleCategoryMenuItem){
        return !!category.selected;
    }

    onSelectionChangedClicked(category: VehicleCategoryMenuItem) {
        category.selected = null;
    }

    private allSelected() {
        return this.optionsMenuItems.filter(value => !!value.selected).length == this.optionsMenuItems.length;
    }

    public isFirstUnselected(category: VehicleCategoryMenuItem){
        let unselected = this.optionsMenuItems.filter(value => !value.selected);
        if (unselected.length == 0){
            return false;
        }
        return category == unselected[0];
    }

    menuItemsToVehicleItems(){
        this.vehicle.categories = [];
        this.optionsMenuItems.forEach(value => {
            let vehicleCategory = new VehicleCategory();
            vehicleCategory.category = new VehicleOption();
            vehicleCategory.category.id = value.value.category.id;
            vehicleCategory.category.skName = value.value.category.skName;
            vehicleCategory.category.enName = value.value.category.enName;
            vehicleCategory.category.skSubtitle = value.value.category.skSubtitle;
            vehicleCategory.category.enSubtitle = value.value.category.enSubtitle;
            let option = new VehicleOption();
            option = new VehicleOption();
            option.id = value.selected.value.id;
            option.skName = value.selected.value.skName;
            option.enName = value.selected.value.enName;
            option.skSubtitle = value.selected.value.skSubtitle;
            option.enSubtitle = value.selected.value.enSubtitle;
            vehicleCategory.items = [option];
            this.vehicle.categories.push(vehicleCategory);
        })
    }
}
