import {Component, Injector, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MenuItem} from "primeng/api";
import {OverlayPanel} from "primeng/overlaypanel";
import {EtoComponent} from "../../components/eto-component";
import {Site} from "../../model/api/response/site";
import {AuthState} from "../../model/ui/auth-state";
import {Token} from "../../model/api/response/token";
import {LanguageSwitchComponent} from "../../components/widget/language-switch/language-switch.component";
import {MenuListener} from "../../service/menu/user/menu-listener";
import {MenuProvider} from "../../service/menu/user/menu-provider";
import {Constants} from "../../constants/constants";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent extends EtoComponent implements OnInit, MenuListener {
    @ViewChild('language') language: LanguageSwitchComponent;

    sessionActive: boolean;
    searchText: string = "";
    searchResults: Site[] = [];
    searchLoading: boolean;

    items: MenuItem[] = [];
    aboutItems: MenuItem[] = [];

    showAuthModal: boolean;
    authModalState: AuthState;

    constructor(injector: Injector) {
        super(injector);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.sessionActive = this.apiService.isUserLoggedIn();
        this.apiService.token.subscribe(value => {
            this.sessionActive = this.apiService.isUserLoggedIn();
            if (this.sessionActive) {
                this.apiService.getProfile().execute(this.destroyed$, data => {
                    this.setupMenu(data.name);
                }, apiError => {
                    this.showGenericError();
                })
            } else {
                this.setupMenu(null);
            }
        })
    }

    private setupMenu(userName: string) {
        let menuProvider = new MenuProvider(this);
        this.items = menuProvider.getMenu(this.apiService.isUserLoggedIn(), userName, this.isSmallerScreen);
        this.aboutItems = menuProvider.menuForAboutSection();
    }

    onTextCleared(search: HTMLSpanElement, op: OverlayPanel, event: Event) {
        this.searchText = '';
        this.onTextChanged(search, op, event);
    }

    onTextChanged(search: HTMLSpanElement, op: OverlayPanel, event: Event) {
        this.handleOverlay(search, op, event);
        if (!this.searchText) {
            return;
        }
        this.searchLoading = true;
        this.apiService.searchSites(this.searchText).execute(this.destroyed$, data => {
            this.searchLoading = false;
            this.searchResults = data;
        }, apiError => {
            this.showGenericError();
        })
    }

    private handleOverlay(search: HTMLSpanElement, op: OverlayPanel, event: Event) {
        if (!!this.searchText && !op.overlayVisible) {
            op.show(event, search);
        }
        if (!this.searchText && op.overlayVisible) {
            op.hide();
        }
    }

    onProfile(): any {
        this.router.navigate([Constants.Route.you]);
    }

    onLanguage(): any {
        this.language.simulateClick();
    }

    onReservations(): any {
        this.router.navigate([Constants.Route.you, Constants.Route.reservations]);
    }

    onSignout(): any {
        this.apiService.logout();
        this.router.navigate([Constants.Route.home]);
        this.apiService.updateSession(false);
    }

    onLogin() {
        this.recaptchaService.execute('toolbar_login_click').subscribe(value => {})
        this.authModalState = AuthState.LOGIN;
        this.showAuthModal = true;
    }

    onSignUp() {
        this.recaptchaService.execute('toolbar_signup_click').subscribe(value => {})
        this.authModalState = AuthState.REGISTRATION;
        this.showAuthModal = true;
    }

    tokenReceived(token: Token) {
        this.apiService.storeToken(token);
    }


    logoClicked() {
        this.router.navigate([Constants.Route.root]);
    }

    onSiteClicked(site: Site) {
        this.searchText = '';
        this.router.navigate([Constants.Route.site, site.id.toString()]);
    }
}
