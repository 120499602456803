import {NgModule} from "@angular/core";
import {ComponentsModule} from "../components/components-module";
import {ToolbarComponent} from './toolbar/toolbar.component';
import {TranslationModule} from "../service/translation/translation-module";
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import {DialogModule} from "primeng/dialog";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";
import {ToastModule} from "primeng/toast";
import {ShimmerModule} from "@sreyaj/ng-shimmer";
import {CarouselModule} from "primeng/carousel";
import { CalendarModalComponent } from './calendar-modal/calendar-modal.component';
import {CalendarModule} from "primeng/calendar";
import { VehicleModalComponent } from './vehicle-modal/vehicle-modal.component';
import {DropdownModule} from "primeng/dropdown";
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { VacancyPreviewComponent } from './vacancy-preview/vacancy-preview.component';
import { VehicleWizardComponent } from './vehicle-wizard/vehicle-wizard.component';
import {AccordionModule} from "primeng/accordion";

@NgModule({
    imports: [
        ComponentsModule,
        TranslationModule,
        DialogModule,
        NgxIntlTelInputModule,
        FormsModule,
        ReactiveFormsModule,
        CheckboxModule,
        ToastModule,
        ShimmerModule,
        CarouselModule,
        CalendarModule,
        DropdownModule,
        AccordionModule
    ],
    exports: [
        ToolbarComponent,
        AuthModalComponent,
        CalendarModalComponent,
        VehicleModalComponent,
        EmptyStateComponent,
        VacancyPreviewComponent,
        VehicleWizardComponent
    ],
    declarations: [
        ToolbarComponent,
        AuthModalComponent,
        CalendarModalComponent,
        VehicleModalComponent,
        EmptyStateComponent,
        VacancyPreviewComponent,
        VehicleWizardComponent
    ]
})

export class SectionsModule {
}
