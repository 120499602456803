import {SiteType} from "../api/response/site-type";
import {TimeWindow} from "../api/request/time-window";

export class Filter{
    selectedRegionHasSites: boolean = true;
    selectedRegionId: number;
    selectedRegionName: string;
    selectedType: SiteType;
    selectedJobTemplateId: number;
    selectedSiteId: number;
    selectedDay: Date;
    selectedTime: TimeWindow;
    selectedCategoryItemsIds: number[];
    selectedJobName: string;
    lat: number;
    lng: number;
}
