import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Constants} from "./constants/constants";
import {HomeScreen} from "./screens/home/home.screen";
import {ProfileScreen} from "./screens/profile/profile.screen";
import {ReservationScreen} from "./screens/reservation/reservation.screen";
import {SiteScreen} from "./screens/site/site.screen";
import {ConfirmationScreen} from "./screens/confirmation/confirmation.screen";
import {AuthGuard} from "./service/util/auth-guard";
import {FilterWidgetComponent} from "./components/widget/filter-widget/filter-widget.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: Constants.Route.home,
        pathMatch: 'full',
    },
    {
        path: Constants.Route.home,
        component: HomeScreen
    },
    {
        path: Constants.Route.you,
        component: ProfileScreen,
        canActivate: [AuthGuard],
        children:[
            {
                path: Constants.Route.reservations,
                canActivate: [AuthGuard],
                component: ProfileScreen
            }
        ]
    },
    {
        path: Constants.Route.reservation +`/:${Constants.PathParam.id}`,
        canActivate: [AuthGuard],
        component: ReservationScreen
    },
    {
        path: Constants.Route.site +`/:${Constants.PathParam.id}`,
        component: SiteScreen
    },
    {
        path: Constants.Route.confirmation,
        component: ConfirmationScreen
    },
    {
        path: Constants.Route.widget,
        component: FilterWidgetComponent,
        data: {hideToolbar: true}
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
