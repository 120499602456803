import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {VacancySelection} from "../../model/ui/vacancy-selection";
import {LabelUtil} from "../../service/util/label-util";
import {EtoTranslation} from "../../service/translation/eto-translation";

@Component({
  selector: 'app-vacancy-preview',
  templateUrl: './vacancy-preview.component.html',
  styleUrls: ['./vacancy-preview.component.scss']
})
export class VacancyPreviewComponent extends EtoComponent{
  @Input()
  vacancyPreview: VacancySelection;
  @Output()
  onVacancyConfirmed: EventEmitter<VacancySelection> = new EventEmitter<VacancySelection>();

  getAddress() {
    const site = this.vacancyPreview.vacancy.carService;
    return LabelUtil.formatFullAddress(site.address, site.zipCode, site.city);
  }

  getDateTime() {
    return this.isHandsetPortrait
        ? LabelUtil.getReservationDatShort(this.vacancyPreview.vacancy.startDate)
        : LabelUtil.getReservationDateFull(this.vacancyPreview.vacancy.startDate);
  }

  getJobName() {
      return this.vacancyPreview.jobTemplateName;
  }

  getPrice() {
    return !!this.vacancyPreview.vacancy.priceMetadata && this.vacancyPreview.vacancy.priceMetadata.guaranteedPrice
        ? LabelUtil.formatPrice(this.vacancyPreview.vacancy.price.toString())
        : LabelUtil.formatPriceFrom(this.vacancyPreview.vacancy.price.toString());
  }

  getPriceLabel() {
    const guaranteedPrice = !!this.vacancyPreview.vacancy.priceMetadata && this.vacancyPreview.vacancy.priceMetadata.guaranteedPrice;
    return EtoTranslation.getInstant(!guaranteedPrice ? 'expected_price' : 'guaranteed_price');
  }

  getPriceTooltip() {
    const guaranteedPrice = !!this.vacancyPreview.vacancy.priceMetadata && this.vacancyPreview.vacancy.priceMetadata.guaranteedPrice;
    return EtoTranslation.getInstant(!guaranteedPrice ? 'summary_expected_price_tooltip' : 'summary_guaranteed_price_tooltip');
  }
}
