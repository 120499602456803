import {Site} from "../../../model/api/response/site";
import {SiteType} from "../../../model/api/response/site-type";
import {MenuItem} from "primeng/api";
import {LabelUtil} from "../../util/label-util";

export class TypeMenuProvider{


    constructor() {
    }

    private static readonly ALLOWED_TYPES = [SiteType.MOT, SiteType.TIRE_SERVICE, SiteType.CAR_SERVICE];

    getTypesMenu(site: Site[], onSelected: (type: SiteType) => void){
        const allTypes = site.flatMap(value => value.type);
        const uniqueTypes = Object.values(SiteType).filter(value => allTypes.indexOf(value) != -1);
        return TypeMenuProvider.createMenuItems(uniqueTypes, onSelected);
    }

    public static createMenuItems(uniqueTypes: SiteType[],  onSelected: (type: SiteType) => void) {
        const result: MenuItem[] = [];
        uniqueTypes.filter(value => TypeMenuProvider.ALLOWED_TYPES.indexOf(value)!=-1).forEach(value => {
            result.push({
                label: LabelUtil.typeToString(value),
                id: Object.values(SiteType).indexOf(value).toString(),
                icon: `icon-${value}`,
                command: () => {
                    onSelected(value)
                }
            })
        })
        return result;
    }
}
