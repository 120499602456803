import {VacancyCard, VacancyItemType} from "./vacancy-card";

export class GroupVacancyCard extends VacancyCard{
    title: string;
    vacanciesCount: number;
    minPrice: number;


    constructor() {
        super(VacancyItemType.GROUP_CARD);
    }

    cards: VacancyCard[] = [];
}
