import {Component, OnInit} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {UserProfile} from "../../model/api/response/user-profile";
import {EtoTranslation} from "../../service/translation/eto-translation";
import {Vehicle} from "../../model/api/response/vehicle";
import {VehicleCategory} from "../../model/api/response/vehicle-category";
import {UserUpdate} from "../../model/api/request/user-update";
import {Constants} from "../../constants/constants";
import {VehicleUpdate} from "../../model/api/request/vehicle-update";
import {VehicleCreate} from "../../model/api/request/vehicle-create";
import {SimpleReservationGroup} from "../../model/api/response/simple-reservation-group";
import {ReservationGroupType} from "../../model/api/response/reservation-group-type";
import {SimpleReservation} from "../../model/api/response/simple-reservation";
import {DateFormatter} from "../../service/util/date-formatter";
import {PromoMessage} from "../../model/api/response/promo-message";
import {PromoMessageUtil} from "../../service/promo/promo-message-util";
import {PromoMessageType} from "../../model/api/response/promo-message-type";
import {NavigationEnd} from "@angular/router";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.screen.html',
    styleUrls: ['./profile.screen.scss']
})
export class ProfileScreen extends EtoComponent implements OnInit {
    personEditMode: boolean;
    loadingProfile: boolean;
    loadingVehicle: boolean;
    loadingReservations: boolean;

    profile: UserProfile;
    vehicle: Vehicle;
    reservationGroups: SimpleReservationGroup[];

    showVehicleModal: boolean;

    showReservationsOnly: boolean;
    loadingMessages: boolean;
    promoMessages: PromoMessage[];


    override ngOnInit() {
        super.ngOnInit();
        this.showReservationsOnly = this.router.url.indexOf(Constants.Route.reservations) != -1;
        if (!this.showReservationsOnly) {
            this.loadUserProfile();
            this.loadVehicle();
        }
        this.loadReservations();
        this.loadPromoMessages();
        this.router.events.subscribe(value => {
            if (value instanceof NavigationEnd){
                let navigationEvent = value as NavigationEnd;
                if (navigationEvent.url.includes(Constants.Route.you)){
                    let currentShowReservationsOnly = this.router.url.indexOf(Constants.Route.reservations) != -1;
                    if (currentShowReservationsOnly != this.showReservationsOnly){
                        this.ngOnInit();
                    }
                }
            }
        })
    }

    private loadVehicle() {
        this.loadingVehicle = true;
        this.apiService.getVehicles().execute(this.destroyed$, data => {
            this.loadingVehicle = false;
            if (data.length > 0) {
                this.vehicle = data[0];
            }
            else{
                this.vehicle = JSON.parse(localStorage.getItem(Constants.Keys.localVehicle))
            }
        }, apiError => {
            this.loadingVehicle = false;
            this.showGenericError();
        })

    }

    private loadUserProfile() {
        this.loadingProfile = true;
        this.apiService.getProfile().execute(this.destroyed$, data => {
            this.loadingProfile = false;
            this.profile = data;
        }, apiError => {
            this.loadingProfile = false;
            this.showGenericError();
        })
    }

    private loadPromoMessages() {
        let installDate = DateFormatter.fromAPIFormat(localStorage.getItem(Constants.Keys.installDate));
        if (!!installDate) {
            installDate = new Date();
            localStorage.setItem(Constants.Keys.installDate, DateFormatter.toApiDateFormat(installDate))
        }
        this.loadingMessages = true;
        this.apiService.getPromoMessages(DateFormatter.toApiDateTimeFormat(new Date()), null).execute(this.destroyed$, data => {
            this.promoMessages = PromoMessageUtil.marketingMessagesForProfileSection(data);
            this.loadingMessages = false;
        }, apiError => {
            this.showGenericError();
            this.loadingMessages = false;
        })
    }

    getUserName() {
        return !!this.profile ? this.profile.name : EtoTranslation.getInstant('your_name');
    }

    getPhoneNumber() {
        return !!this.profile ? `+${this.profile.phoneNumber}` : EtoTranslation.getInstant('0900 123 456');
    }

    getVehicleName() {
        return !!this.vehicle ? this.vehicle.license : EtoTranslation.getInstant('your_vehicle');
    }

    getCategoryName(item: VehicleCategory) {
        return EtoTranslation.getName(item.category);
    }

    getCategoryValue(item: VehicleCategory) {
        return EtoTranslation.getName(item.items[0]);
    }

    getVehicleDescription() {
        return !!this.vehicle ? this.vehicle.description : EtoTranslation.getInstant('license');
    }

    onEditProfileClicked() {
        this.personEditMode = true;
    }

    onEditProfileDone() {
        this.personEditMode = false;
        if (!this.profile.name) {
            this.loadUserProfile();
            return;
        }
        this.loadingProfile = true;
        let userUpdate = new UserUpdate();
        userUpdate.id = this.profile.id;
        userUpdate.name = this.profile.name;
        this.apiService.updateProfile(userUpdate).execute(this.destroyed$, data => {
            this.loadingProfile = false;
        }, apiError => {
            this.loadingProfile = false;
            this.showGenericError();
            this.loadUserProfile();
        })
    }

    showVehicleDialog() {
        this.showVehicleModal = true;
    }

    onVehicleUpdated(vehicle: Vehicle) {
        this.recaptchaService.execute('profile_vehicle_update').subscribe(value => {})
        if (!vehicle.id) {
            this.createVehicle(vehicle);
        } else {
            this.updateVehicle(vehicle);
        }
    }

    private updateVehicle(vehicle: Vehicle) {
        let vehicleUpdate = new VehicleUpdate();
        vehicleUpdate.id = vehicle.id;
        vehicleUpdate.description = vehicle.description;
        vehicleUpdate.license = vehicle.license;
        vehicleUpdate.itemTemplatedIds = vehicle.categories.flatMap(value => value.items[0].id);
        this.loadingVehicle = true;
        this.apiService.updateVehicle(vehicleUpdate).execute(this.destroyed$, data => {
            this.vehicle = vehicle;
            this.loadingVehicle = false;
        }, apiError => {
            this.showGenericError();
            this.loadingVehicle = false;
            this.loadVehicle();
        })
    }

    private createVehicle(vehicle: Vehicle) {
        let vehicleCreate = new VehicleCreate();
        vehicleCreate.description = vehicle.description;
        vehicleCreate.license = vehicle.license;
        vehicleCreate.itemTemplatedIds = vehicle.categories.flatMap(value => value.items[0].id);
        this.loadingVehicle = true;
        this.apiService.createVehicle(vehicleCreate).execute(this.destroyed$, data => {
            this.loadVehicle();
            this.loadingVehicle = false;
        }, apiError => {
            this.showGenericError();
            this.loadingVehicle = false;
            this.loadVehicle();
        })
    }

    private removeLocalVehicle(vehicle: Vehicle) {
        localStorage.removeItem(Constants.Keys.localVehicle);
    }

    private loadReservations() {
        this.loadingReservations = true;
        this.apiService.getReservations().execute(this.destroyed$, data => {
            this.loadingReservations = false;
            this.reservationGroups = data.flatMap(value => value.reservations).length == 0 ? [] : data;
        }, apiError => {
            this.loadingReservations = false;
            this.showGenericError();
        })
    }

    getNameForReservationGroup(group: SimpleReservationGroup) {
        return EtoTranslation.getInstant(group.groupType == ReservationGroupType.ACTIVE ? 'active_reservations' : 'finished_reservations')
    }

    onReservationSelected(reservation: SimpleReservation) {
        this.router.navigate([Constants.Route.reservation, reservation.id.toString()])
    }

    onEmptyStateButtonClicked() {
        this.router.navigate([Constants.Route.home]);
    }

    onDeleteProfileClicked() {
        this.confirmationService.confirm({
            message: EtoTranslation.getInstant('delete_profile_prompt'),
            accept: () => {
                this.loadingProfile = true;
                this.apiService.deleteProfile().execute(this.destroyed$, data => {
                    this.loadingProfile = false;
                    this.apiService.logout();
                    this.router.navigate([Constants.Route.home]);
                }, apiError => {
                    this.loadingProfile = false;
                    this.showGenericError();
                })
            }
        });
    }

    shouldDisplayAddVehicle() {
        return !this.vehicle || !this.vehicle.id;

    }

    onPromoMessageClicked(promoMessage: PromoMessage) {
        switch (promoMessage.type) {
            case PromoMessageType.FUNCTIONAL_NO_VEHICLE_ON_PROFILE:
                this.showVehicleScreen();
                break;
            case PromoMessageType.MARKETING_NEW_REGION:
            case PromoMessageType.MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE:
            case PromoMessageType.MARKETING_GENERIC:
            case PromoMessageType.VOUCHER_PENDING:
            case PromoMessageType.VOUCHER_READY:
            case PromoMessageType.VOUCHER_PROMO:
                window.location.href = promoMessage.args[0];
                break;
            case PromoMessageType.MARKETING_FIRST_LOGIN:
            case PromoMessageType.MARKETING_SEASONAL_AUTUMN:
            case PromoMessageType.MARKETING_SEASONAL_SPRING:
                this.goToDashBoard();
                break;
        }
    }

    private showVehicleScreen() {
        this.showVehicleModal = true;
    }

    private goToDashBoard() {
        this.router.navigate([Constants.Route.home]);
    }
}
