import {trigger, transition, style, animate, query, stagger, state, AUTO_STYLE} from '@angular/animations';

const DEFAULT_DURATION = 300;

export const shakeAnimation = trigger('shakeAnimation', [
    transition(':enter', [
        style({ transform: "translateX(4px)" }), animate('100ms'),
        style({ transform: "translateX(-4px)" }), animate('100ms'),
        style({ transform: "translateX(4px)" }), animate('100ms'),
        style({ transform: "translateX(-4px)" }), animate('100ms')]
    ),
]);

export const revealAnimation = trigger('revealAnimation', [
    transition(":enter", [
        transition(':enter', [style({
            opacity: 0,
            transform: 'translateY(-100%)'
        }),
            animate(4000)
        ])
    ])
]);

export const test = trigger('revealAnimation',  [
    state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
    state('true', style({ height: '0', visibility: 'hidden' })),
    transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
    transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
]);

export const listAnimation = trigger('listAnimation', [
    transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
            { optional: true }
        ),
        query(':leave',
            animate('300ms', style({ opacity: 0 })),
            { optional: true}
        )
    ])
]);
