import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import * as moment from "moment";
import {LocaleSettings} from "primeng/calendar";
import {EtoTranslation} from "../../service/translation/eto-translation";

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarModalComponent extends EtoComponent{
  @Input()
  display: boolean;
  @Output()
  displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  date: Date = moment().toDate();
  @Output()
  onDateSelected:EventEmitter<Date> = new EventEmitter<Date>();

  onSelected(date: Date) {
    this.display = false;
    this.displayChange.emit(this.display);
    this.onDateSelected.emit(date);
  }

  getFirstDayIndex() {
    return EtoTranslation.getSelectedLang() == 'en' ? 0 : 1;
  }
}
