import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {Site} from "../../../model/api/response/site";

@Component({
  selector: 'app-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss']
})
export class SitesListComponent extends EtoComponent{
  @Input()
  sites: Site[];
  @Input()
  title: string;
  @Output()
  onSiteClicked: EventEmitter<Site> = new EventEmitter<Site>();

  constructor(injector: Injector) {
    super(injector);
  }

}
