import {Component} from '@angular/core';
import {VacanciesComponent} from "../../vacancies-component";
import {takeUntil} from "rxjs/operators";
import {MenuItem} from "primeng/api";
import {Filter} from "../../../model/ui/filter";
import {FilterType} from "../../../service/filter/filter-type";
import {Constants} from "../../../constants/constants";
import {FilterResult} from "../../../service/filter/filter-result";
import {MainVacancyResult} from "../../../model/api/response/main-vacancy-result";
import {FilterDateType} from "../../../model/ui/filter-date-type";

@Component({
  selector: 'app-filter-widget',
  templateUrl: './filter-widget.component.html',
  styleUrls: ['./filter-widget.component.scss']
})
export class FilterWidgetComponent extends VacanciesComponent{
  filterMenuItems: MenuItem[];
  private filterQueryParam: string;


  override ngOnInit() {
    super.ngOnInit();
    this.prepareFilter()
  }

  private prepareFilter() {
    this.filterService.getFilterResultObservable().pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.processFilterResult(value);
    })
    this.initFilter();
  }


  protected override bindMenuItems(items: MenuItem[]) {
    super.bindMenuItems(items);
    this.filterMenuItems = this.filterFilterItems(items);
  }

  private filterFilterItems(menuItems: MenuItem[]) {
    return menuItems.filter(value => {
      if (value.automationId == FilterType.START_DATE){
        //exclude EXACT DATE option
        value.items = value.items.filter(dateValue => {
          return dateValue.id != Object.values(FilterDateType).indexOf(FilterDateType.EXACT_DATE).toString()
        })
      }
      if (this.isHandsetPortrait){
        return [FilterType.JOB].indexOf(value.automationId) != -1;
      }
      return [FilterType.JOB, FilterType.START_DATE].indexOf(value.automationId) != -1;
    });
  }

  onButtonClicked() {
    let filterParam = `${Constants.Route.home}?${Constants.QueryParam.filter}=${this.filterQueryParam}`;
    window.open(filterParam, "_BLANK");
  }


  protected override processFilterResult(result: FilterResult) {
    super.processFilterResult(result);
    this.filterQueryParam = result.queryParam;
  }


  protected override loadVacancies(filter: Filter, previousResult: MainVacancyResult) {
    //we don't need to load vacancies in widget
  }
}
