import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PromoMessage} from "../../../model/api/response/promo-message";
import {EtoComponent} from "../../eto-component";
import {EtoTranslation} from "../../../service/translation/eto-translation";
import {PromoMessageUtil} from "../../../service/promo/promo-message-util";

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent extends EtoComponent{

  @Input()
  message: PromoMessage;
  @Output()
  onMessageClicked: EventEmitter<PromoMessage> = new EventEmitter<PromoMessage>();

  getTitle() {
    return PromoMessageUtil.getTitle(this.message);
  }

  getText() {
    return PromoMessageUtil.getText(this.message);
  }
}
