<p-table responsiveLayout="stack" [value]="items" [columns]="getHeaders()" selectionMode="single">
<ng-template pTemplate="header" let-columns>
    <tr>
        <th *ngFor="let column of columns">{{column}}</th>
    </tr>
</ng-template>
<ng-template pTemplate="body" let-item let-columns="columns">
    <tr style="cursor: pointer" (click)="onRowClicked(item)">
        <td><span class="p-column-title">{{columns[0]}}</span><h4 style="margin:0">{{item.carServiceName}}</h4></td>
        <td><span class="p-column-title">{{columns[1]}}</span><p>{{item.jobName}}</p></td>
        <td><span class="p-column-title">{{columns[2]}}</span><p>{{getReservationDateAndTime(item)}}</p></td>
        <td><span class="p-column-title">{{columns[3]}}</span><span [class]="'state-' + item.state.toString()">{{getStateText(item)}}</span></td>
        <td><span class="p-column-title">{{columns[4]}}</span><h4 style="margin:0">{{getPrice(item)}}</h4></td>
    </tr>
</ng-template>
</p-table>
