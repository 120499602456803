import {AbstractFilterLoader} from "./abstract-filter-loader";
import {TimeWindow} from "../../../model/api/request/time-window";
import {FilterResult} from "../filter-result";
import {MenuItem} from "primeng/api";
import {FilterType} from "../filter-type";
import {FilterMenuProvider} from "../../menu/filter/filter-menu-provider";

export class TimeFilterLoader extends AbstractFilterLoader<TimeWindow[]>{

    protected convertDataToMenuItems(data: TimeWindow[], filterResult: FilterResult): MenuItem {
        let items = FilterMenuProvider.getTimeMenu(data, time => {
            this.onTimeSelected(time, true);
        });
        this.result = {
            automationId: FilterType.TIME_WINDOW,
            icon: 'time-icon',
            items: items
        };
        let selectedTime = this.filterResult.filter.selectedTime;
        this.onTimeSelected(!!selectedTime ? selectedTime : TimeWindow.ALL_DAY, false);
        return this.result;
    }

    protected getDataFromApi(onSuccess: (data: TimeWindow[]) => void): void {
        this.updateProgress(false);
        onSuccess(Object.values(TimeWindow));
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [];
    }

    private onTimeSelected(time: TimeWindow, manual: boolean) {
        this.result.items.forEach(value => value.disabled = false);
        this.result.label = FilterMenuProvider.getTimeLabel(time);
        this.filterResult.filter.selectedTime = time;
        if (manual) {
            this.onItemSelectedManually();
        } else {
            this.onItemSelected();
        }
    }
}
