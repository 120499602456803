import {VacancyCard, VacancyItemType} from "./vacancy-card";
import {Vacancy} from "../../api/response/vacancy";

export class SingleVacancyCard extends VacancyCard{
    vacancy: Vacancy;

    constructor() {
        super(VacancyItemType.SINGLE_CARD);
    }
}
