export enum PromoMessageType{
    MARKETING_FIRST_LOGIN = 'MARKETING_FIRST_LOGIN',
    MARKETING_NEW_REGION = 'MARKETING_NEW_REGION',
    MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE = 'MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE',
    MARKETING_SEASONAL_SPRING = 'MARKETING_SEASONAL_SPRING',
    MARKETING_SEASONAL_AUTUMN = 'MARKETING_SEASONAL_AUTUMN',
    FUNCTIONAL_NO_VEHICLE_ON_PROFILE = 'FUNCTIONAL_NO_VEHICLE_ON_PROFILE',
    FUNCTIONAL_NO_USER_PROFILE = 'FUNCTIONAL_NO_USER_PROFILE',
    RESERVATION_UPCOMING = 'RESERVATION_UPCOMING',
    VOUCHER_PROMO = 'VOUCHER_PROMO',
    VOUCHER_PENDING = 'VOUCHER_PENDING',
    VOUCHER_READY = 'VOUCHER_READY',
    MARKETING_GENERIC = 'MARKETING_GENERIC'
}
