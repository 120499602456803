import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PhoneInputComponent extends EtoComponent {
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required])
    });

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Slovakia, CountryISO.CzechRepublic, CountryISO.Poland, CountryISO.Hungary, CountryISO.Austria, CountryISO.Ukraine];
    @Input()
    value: string;
    @Output()
    onValidPhoneProvided: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    onPhoneInvalidated: EventEmitter<string> = new EventEmitter<string>();

    onPhoneChange(value: any) {
        if (this.phoneForm.invalid){
            this.onPhoneInvalidated.emit(null);
        }
        else{
            this.onValidPhoneProvided.emit(value.internationalNumber.replace(/\s/g,''));
        }
    }
}
