import {ApiRequest} from "./api-request-builder";
import {Observable} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

export class ApiRequestExecutor<T> {
    private apiRequest: ApiRequest<T>;


    constructor(apiRequest: ApiRequest<T>) {
        this.apiRequest = apiRequest;
    }


    public executeWithSameCallback(notifier: Observable<any>, onSuccessOrOnError: () => void) {
        this.executeRequest(notifier, onSuccessOrOnError, onSuccessOrOnError);
    }

    public execute(notifier: Observable<any>, onSuccess: (data: T) => void, onError: (apiError: HttpErrorResponse) => void) {
        this.executeRequest(notifier, onSuccess, onError);
    }

    private executeRequest(notifier: Observable<any>, onSuccess: (data: T) => void, onError: (apiError: HttpErrorResponse) => void) {
        this.apiRequest.observableRequest
            .pipe(takeUntil(notifier))
            .subscribe(
                data => {
                    onSuccess(data);
                },
                error => {
                    onError(error);
                });
    }
}
