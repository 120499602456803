import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeScreen} from './screens/home/home.screen';
import {SectionsModule} from "./sections/sections-module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HeadersInterceptor} from "./service/api/interceptors/headers-interceptor.service";
import {TokenInterceptor} from "./service/api/interceptors/token-interceptor";
import {ApiService} from "./service/api/api.service";
import {TranslateService} from "@ngx-translate/core";
import {EtoTranslation} from "./service/translation/eto-translation";
import {LOCATION_INITIALIZED} from "@angular/common";
import {TranslationModule} from "./service/translation/translation-module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ConfirmationService, MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {ComponentsModule} from "./components/components-module";
import {TabViewModule} from "primeng/tabview";
import {MenubarModule} from "primeng/menubar";
import {CarouselModule} from "primeng/carousel";
import {FilterService} from "./service/filter/filter-service";
import {TooltipModule} from "primeng/tooltip";
import {SidebarModule} from "primeng/sidebar";
import { ProfileScreen } from './screens/profile/profile.screen';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { ReservationScreen } from './screens/reservation/reservation.screen';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { SiteScreen } from './screens/site/site.screen';
import {ImageModule} from "primeng/image";
import { ConfirmationScreen } from './screens/confirmation/confirmation.screen';
import {AuthGuard} from "./service/util/auth-guard";
import {GMapModule} from "primeng/gmap";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireAnalyticsModule} from "@angular/fire/compat/analytics";
import {environment} from "../environments/environment";
import {AnalyticsService} from "./service/analytics/analytics-service";
import {PixelModule} from "ngx-pixel";
import {PromoMessageService} from "./service/promo/promo-message-service";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";

@NgModule({
    declarations: [
        AppComponent,
        HomeScreen,
        ProfileScreen,
        ReservationScreen,
        SiteScreen,
        ConfirmationScreen
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SectionsModule,
        TranslationModule,
        BrowserAnimationsModule,
        ToastModule,
        TooltipModule,
        ComponentsModule,
        TabViewModule,
        MenubarModule,
        CarouselModule,
        SidebarModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        ImageModule,
        GMapModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        RecaptchaV3Module,
        PixelModule.forRoot({ enabled: true, pixelId: '1084417232278841'})

    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true},
        ApiService,
        MessageService,
        FilterService,
        AnalyticsService,
        ConfirmationService,
        AuthGuard,
        PromoMessageService,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            EtoTranslation.createInstance(translate).subscribe(() => {
                console.info(`Successfully initialized '${EtoTranslation.getSelectedLang()}' language.'`);
            }, err => {
                console.error((`Problem with '${EtoTranslation.getSelectedLang()}' language initialization.'`));
            }, () => {
                resolve(null);
            });
        });
    });
}
