import {Component, Input, OnInit} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {ChecklistItem} from "../../../model/api/response/checklist-item";

@Component({
  selector: 'app-checklist-list',
  templateUrl: './checklist-list.component.html',
  styleUrls: ['./checklist-list.component.scss']
})
export class ChecklistListComponent extends EtoComponent{

  @Input()
  items: ChecklistItem[];

}
