import {Vehicle} from "../../../model/api/response/vehicle";
import {VehicleCategory} from "../../../model/api/response/vehicle-category";
import {VehicleOptionMenuItem} from "../../../model/ui/vehicle-option-menu-item";
import {VehicleCategoryMenuItem} from "../../../model/ui/vehicle-category-menu-item";
import {EtoTranslation} from "../../translation/eto-translation";

export class VehicleOptionsMenuProvider{

    public static getMenu(vehicle: Vehicle, options: VehicleCategory[]): VehicleCategoryMenuItem[]{
        const result: VehicleCategoryMenuItem[] = []
        const selections: Map<number, number> = new Map<number, number>();
        if (!!vehicle){
            vehicle.categories.forEach(category => {
                selections.set(category.category.id, category.items[0].id);
            })
        }
        options.forEach(category => {
            let vehicleCategoryMenuItem = new VehicleCategoryMenuItem();
            vehicleCategoryMenuItem.label = EtoTranslation.getName(category.category);
            vehicleCategoryMenuItem.value = category;
            vehicleCategoryMenuItem.tooltip = EtoTranslation.getSubtitle(category.category);
            vehicleCategoryMenuItem.options = [];
            let index = 0;
            category.items.forEach(option => {
                let vehicleOptionMenuItem = new VehicleOptionMenuItem();
                vehicleOptionMenuItem.value = option;
                vehicleOptionMenuItem.id = option.id.toString();
                vehicleOptionMenuItem.label = EtoTranslation.getName(option);
                vehicleOptionMenuItem.selected = selections.has(category.category.id)
                    ? selections.get(category.category.id) == option.id
                    : index == 0;
                index++;
                let subtitle = EtoTranslation.getSubtitle(option);
                if (!!subtitle){
                    vehicleOptionMenuItem.tooltip = subtitle;
                }
                vehicleCategoryMenuItem.options.push(vehicleOptionMenuItem);

            })
            vehicleCategoryMenuItem.selected = vehicleCategoryMenuItem.options.filter(value => value.selected)[0];
            result.push(vehicleCategoryMenuItem);
        })
        return result;
    }
}
