import {Component, Injector, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {PrimeNGConfig} from "primeng/api";
import {EtoTranslation} from "./service/translation/eto-translation";
import {NgxSmartBannerPlatform, NgxSmartBannerService} from "@netcreaties/ngx-smart-banner";
import {NavigationEnd, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {EtoComponent} from "./components/eto-component";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends EtoComponent{
  hideToolbar: boolean = false;


  constructor(injector: Injector) {
    super(injector);
    this.ngxSmartBannerService.initialize({
      viewContainerRef: this.viewContainerRef,
      enabledPlatforms: [NgxSmartBannerPlatform.Android, NgxSmartBannerPlatform.IOS],
      title: EtoTranslation.getInstant('app_name'),
      author: 'Ernest.to s.r.o',
      hideRating: true,
      buttonUrl: {
        android: 'https://play.google.com/store/apps/details?id=sk.ernestto.portal',
        ios: 'https://itunes.apple.com/app/id1641186217?mt=8'
      },
      priceSuffix: {
        ios: EtoTranslation.getInstant('download_from_app_store'),
        android: EtoTranslation.getInstant('download_from_play_store'),
      },
      price: null,
      icon: {
        ios: '../assets/images/store_icon.png',
        android: '../assets/images/store_icon.png'
      },
      buttonLabel: EtoTranslation.getInstant('view'),
      daysHidden: 0,
      daysReminder: 0,
    });
  }


  override ngOnInit() {
    super.ngOnInit();
    this.primengConfig.ripple = true;
    EtoTranslation.registerPrimengLocalization(this.primengConfig);
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideToolbar = this.route.firstChild.snapshot.data['hideToolbar'];
      }
    });
  }
}
