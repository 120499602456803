import {EtoComponent} from "./eto-component";
import {Component, HostListener, Injector} from "@angular/core";
import {MenuItem} from "primeng/api";
import {FilterService} from "../service/filter/filter-service";
import {MainVacancyResult} from "../model/api/response/main-vacancy-result";
import {VacancyResult} from "../model/api/response/vacancy-result";
import {Vehicle} from "../model/api/response/vehicle";
import {Constants} from "../constants/constants";
import {FilterResult} from "../service/filter/filter-result";
import {FilterResultType} from "../service/filter/filter-result-type";
import {Filter} from "../model/ui/filter";
import {KeyValue} from "@angular/common";
import {FilterType} from "../service/filter/filter-type";
import {HttpErrorResponse} from "@angular/common/http";
import {EtoTranslation} from "../service/translation/eto-translation";
import {VacancyUtil} from "../service/util/vacancy-util";
import {FilterDateType} from "../model/ui/filter-date-type";
import {Vacancy} from "../model/api/response/vacancy";
import {VacancySelection} from "../model/ui/vacancy-selection";
import {VehicleUpdate} from "../model/api/request/vehicle-update";
import {VehicleCreate} from "../model/api/request/vehicle-create";
import {VacanciesDataProcessor} from "../service/vacancies/vacancies-data-processor";
import {VacancySection} from "../model/ui/vacancy/vacancy-section";
import {SingleVacancyCard} from "../model/ui/vacancy/single-vacancy-card";
import {MultiVacancyCard} from "../model/ui/vacancy/multi-vacancy-card";
import {GroupVacancyCard} from "../model/ui/vacancy/group-vacancy-card";
import {PromoMessage} from "../model/api/response/promo-message";

@Component({
    template: '',
})

export class VacanciesComponent extends EtoComponent {

    filterMenu: MenuItem[];
    vehicleModalDisplayed: boolean = false;

    filterService: FilterService
    mainVacancyResult: MainVacancyResult = new MainVacancyResult();
    vacancySections: VacancySection[] = [];
    groupSections: VacancySection[];
    groupVacanciesResultVisible: boolean;
    vacancyPreviewVisible: boolean;
    vacancyPreview: VacancySelection;
    vehicle: Vehicle;

    loadingFilter: boolean;

    loadingAllVacancies: boolean = true;

    calendarDisplayed: boolean = false;

    dataProcessor: VacanciesDataProcessor;


    constructor(injector: Injector) {
        super(injector);
        this.filterService = injector.get(FilterService);
    }

    protected initFilter() {
        let filterQueryParam = this.route.snapshot.queryParamMap.get(Constants.QueryParam.filter);
        this.filterService.loadFilter(filterQueryParam, this.destroyed$);
    }

    protected processFilterResult(result: FilterResult) {
        this.updateURLWithNewParamsWithoutReloading(Constants.QueryParam.filter, result.queryParam);
        this.bindProgress(result.progress);
        if (!!result.menuItems) {
            this.bindMenuItems(result.menuItems);
        }
        if (!!result.error) {
            this.bindError(result.error);
        }
        if (result.type == FilterResultType.LOAD_VACANCIES) {
            this.loadVacancies(this.getProcessedFilter(result.filter), null);
        }

        this.overrideDateSelection(result.menuItems);
        this.overrideVehicleSelection(result.menuItems);
    }


    protected getProcessedFilter(result: Filter) {
        return result;
    }

    protected bindProgress(progress: KeyValue<FilterType, boolean>[]) {
        for (const progressItem of progress) {
            this.loadingFilter = progressItem.value;
            this.updateProgressForFilerItems();
        }
    }

    private updateProgressForFilerItems() {
        if (!!this.filterMenu) {
            for (const filterMenuItem of this.filterMenu) {
                switch (filterMenuItem.automationId) {
                    case FilterType.REGION:
                    case FilterType.SITE_TYPE:
                        break;
                    default:
                        filterMenuItem.disabled = this.loadingFilter;
                }
            }
        }
    }

    protected bindMenuItems(items: MenuItem[]) {
        // to be overriden
    }

    private bindError(error: HttpErrorResponse) {
        if (error.status == 0) {
            this.messageService.add(
                {
                    severity: 'error',
                    summary: EtoTranslation.getInstant('oops'),
                    detail: EtoTranslation.getInstant('user_location_not_found')
                });
        } else {
            this.showGenericError();
        }
    }

    protected loadVacancies(filter: Filter, previousResult: MainVacancyResult) {
        if (!filter.selectedJobTemplateId) {
            this.postProcessVacancies(null, new MainVacancyResult());
            return;
        }
        this.loadingFilter = true;
        this.loadingAllVacancies = !previousResult;
        const seekFrom = !!previousResult ? previousResult.endDate : filter.selectedDay;
        this.apiService.getVacancies(filter, seekFrom).execute(this.destroyed$, data => {
            this.loadingAllVacancies = false;
            this.loadingFilter = false;
            if (!data.items || data.items.length == 0) {
                this.postProcessVacancies(null, new MainVacancyResult());
                return;
            }
            this.mainVacancyResult = this.postProcessVacancies(previousResult, data);
        }, apiError => {
            this.loadingAllVacancies = false;
            this.loadingFilter = false;
            this.showGenericError();
        })
    }


    private overrideDateSelection(menuItems: MenuItem[]) {
        if (!menuItems) {
            return;
        }
        for (const menuItem of Array.from(menuItems)) {
            if (menuItem.automationId == FilterType.START_DATE) {
                for (const item of Array.from(menuItem.items)) {
                    if (item.id == Object.values(FilterDateType).indexOf(FilterDateType.EXACT_DATE).toString()) {
                        item.command = () => {
                            this.openDatePicker();
                        }
                    }
                }
            }
        }
    }

    private overrideVehicleSelection(menuItems: MenuItem[]) {
        if (!menuItems) {
            return;
        }
        for (const menuItem of Array.from(menuItems)) {
            if (menuItem.automationId == FilterType.VEHICLE) {
                for (const item of Array.from(menuItem.items)) {
                    item.command = () => {
                        this.vehicleModalDisplayed = true;
                    }
                }
            }
        }
    }

    private openDatePicker() {
        this.calendarDisplayed = true;
    }

    loadMoreVacancies() {
        if (this.loadingFilter || this.loadingAllVacancies || !this.mainVacancyResult.items) {
            return;
        }
        let processedFilter = this.getProcessedFilter(this.filterService.getFilter());
        if (!processedFilter.selectedRegionId && !processedFilter.selectedSiteId) {
            return;
        }
        this.loadVacancies(processedFilter, this.mainVacancyResult)
    }

    private postProcessVacancies(previousResult: MainVacancyResult, newResult: MainVacancyResult) {
        const result = !previousResult ? newResult : VacancyUtil.mergeVacancies(previousResult, newResult);
        if (!previousResult){
            let filter = this.getProcessedFilter(this.filterService.getFilter());
            let selectedDay = filter.selectedDay;
            let selectedSiteId = filter.selectedSiteId;
            this.dataProcessor = new VacanciesDataProcessor(selectedDay, !selectedSiteId, this.getPromoMessages());
        }
        this.vacancySections = this.dataProcessor.vacanciesToCards(result);
        return result;
    }

    protected getPromoMessages(): PromoMessage[] {
        return [];
    }

    onSingleCarClicked(card: SingleVacancyCard){
        this.recaptchaService.execute('vacancy_single_click').subscribe(value => {})
        this.groupVacanciesResultVisible = false;
        this.vacancyPreviewVisible = true;
        let filter = this.filterService.getFilter();
        let vacancySelection = new VacancySelection();
        vacancySelection.vacancy = card.vacancy;
        vacancySelection.jobTemplateId = filter.selectedJobTemplateId;
        vacancySelection.jobTemplateName = filter.selectedJobName;
        vacancySelection.siteType = filter.selectedType;
        this.vacancyPreview = vacancySelection;
    }


    onMultiCardClicked(card: MultiVacancyCard) {
        this.recaptchaService.execute('vacancy_multi_click').subscribe(value => {})
        this.groupSections = this.dataProcessor.toSimpleCards(card);
        this.groupVacanciesResultVisible = true;
    }

    onGroupCardClicked(card: GroupVacancyCard) {
        this.recaptchaService.execute('vacancy_group_click').subscribe(value => {})
        this.groupSections = this.dataProcessor.toSimpleAndMultiCards(card);
        this.groupVacanciesResultVisible = true;
    }

    onVacancySelected(vacancySelection: VacancySelection) {
        this.recaptchaService.execute('vacancy_single_select').subscribe(value => {})
        this.groupVacanciesResultVisible = false;
        this.groupSections = null;
        const key: string = Constants.QueryParam.vacancy;
        const vacancyParam = btoa(unescape(encodeURIComponent(JSON.stringify(vacancySelection))));
        this.logClickEvent(Constants.Analytics.clickEventSelectedVacancy);
        this.router.navigate([Constants.Route.confirmation], {queryParams: {[key]: vacancyParam}});
    }

    onVehicleUpdated(vehicle: Vehicle) {
        this.recaptchaService.execute('vacancies_vehicle_update').subscribe(value => {})
        if (this.apiService.isUserLoggedIn() && !!vehicle.license){
            if (!!vehicle.id){
                this.updateVehicle(vehicle);
            }
            {
                this.createVehicle(vehicle);
            }
        }
        else{
            localStorage.setItem(Constants.Keys.localVehicle, JSON.stringify(vehicle));
            this.filterService.vehicleChanged(this.destroyed$);
        }
    }

    private updateVehicle(vehicle: Vehicle) {
        const vehicleUpdate = new VehicleUpdate();
        vehicleUpdate.id = vehicle.id;
        vehicleUpdate.license = vehicle.license;
        vehicleUpdate.description = vehicle.description;
        vehicleUpdate.itemTemplatedIds = vehicle.categories.flatMap(value => value.items[0].id);
        this.loadingFilter = true;
        this.apiService.updateVehicle(vehicleUpdate).execute(this.destroyed$, data => {
            this.loadingFilter = false;
            this.filterService.vehicleChanged(this.destroyed$);
        }, apiError => {
            this.loadingFilter = false;
            this.showGenericError()
        })
    }

    private createVehicle(vehicle: Vehicle) {
        const vehicleCreate = new VehicleCreate();
        vehicleCreate.license = vehicle.license;
        vehicleCreate.description = vehicle.description;
        vehicleCreate.itemTemplatedIds = vehicle.categories.flatMap(value => value.items[0].id);
        this.loadingFilter = true;
        this.apiService.createVehicle(vehicleCreate).execute(this.destroyed$, data => {
            this.loadingFilter = false;
            this.filterService.vehicleChanged(this.destroyed$);
        }, apiError => {
            this.loadingFilter = false;
            this.showGenericError()
        })
    }

    protected loadVehicle() {
        if (this.apiService.isUserLoggedIn()) {
            this.apiService.getVehicles().execute(this.destroyed$, data => {
                if (data.length == 0) {
                    this.vehicle = JSON.parse(localStorage.getItem(Constants.Keys.localVehicle));
                } else {
                    this.vehicle = data[0];
                }
            }, apiError => {
                this.showGenericError();
            })
        } else {
            this.vehicle = JSON.parse(localStorage.getItem(Constants.Keys.localVehicle));
        }
    }

    onDateSelected(date: Date) {
        this.filterService.dateSelected(date, this.destroyed$);
    }

    isVehicleCreated() {
        let filter = this.filterService.getFilter();
        return !!filter.selectedCategoryItemsIds && filter.selectedCategoryItemsIds.length > 0;
    }
}
