import {PromoMessage} from "../../model/api/response/promo-message";
import {PromoMessageType} from "../../model/api/response/promo-message-type";
import {EtoTranslation} from "../translation/eto-translation";
import {LabelUtil} from "../util/label-util";
import {DateFormatter} from "../util/date-formatter";
import {PromoText} from "../../model/api/response/promo-text";
import {PromoGenericMessageType} from "../../model/api/response/promo-generic-message-type";

export class PromoMessageUtil{

    public static getTitle(item: PromoMessage) {
        switch (item.type) {
            case PromoMessageType.RESERVATION_UPCOMING:
                return EtoTranslation.getInstant('promo_reservation_title');
            case PromoMessageType.FUNCTIONAL_NO_USER_PROFILE:
                return EtoTranslation.getInstant('promo_no_profile_title');
            case PromoMessageType.FUNCTIONAL_NO_VEHICLE_ON_PROFILE:
                return EtoTranslation.getInstant('promo_vehicle_on_profile_title');
            case PromoMessageType.MARKETING_FIRST_LOGIN:
                return EtoTranslation.getInstant('promo_first_login_title');
            case PromoMessageType.MARKETING_NEW_REGION:
                return EtoTranslation.getInstant('promo_new_region_presov_title');
            case PromoMessageType.MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE:
                return EtoTranslation.getInstant('promo_post_tire_change_title');
            case PromoMessageType.MARKETING_SEASONAL_AUTUMN:
                return EtoTranslation.getInstant('promo_season_autumn_title');
            case PromoMessageType.MARKETING_SEASONAL_SPRING:
                return EtoTranslation.getInstant('promo_season_spring_title');
            case PromoMessageType.VOUCHER_PROMO:
                return EtoTranslation.getInstant('promo_voucher_promo_title');
            case PromoMessageType.VOUCHER_READY:
                return EtoTranslation.getInstant('promo_voucher_ready_title');
            case PromoMessageType.VOUCHER_PENDING:
                return EtoTranslation.getInstant('promo_voucher_pending_title');
            case PromoMessageType.MARKETING_GENERIC:
                return this.readTextValue(item.title, EtoTranslation.getInstant('empty'));
            default:
                return EtoTranslation.getInstant('empty');
        }
    }

    public static getText(item: PromoMessage) {
        switch (item.type) {
            case PromoMessageType.RESERVATION_UPCOMING:
                const serviceName = item.args[0];
                const license = item.args[1];
                const time = LabelUtil.formatPromoTime(DateFormatter.fromAPIFormat(item.args[2]));
                return EtoTranslation.getInstant('promo_reservation', {site: serviceName, vehicle: license, time: time});
            case PromoMessageType.FUNCTIONAL_NO_USER_PROFILE:
                return EtoTranslation.getInstant('promo_no_profile');
            case PromoMessageType.FUNCTIONAL_NO_VEHICLE_ON_PROFILE:
                return EtoTranslation.getInstant('promo_vehicle_on_profile');
            case PromoMessageType.MARKETING_FIRST_LOGIN:
                return EtoTranslation.getInstant('promo_first_login');
            case PromoMessageType.MARKETING_NEW_REGION:
                return EtoTranslation.getInstant('promo_new_region_presov');
            case PromoMessageType.MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE:
                return EtoTranslation.getInstant('promo_post_tire_change');
            case PromoMessageType.MARKETING_SEASONAL_AUTUMN:
                return EtoTranslation.getInstant('promo_season_autumn');
            case PromoMessageType.MARKETING_SEASONAL_SPRING:
                return EtoTranslation.getInstant('promo_season_spring');
            case PromoMessageType.VOUCHER_PROMO:
                return EtoTranslation.getInstant('promo_voucher_promo');
            case PromoMessageType.VOUCHER_READY:
                return EtoTranslation.getInstant('promo_voucher_ready');
            case PromoMessageType.VOUCHER_PENDING:
                return EtoTranslation.getInstant('promo_voucher_pending');
            case PromoMessageType.MARKETING_GENERIC:
                return this.readTextValue(item.message, EtoTranslation.getInstant('empty'));
            default:
                return EtoTranslation.getInstant('empty');
        }
    }

    private static readTextValue(title: PromoText, defaultValue: string) {
        let selectedLanguage = EtoTranslation.getSelectedLang().toLowerCase();
        if (selectedLanguage.startsWith('sk') && !!title.sk) {
            return title.sk;
        }

        if (selectedLanguage.startsWith('en') && !!title.en) {
            return title.en;
        }
        return defaultValue;
    }

    public static getBackgroundImageUrl(item: PromoMessage) {
        switch (item.type) {
            case PromoMessageType.MARKETING_GENERIC:
                return 'https://res.cloudinary.com/ernest-to/image/upload/v1662300748/Others/Images/tires_xnbghh.png';
            case PromoMessageType.RESERVATION_UPCOMING:
            case PromoMessageType.FUNCTIONAL_NO_USER_PROFILE:
            case PromoMessageType.FUNCTIONAL_NO_VEHICLE_ON_PROFILE:
            case PromoMessageType.MARKETING_FIRST_LOGIN:
            case PromoMessageType.MARKETING_NEW_REGION:
            case PromoMessageType.MARKETING_BLOG_POST_HOW_TO_PEPARE_FOR_TIRE_CHANGE:
            case PromoMessageType.MARKETING_SEASONAL_AUTUMN:
            case PromoMessageType.MARKETING_SEASONAL_SPRING:
            case PromoMessageType.VOUCHER_PROMO:
            case PromoMessageType.VOUCHER_READY:
            case PromoMessageType.VOUCHER_PENDING:
            default:
                return 'https://res.cloudinary.com/ernest-to/image/upload/v1662300748/Others/Images/service_technician_gsf9tx.png';
        }
    }

    public static importantMessageForNotifications(messages: PromoMessage[]):PromoMessage[]{
        return messages.filter(value => {
            if ([PromoMessageType.VOUCHER_READY, PromoMessageType.RESERVATION_UPCOMING].indexOf(value.type)!=-1){
                return true;
            } else if (value.type == PromoMessageType.MARKETING_GENERIC){
                return [
                    PromoGenericMessageType.CAMPAIGN_VOUCHER_GAS_PENDING_OFFLINE_2022_09_01,
                    PromoGenericMessageType.CAMPAIGN_VOUCHER_GAS_PENDING_ONLINE_2022_09_01,
                    PromoGenericMessageType.CAMPAIGN_VOUCHER_GAS_READY_OFFLINE_2022_09_01,
                    PromoGenericMessageType.CAMPAIGN_VOUCHER_GAS_READY_ONLINE_2022_09_01
                ].indexOf((value.id as PromoGenericMessageType)) != -1
            }
            return false;
        }).map(value => {
            if (value.type == PromoMessageType.RESERVATION_UPCOMING){
                value.id = `${value.type}-${value.args[3]}`;
            }
            return value;
        });
    }

    public static marketingMessagesForProfileSection(messages: PromoMessage[]):PromoMessage[]{
        return messages.filter(value => {
            return [PromoMessageType.RESERVATION_UPCOMING].indexOf(value.type) == -1;

        });
    }

    public static marketingMessageForVacanciesGrid(messages: PromoMessage[]):PromoMessage{
        let marketingMessages = messages.filter(value => PromoMessageType.MARKETING_GENERIC == value.type
            && value.id == PromoGenericMessageType.CAMPAIGN_VOUCHER_GAS_PROMO_2022_09_01);
        if (marketingMessages.length == 0){
            return null;
        }
        return marketingMessages[0];
    }

    public static functionalMessageForVacanciesGrid(messages: PromoMessage[]):PromoMessage{
        let functionalMessage = messages.filter(value => PromoMessageType.FUNCTIONAL_NO_USER_PROFILE == value.type);
        if (functionalMessage.length == 0){
            return null;
        }
        return functionalMessage[0];
    }
}
