<div class="site-list-container">
    <div class="site-list-header">
        <h3>{{title}}</h3>
    </div>
    <div *ngIf="sites.length == 0 && !isLoading" style="display: flex;justify-content: center;align-items: center;height: 100px;">
        <p>{{'no_sites_found'|etotranslate}}</p>
    </div>

    <div class="site-list-table-container">
        <div *ngFor="let site of sites">
            <app-site-item [site]="site" (click)="onSiteClicked.emit(site)"></app-site-item>
        </div>
    </div>
</div>
