import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {VacancySection} from "../../../model/ui/vacancy/vacancy-section";
import {MultiVacancyCard} from "../../../model/ui/vacancy/multi-vacancy-card";
import {GroupVacancyCard} from "../../../model/ui/vacancy/group-vacancy-card";
import {SingleVacancyCard} from "../../../model/ui/vacancy/single-vacancy-card";
import {MarketingVacancyCard} from "../../../model/ui/vacancy/marketing-vacancy-card";

@Component({
  selector: 'app-vacancies-list',
  templateUrl: './vacancies-list.component.html',
  styleUrls: ['./vacancies-list.component.scss'],
})
export class VacanciesListComponent extends EtoComponent{
  @Input()
  vehicleCreated: boolean;
  @Input()
  loadMoreEnabled: boolean = false;
  @Input()
  sections: VacancySection[];


  @Input()
  loadingAll: boolean;

  @Output()
  onMultiCardClicked: EventEmitter<MultiVacancyCard> = new EventEmitter<MultiVacancyCard>();
  @Output()
  onGroupCardClicked: EventEmitter<GroupVacancyCard> = new EventEmitter<GroupVacancyCard>();
  @Output()
  onSingleCardClicked: EventEmitter<SingleVacancyCard> = new EventEmitter<SingleVacancyCard>();
  @Output()
  onMarketingCardClicked: EventEmitter<MarketingVacancyCard> = new EventEmitter<MarketingVacancyCard>();
  @Output()
  onShowMoreClicked: EventEmitter<any> = new EventEmitter<any>();

  shouldShowLoadMore() {
    return this.loadMoreEnabled && (this.sections.length > 1 || (this.sections.length > 0 && this.sections[0].cards.length > 0))
  }
}
