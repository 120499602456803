<p-toast></p-toast>
<app-auth-modal *ngIf="showAuthModal"
                [(display)]="showAuthModal"
                (onAuthorisationDone)="tokenReceived($event)">
</app-auth-modal>
<app-vehicle-modal [vehicle]="vehicle"
                   [(display)]="showVehicleModal"
                   (onVehicleCreatedOrUpdate)="onVehicleUpdated($event)">
</app-vehicle-modal>
<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                 [style]="{width: '50vw'}"
                 [closable]="false"
                 icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<app-empty-state *ngIf="!vacancySelection" [text]="'error_generic'|etotranslate"></app-empty-state>
<div class="thank-you-layout" *ngIf="!!vacancySelection && vacancySelection.success">
    <div style="max-width:300px; display:flex; flex-direction:column">
    <h2>{{'reservation_success_title'|etotranslate}}</h2>
    <h2>{{'reservation_success_subtitle'|etotranslate}}</h2>
    <h3>{{getDate()}}</h3>
    <p class="big-emoji" style="margin-top:16px">🎉</p>
    <button pButton pRipple type="button"
            class="p-button-outlined"
            (click)="goToReservations()"
            [label]="'your_reservations'|etotranslate"
            style="margin-top:64px; width: 100%">
    </button>
    <button pButton pRipple type="button"
            class="p-button-text-primary"
            (click)="goHome()"
                [label]="'to_dashboard'|etotranslate"
            style="margin-top:16px; width: 100%">
    </button>
    </div>
    </div>
<div style="display:flex; flex-direction:column; justify-content: center;align-items: center" *ngIf="!!vacancySelection && !vacancySelection.success">
    <div class="confirmation-container" >
        <div style="display:flex;flex-direction:row;align-items:center">
            <button pButton pRipple type="button" icon="pi pi-angle-left"
                    class="p-button-rounded p-button-text"
                    (click)="onBack()"
                    style="margin-right:8px">
            </button>
            <h2>{{'summary'|etotranslate}}</h2>
        </div>
        <div class="confirmation-sections">
            <div class="bill-title-container">

                <div class="bill-title-section">
                    <p>{{'customer'|etotranslate}}</p>
                    <div>
                        <i *ngIf="loadingProfile" class="pi pi-spin pi-spinner font-icon"></i>
                    </div>
                    <span *ngIf="!loadingProfile && !!userProfile" class="bill-title-right">{{getName()}}</span>
                    <span *ngIf="!loadingProfile && !!userProfile" class="bill-title-value-right">{{getPhone()}}</span>
                    <button *ngIf="!loadingProfile && !userProfile" pButton pRipple type="button"
                            style="margin-top:16px"
                            [label]="'login'|etotranslate"
                            class="p-button-primary p-button-sm" (click)="onLoginClicked()"></button>
                </div>

                <div class="bill-title-section">
                    <p>{{'vehicle'|etotranslate}}</p>
                    <div>
                        <i *ngIf="loadingProfile" class="pi pi-spin pi-spinner font-icon"></i>
                    </div>
                    <span *ngIf="!loadingProfile && !!userProfile"
                          class="bill-title-right">{{getLicense()}}</span>
                    <span *ngIf="!loadingProfile && !!userProfile"
                          class="bill-title-value-right">{{getDescription()}}</span>
                    <button *ngIf="!loadingProfile && !!userProfile && !userProfile.vehicle" pButton pRipple
                            type="button"
                            style="margin-top:16px"
                            [label]="'add_vehicle'|etotranslate"
                            class="p-button-primary p-button-sm" (click)="onAddVehicleClicked()"></button>
                </div>
                <div class="bill-title-section">
                    <p>{{'site'|etotranslate}}</p>
                    <span class="bill-title">{{vacancySelection.vacancy.carService.name}}</span>
                    <span class="bill-title-value">{{vacancySelection.vacancy.carService.address}}</span>
                    <span class="bill-title-value">{{vacancySelection.vacancy.carService.zipCode}}
                        , {{vacancySelection.vacancy.carService.city}}</span>
                    <button pButton pRipple type="button"
                            style="margin-top:16px"
                            [label]="'site_profile'|etotranslate"
                            class="p-button-link site-link" (click)="onSiteClicked()"></button>
                </div>
            </div>
            <div class="separator"></div>
            <div class="bill-row" *ngFor="let item of billItems">
                <div class="bill-label-container">
                    <i [class]="item.icon +' bill-icon font-icon'"></i>
                    <span class="bill-label">{{item.label}}</span>
                </div>
                <div class="connection-line"></div>
                <span class="bill-value">{{item.value}}</span>
            </div>
            <app-checklist-list *ngIf="!!vacancySelection.vacancy.priceMetadata && !!vacancySelection.vacancy.priceMetadata.checkListItems"
                                style="margin-left:44px"
                                [items]="vacancySelection.vacancy.priceMetadata.checkListItems"></app-checklist-list>
            <div class="separator"></div>
            <div class="bill-row bill-price">
                <div style="display:flex;flex-direction:row;align-items: center">
                    <h3 [pTooltip]="getPriceTooltip()">{{getPriceLabel()}}</h3>
                    <i [pTooltip]="getPriceTooltip()" class="pi pi-info-circle font-icon" style="font-size:1.5rem; margin-left:8px"></i>
                </div>
                <div class="connection-line"></div>
                <h3>{{getPrice()}}</h3>
            </div>
        </div>
        <div class="button-container">
            <p *ngIf="!loadingProfile && (!userProfile || !userProfile.vehicle)"
               style="margin-bottom:16px;text-align:center">{{getPromptMessage()}}</p>
            <p-button [icon]="'pi pi-check'"
                      [loading]="isLoading || loadingProfile || loadingCheck || creatingReservation"
                      [disabled]="confirmDisabled()"
                      [label]="getButtonLabel()"
                      (onClick)="onReservationConfirmClicked()"
                      [styleClass]="!isHandsetPortrait ? 'p-button-lg' : 'p-button-primary'">
            </p-button>
        </div>
    </div>
</div>
