import {DateFormatter} from "./date-formatter";
import {EtoTranslation} from "../translation/eto-translation";
import {LabelUtil} from "./label-util";
import {VacancyResult} from "../../model/api/response/vacancy-result";
import {MainVacancyResult} from "../../model/api/response/main-vacancy-result";
import * as moment from "moment";
import {Vacancy} from "../../model/api/response/vacancy";

export class VacancyUtil {

    public static getVacancySectionForDate(date: Date) {
        if (LabelUtil.isToday(date)) {
            return EtoTranslation.getInstant('today');
        }
        if (LabelUtil.isTomorrow(date)) {
            return EtoTranslation.getInstant('tomorrow');
        }
        if (LabelUtil.isThisWeek(date)) {
            return EtoTranslation.getInstant('later_this_week');
        }
        if (LabelUtil.isNextWeek(date)) {
            return EtoTranslation.getInstant('next_week');
        }
        if (LabelUtil.isThisMonth(date)) {
            return EtoTranslation.getInstant('this_month');
        }
        return LabelUtil.capitalize(DateFormatter.toUIMonthFormat(date));
    }

    public static mergeVacancies(previousResult: MainVacancyResult, newResult: MainVacancyResult) {
        const items: VacancyResult[] = previousResult.items.filter(value => !!value).concat(newResult.items);
        const merged = new MainVacancyResult();
        merged.startDate = previousResult.startDate;
        merged.endDate = newResult.endDate;
        merged.items = items;
        return merged;
    }

    public static getVacancyReadableDay(vacancy: VacancyResult): string {
        return this.getReadableDay(vacancy.startDate);
    }

    public static getReadableDay(date: Date): string {
        if (LabelUtil.isToday(date) || LabelUtil.isTomorrow(date)) {
            return `${LabelUtil.formatReadableDate(date)}`
        }
        return DateFormatter.dateToStringWithYear(date);
    }

    public static getVacancyReadableDayAndDate(vacancy: VacancyResult): string {
        return `${LabelUtil.formatReadableDate(vacancy.startDate)} ${DateFormatter.dateToStringShort(vacancy.startDate)}`
    }

    public static getVacanciesCountText(count: number): string{
        if (count == 1){
            return EtoTranslation.getInstant('one_vacancy');
        }else if (count!=0 && count < 5){
            return EtoTranslation.getInstant('four_vacancies', {count: count});
        }
        else{
            return EtoTranslation.getInstant('five_vacancies', {count: count});
        }
    }

    public static getMultiCardTitle(count: number) {
        return EtoTranslation.getInstant('choose_one_of_x_sites', {count: count});
    }
}
