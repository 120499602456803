<div class="widget-wrapper">
    <div class="widget-container">
        <p-menubar [model]="filterMenuItems" [autoDisplay]="false">
            <ng-template pTemplate="end">
                <i class="pi pi-spin pi-spinner font-icon" *ngIf="loadingFilter"></i>
            </ng-template>
        </p-menubar>
        <button pButton type="button" style="margin-left:16px; padding:8px"
                pRipple
                class="p-button-primary"
                [disabled]="loadingFilter"
                [label]="'all_vacancies'|etotranslate"
                (click)="onButtonClicked()">
        </button>
    </div>
</div>
