import {JobTemplate} from "../api/response/job-template";
import {Vacancy} from "../api/response/vacancy";
import {SiteType} from "../api/response/site-type";

export class VacancySelection{
    jobTemplateId: number;
    jobTemplateName: string;
    siteType: SiteType;
    vacancy: Vacancy;
    success: boolean;
}
