import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {ApiService} from "../api.service";
import {Constants} from "../../../constants/constants";
import {RefreshToken} from "../../../model/api/request/refresh-token";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: ApiService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.withCredentials || request.url.includes(Constants.ApiUrl.refresh)) {
      return next.handle(request);
    }
    if (!this.authService.isActiveSession()) {
      return this.handleTokenExpiredError(request, next);
    }
    const token = this.authService.getToken();
    request = this.addToken(request, token.accessToken);
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 418) {
        return this.handleTokenExpiredError(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private handleTokenExpiredError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authService.refreshAccessToken(new RefreshToken(this.authService.getRefreshToken())).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(token.accessToken);
          return next.handle(this.addToken(request, token.accessToken));
        }));

    } else {
      return this.accessTokenSubject.pipe(
        filter(accessToken => accessToken != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (request.url.includes(Constants.ApiUrl.refresh)) {
      return request;
    }
    return request.clone({
      setHeaders: {
        'Authorization': `ERNESTTO ${token}`,
      }
    });
  }
}
