<p-toast></p-toast>
<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                 [style]="{width: '50vw'}"
                 [closable]="false"
                 [acceptLabel]="'yes'|etotranslate"
                 [rejectLabel]="'no'|etotranslate"
                 icon="pi pi-exclamation-triangle">
</p-confirmDialog>

<div style="display:flex; flex-direction:column; justify-content: center;align-items: center">
    <div class="reservation-container">
        <div style="display:flex;flex-direction:row;align-items:center">
            <button pButton pRipple type="button" icon="pi pi-angle-left"
                    class="p-button-rounded p-button-text"
                    (click)="onBack()"
                    style="margin-right:8px">
            </button>
            <h2>{{'reservation_detail'|etotranslate}}</h2>
            <i style="margin-left: 8px" *ngIf="isLoading" class="pi pi-spin pi-spinner font-icon"></i>
        </div>
        <app-empty-state *ngIf="!isLoading && !reservation" [text]="'error_generic'|etotranslate"></app-empty-state>
        <div class="reservation-section-container" *ngIf="!isLoading && !!reservation" style="padding-bottom:32px">
            <div style="margin:32px">
                <span [class]="'rounded-'+reservation.state.toString()" class="pi pi-book"></span>
                <h4 *ngIf="!isHandsetPortrait" style="margin-top:4px; margin-bottom:8px;text-align:center">{{getReservationStateText()}}</h4>
            </div>
            <div class="reservation-section-data-container">
                <div style="display: flex; flex-direction:row; align-items: flex-end">
                    <div style="display:flex;flex-direction:column">
                        <p style="margin-top:32px;">{{'site'|etotranslate}}</p>
                        <h3 style="margin-top:0">{{reservation.carService.name}}</h3>
                    </div>
                    <button pButton pRipple type="button" icon="pi pi-angle-double-right"
                            class="p-button-rounded p-button-text"
                            (click)="onSiteDetailClicked()"
                            style="margin-left:8px;margin-bottom:18px">
                    </button>
                </div>

                <p style="margin-top:0px">{{reservation.vehicle.description}}</p>
                <h3 style="margin-top:0">{{reservation.vehicle.license}}</h3>
                <div style="display:flex;flex-direction:row;align-items: center">
                    <p [pTooltip]="getPriceTooltip()" style="margin-top:0px">{{getPriceLabel()}}</p>
                    <i [pTooltip]="getPriceTooltip()" class="pi pi-info-circle" style="font-size:1rem; margin-left:8px"></i>
                </div>

                <h3 style="margin-top:0">{{getPrice()}}</h3>
                <div class="reservation-section-items-container">
                    <div *ngIf="isHandsetPortrait" class="reservation-section-data-item">
                        <p>{{'state'|etotranslate}}</p>
                        <h4 style="margin-top:4px; margin-bottom:8px">{{getReservationStateText()}}</h4>
                    </div>
                    <div class="reservation-section-data-item">
                        <p>{{'reservation_job'|etotranslate}}</p>
                        <h4 style="margin-top:4px; margin-bottom:8px">{{getJobTitle()}}</h4>
                        <app-checklist-list *ngIf="shouldDisplayChecklist()" [items]="reservation.priceMetadata.checkListItems"></app-checklist-list>
                    </div>
                    <div class="reservation-section-data-item">
                        <p>{{'date_time'|etotranslate}}</p>
                        <h4 style="margin-top:4px; margin-bottom:8px;">{{getReservationDateText()}}</h4>
                        <div *ngIf="shouldDisplayChecklist()" class="reservation-section-data-item">
                            <p>{{'address'|etotranslate}}</p>
                            <h4 style="margin-top:4px; margin-bottom:8px">{{getAddress()}}</h4>
                        </div>
                    </div>
                    <div *ngIf="!shouldDisplayChecklist()" class="reservation-section-data-item">
                        <p>{{'address'|etotranslate}}</p>
                        <h4 style="margin-top:4px; margin-bottom:8px">{{getAddress()}}</h4>
                    </div>
                </div>
                <div *ngIf="canDisplayCancelButton()" class="reservation-section-items-container">
                    <div style="padding-top:32px">
                        <button pButton pRipple type="button" icon="pi pi-trash" [label]="'cancel_reservation'|etotranslate"
                                [disabled]="!reservation.cancellable"
                                (click)="onCancelReservationClicked()"
                                class="p-button-secondary p-button-outlined"></button>
                    </div>
                </div>
                <p *ngIf="!reservation.cancellable && canDisplayCancelButton()" class="cancellable-disclaimer">{{'not_cancellable_reservation_disclaimer'|etotranslate}}</p>
            </div>
        </div>
    </div>
</div>
