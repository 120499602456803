import {VacancyResult} from "../../model/api/response/vacancy-result";
import {VacancyCard, VacancyItemType} from "../../model/ui/vacancy/vacancy-card";
import {PromoMessage} from "../../model/api/response/promo-message";
import {MainVacancyResult} from "../../model/api/response/main-vacancy-result";
import {VacancySection} from "../../model/ui/vacancy/vacancy-section";
import {EtoTranslation} from "../translation/eto-translation";
import {LabelUtil} from "../util/label-util";
import {VacancyUtil} from "../util/vacancy-util";
import {MultiVacancyCard} from "../../model/ui/vacancy/multi-vacancy-card";
import {SingleVacancyCard} from "../../model/ui/vacancy/single-vacancy-card";
import {Vacancy} from "../../model/api/response/vacancy";
import {GroupVacancyCard} from "../../model/ui/vacancy/group-vacancy-card";
import {PromoMessageType} from "../../model/api/response/promo-message-type";
import {MarketingVacancyCard} from "../../model/ui/vacancy/marketing-vacancy-card";
import {PromoGenericMessageType} from "../../model/api/response/promo-generic-message-type";
import {PromoMessageUtil} from "../promo/promo-message-util";

export class VacanciesDataProcessor{
    private readonly targetDate: Date;
    private readonly multipleSite: boolean;
    private readonly messages: PromoMessage[];

    private firstDateSection: Date;
    private secondDateSection: Date;

    private sections: VacancySection[] = [];


    constructor(targetDate: Date, multipleSite: boolean, messages: PromoMessage[]) {
        this.targetDate = targetDate;
        this.multipleSite = multipleSite;
        this.messages = messages;
    }

    public vacanciesToCards(result: MainVacancyResult): VacancySection[]{
        this.sections = [];
        if (!result.items || result.items.length == 0){
            this.appendNoResultsCard();
            return this.sections;
        }
        let lastSection;
        this.findFirstTwoDates(result);
        for (const vacancyResult of result.items) {
            if (this.sections.length == 0 && !LabelUtil.isSameDay(vacancyResult.startDate, this.targetDate)){
                this.appendNoResultForTargetSection();
            }
            const sectionForItem = this.getSectionTitle(vacancyResult);
            if (!lastSection || lastSection.title != sectionForItem){
                lastSection = new VacancySection();
                lastSection.title = sectionForItem;
                this.sections.push(lastSection);
            }
            if (this.isTargetingFirstSection(vacancyResult.startDate)) {
                if (lastSection.cards.length < 8) {
                    this.addAsSingleOrMultiCards(vacancyResult, lastSection.cards);
                } else{
                    this.addAsDayGroupCard(vacancyResult, lastSection);
                }
            } else if (this.isTargetingSecondSection(vacancyResult.startDate) && this.multipleSite) {
                this.addAsSiteGroupCard(vacancyResult, lastSection);
            } else {
                this.addAsDayGroupCard(vacancyResult, lastSection);
            }

        }
        this.appendGroupMetadata();
        this.appendMarketingCards();
        return this.sections;
    }

    private appendGroupMetadata() {
        for (const section of this.sections) {
            for (const card of section.cards) {
                if (card.type == VacancyItemType.GROUP_CARD){
                    const groupCard = (card as GroupVacancyCard);
                    let count = 0;
                    let minPrice;
                    for (const cardInGroup of groupCard.cards) {
                        if (cardInGroup.type == VacancyItemType.SINGLE_CARD){
                            count++;
                            let price = (cardInGroup as SingleVacancyCard).vacancy.price;
                            if (!minPrice || minPrice > price){
                                minPrice = price;
                            }
                        }
                        if (cardInGroup.type == VacancyItemType.MULTI_CARD){
                            count = count + (cardInGroup as MultiVacancyCard).cards.length;
                            let price = (cardInGroup as MultiVacancyCard).minPrice;
                            if (!minPrice || minPrice > price){
                                minPrice = price;
                            }
                        }
                    }
                    groupCard.vacanciesCount = count;
                    groupCard.minPrice = minPrice;
                }
            }
        }
    }

    private isTargetingFirstSection(date: Date) {
        return LabelUtil.isSameDay(date, this.firstDateSection);
    }

    private isTargetingSecondSection(date: Date) {
        return LabelUtil.isSameDay(date, this.secondDateSection);
    }

    private addAsSingleOrMultiCards(vacancyResult: VacancyResult, target: VacancyCard[]) {
        if (vacancyResult.items.length > 1) {
            let multiVacancyCard = new MultiVacancyCard();
            for (const item of vacancyResult.items) {
                multiVacancyCard.cards.push(this.itemToCard(item));
            }
            multiVacancyCard.minPrice = vacancyResult.minPrice;
            target.push(multiVacancyCard);
        } else {
            target.push(this.itemToCard(vacancyResult.items[0]));
        }
    }

    private addAsDayGroupCard(vacancyResult: VacancyResult, lastSection: VacancySection) {
        let groupTitle = VacancyUtil.getVacancyReadableDayAndDate(vacancyResult);
        this.addAsGroupCard(lastSection, groupTitle, vacancyResult);
    }

    private addAsSiteGroupCard(vacancyResult: VacancyResult, lastSection: VacancySection) {
        for (const item of vacancyResult.items) {
            let result = new VacancyResult();
            result.items = [item];
            result.startDate = vacancyResult.startDate;
            result.endDate = vacancyResult.endDate;
            result.minPrice = vacancyResult.minPrice;
            result.minDistance = vacancyResult.minDistance;
            this.addAsGroupCard(lastSection, item.carService.name, result);
        }
    }

    private addAsGroupCard(lastSection: VacancySection, groupTitle: string, vacancyResult: VacancyResult) {
        let groupCards = lastSection.cards.filter(value => {
            return (value as GroupVacancyCard).title == groupTitle
        });
        if (groupCards.length == 0) {
            let groupCard = new GroupVacancyCard();
            groupCard.title = groupTitle;
            lastSection.cards.push(groupCard);
            groupCards[0] = groupCard;
        }
        this.addAsSingleOrMultiCards(vacancyResult, (groupCards[0] as GroupVacancyCard).cards);
    }

    private getSectionTitle(vacancyResult: VacancyResult): string{
        if (LabelUtil.isSameDay(this.firstDateSection, vacancyResult.startDate)
            || LabelUtil.isSameDay(this.secondDateSection, vacancyResult.startDate)){
            return VacancyUtil.getVacancyReadableDay(vacancyResult);
        }
        return VacancyUtil.getVacancySectionForDate(vacancyResult.startDate);

    }

    private appendNoResultForTargetSection() {
        const noResultsForTargetSection = new VacancySection();
        noResultsForTargetSection.title = EtoTranslation.getInstant('no_vacancies_for_target_error', {day: VacancyUtil.getReadableDay(this.targetDate)});
        noResultsForTargetSection.subtitle = EtoTranslation.getInstant('no_vacancies_for_target_error_subtitle');
        this.sections.push(noResultsForTargetSection);
    }

    private appendNoResultsCard() {
        const noResultsSection = new VacancySection();
        noResultsSection.title = EtoTranslation.getInstant('no_vacancies_error');
        noResultsSection.subtitle = EtoTranslation.getInstant('no_vacancies_error_subtitle');
        this.sections.push(noResultsSection);
    }

    private itemToCard(item: Vacancy): SingleVacancyCard{
        let singleVacancyCard = new SingleVacancyCard();
        singleVacancyCard.vacancy = item;
        return singleVacancyCard;
    }

    private findFirstTwoDates(result: MainVacancyResult) {
        this.firstDateSection = null;
        this.secondDateSection = null;
        for (const item of result.items) {
            if (this.firstDateSection == null){
                this.firstDateSection = item.startDate;
            }
            if (this.secondDateSection == null && !LabelUtil.isSameDay(this.firstDateSection, item.startDate)){
                this.secondDateSection = item.startDate;
            }
            if (!!this.firstDateSection && !!this.secondDateSection){
                return;
            }
        }
    }

    toSimpleCards(card: MultiVacancyCard) {
        const vacancySection = new VacancySection();
        vacancySection.cards = card.cards;
        vacancySection.title = VacancyUtil.getMultiCardTitle(card.cards.length);
        return [vacancySection];
    }

    toSimpleAndMultiCards(card: GroupVacancyCard) {
        const vacancySection = new VacancySection();
        vacancySection.cards = card.cards;
        vacancySection.title = card.title;
        return [vacancySection];
    }

    private appendMarketingCards() {
        this.addMarketingCard();
        this.addFunctionalCard();
    }

    private addMarketingCard() {
        let promoMessage = PromoMessageUtil.marketingMessageForVacanciesGrid(this.messages);
        if (!promoMessage){
            return;
        }
        let firstSection = this.getFirstSection();
        if (!!firstSection){
            let marketingVacancyCard = new MarketingVacancyCard();
            marketingVacancyCard.message = promoMessage;
            marketingVacancyCard.small = false;
            firstSection.cards.splice(firstSection.cards.length >= 4 ? 3 : firstSection.cards.length - 1, 0, marketingVacancyCard);

        }
    }

    private addFunctionalCard() {
        let promoMessage = PromoMessageUtil.functionalMessageForVacanciesGrid(this.messages);
        if (!promoMessage){
            return;
        }
        let thirdSection = this.getThirdSection();
        if (!!thirdSection){
            let marketingVacancyCard = new MarketingVacancyCard();
            marketingVacancyCard.message = promoMessage;
            marketingVacancyCard.small = true;
            if (thirdSection.cards.length < 4){
                return;
            }
            thirdSection.cards.splice(3, 0, marketingVacancyCard);

        }
    }

    private getFirstSection(){
        const firstSectionEmpty = this.sections.length > 0 && this.sections[0].cards.length == 0;
        if (firstSectionEmpty && this.sections.length > 1){
            return this.sections[1];
        }
        if (!firstSectionEmpty && this.sections.length > 0){
            return this.sections[0];
        }
        return null;
    }

    private getThirdSection(){
        const firstSectionEmpty = this.sections.length > 0 && this.sections[0].cards.length == 0;
        if (firstSectionEmpty && this.sections.length > 3){
            return this.sections[3];
        }
        if (!firstSectionEmpty && this.sections.length > 2){
            return this.sections[2];
        }
        return null;
    }
}
