import {Observable} from "rxjs";
import {ApiError} from "../error/api-error";
import {ApiRequestExecutor} from "./api-request-executor";
import {Injector} from "@angular/core";

export class ApiRequestBuilder<T> {
  private readonly apiRequest: ApiRequest<T>;

  public static create<T>(observableRequest: Observable<T>): ApiRequestBuilder<T> {
    return new ApiRequestBuilder(observableRequest);
  }

  constructor(observableRequest: Observable<T>) {
      this.apiRequest = new ApiRequest<T>(observableRequest);
  }

  build():ApiRequestExecutor<T>{
    return new ApiRequestExecutor<T>(this.apiRequest);
  }
}

export class ApiRequest<T> {
  observableRequest: Observable<T>;

  constructor(observableRequest: Observable<T>) {
    this.observableRequest = observableRequest;
  }
}
