import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiFactoryService} from "./api-factory-service";
import {TokenApiService} from "./token-api-service";
import {UrlBuilder} from "./helper/url-builder";
import {environment} from "../../../environments/environment";
import {Constants} from "../../constants/constants";
import {ResponseData} from "../../model/api/response/response-data";
import {Site} from "../../model/api/response/site";
import {map} from "rxjs/operators";
import {AuthRequest} from "../../model/api/request/auth-request";
import {VerificationResponse} from "../../model/api/response/verification-response";
import {VerificationRequest} from "../../model/api/request/verification-request";
import {Token} from "../../model/api/response/token";
import {UserProfile} from "../../model/api/response/user-profile";
import {ResendCodeRequest} from "../../model/api/request/resend-code-request";
import {Region} from "../../model/api/response/region";
import {PromoMessage} from "../../model/api/response/promo-message";
import {SiteType} from "../../model/api/response/site-type";
import {JobTemplate} from "../../model/api/response/job-template";
import {Vehicle} from "../../model/api/response/vehicle";
import {Filter} from "../../model/ui/filter";
import {MainVacancyResult} from "../../model/api/response/main-vacancy-result";
import {DateFormatter} from "../util/date-formatter";
import {UserUpdate} from "../../model/api/request/user-update";
import {VehicleCategory} from "../../model/api/response/vehicle-category";
import {VehicleUpdate} from "../../model/api/request/vehicle-update";
import {VehicleCreate} from "../../model/api/request/vehicle-create";
import {SimpleReservationGroup} from "../../model/api/response/simple-reservation-group";
import {Reservation} from "../../model/api/response/reservation";
import {SiteDetail} from "../../model/api/response/site-detail";
import {VacancyCheckResult} from "../../model/api/response/vacancy-check-result";
import {VacancyCheck} from "../../model/api/response/vacancy-check";
import {ReservationCreate} from "../../model/api/request/reservation-create";
import {MessageService} from "primeng/api";

@Injectable()
export class ApiService extends TokenApiService {


    constructor(router: Router, http: HttpClient, messageService: MessageService, private apiFactoryService: ApiFactoryService) {
        super(router, http, messageService);
    }

    verifyPhone(authRequest: AuthRequest) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.auth)
            .appendPathParam(Constants.ApiUrl.verify)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.post<ResponseData<VerificationResponse>>(url, authRequest, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    authorize(verificationRequest: VerificationRequest) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.auth)
            .appendPathParam(Constants.ApiUrl.authorize)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.post<ResponseData<Token>>(url, verificationRequest, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    resendCode(resendCodeRequest: ResendCodeRequest) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.auth)
            .appendPathParam(Constants.ApiUrl.resend)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.post<ResponseData<VerificationResponse>>(url, resendCodeRequest, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getProfile() {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.profile)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<UserProfile>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    updateProfile(update: UserUpdate) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.profile)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.put<ResponseData<any>>(url, update, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    searchSites(query: string) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.carservice)
            .appendPathParam(Constants.ApiUrl.sites)
            .appendPathParam(Constants.ApiUrl.search)
            .appendQueryParam({key: 'query', value: query})
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<Site[]>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getSites(regionId: number, type: SiteType, lat: number, lng: number) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.carservice)
            .appendPathParam(Constants.ApiUrl.sites)
            .appendQueryParam({key: 'regionId', value: regionId.toString()})
            .appendQueryParam({key: 'type', value: !!type ? type.toString() : null})
            .appendQueryParam({key: 'lat', value: !!lat ?  lat.toString(): null})
            .appendQueryParam({key: 'lng', value: !!lng ? lng.toString() : null})
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<Site[]>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getPromoMessages(installDate: string, localVehicleCreated: boolean) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.profile)
            .appendPathParam(Constants.ApiUrl.promoMessages)
            .appendQueryParam({key: 'version', value: "1"})
            .appendQueryParam({key: 'installDate', value: installDate})
            .appendQueryParam({key: 'localVehicleCreated', value: Boolean(localVehicleCreated).toString()})
            .build();
        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<PromoMessage[]>>(url, {withCredentials: this.isUserLoggedIn()})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getRegions(lat: number, lng:number) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.carservice)
            .appendPathParam(Constants.ApiUrl.regions)
            .appendQueryParam({key: 'lat', value: lat?.toString()})
            .appendQueryParam({key: 'lng', value: lng?.toString()})
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<Region[]>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getRegion(lat: number, lng:number) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.carservice)
            .appendPathParam(Constants.ApiUrl.region)
            .appendQueryParam({key: 'lat', value: lat?.toString()})
            .appendQueryParam({key: 'lng', value: lng?.toString()})
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<Region>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getJobTemplates(type: SiteType) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .appendPathParam(Constants.ApiUrl.jobTemplates)
            .appendQueryParam({key: 'type', value: type.toString()})
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<JobTemplate[]>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getVehicles() {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.vehicle)
            .appendPathParam(Constants.ApiUrl.list)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<Vehicle[]>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getVacancies(filter: Filter, seekFrom: Date) {
        let urlBuilder = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .appendPathParam(Constants.ApiUrl.vacancies)
            .appendQueryParam({key: 'regionId', value: !filter.selectedRegionId ? null : filter.selectedRegionId.toString()})
            .appendQueryParam({key: 'lat', value: !filter.lat ? null : filter.lat.toString()})
            .appendQueryParam({key: 'lng', value: !filter.lng ? null : filter.lng.toString()})
            .appendQueryParam({key: 'jobTemplateId', value: !filter.selectedJobTemplateId ? null : filter.selectedJobTemplateId.toString()})
            .appendQueryParam({key: 'seekFromDate', value: !seekFrom ? null : DateFormatter.toApiDateFormat(seekFrom)})
            .appendQueryParam({key: 'timeWindow', value: !filter.selectedTime ? null : filter.selectedTime.toString()})
            .appendQueryParam({key: 'carServiceId', value: !filter.selectedSiteId ? null : filter.selectedSiteId.toString()})
            .appendQueryParam({key: 'minResultsSize', value: '50'});
        if (!!filter.selectedCategoryItemsIds){
            filter.selectedCategoryItemsIds.forEach(value => {
            urlBuilder.appendQueryParam({key: 'categoryItem', value: value.toString()})
            })
        }
        const url = urlBuilder
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<MainVacancyResult>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getVehicleOptions() {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.vehicle)
            .appendPathParam(Constants.ApiUrl.options)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<VehicleCategory[]>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    updateVehicle(update: VehicleUpdate) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.vehicle)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.put<ResponseData<any>>(url, update, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    createVehicle(create: VehicleCreate) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.vehicle)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.post<ResponseData<any>>(url, create, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getReservations() {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .appendPathParam(Constants.ApiUrl.list)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<SimpleReservationGroup[]>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    getReservation(id: string) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .appendPathParam(id)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<Reservation>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    cancelReservation(id: string) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .appendPathParam(id)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.delete<ResponseData<Reservation>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData;
            })));
        return builder.build();
    }

    getSite(id: string) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.carservice)
            .appendPathParam(Constants.ApiUrl.site)
            .appendPathParam(id)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<SiteDetail>>(url, {withCredentials: false})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    checkVacancy(jobTemplateId: number, vacancyDate: Date, price: number, priceGuaranteed: boolean, vehicleId: number, carServiceId: number) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .appendPathParam(Constants.ApiUrl.vacancy)
            .appendPathParam(Constants.ApiUrl.check)
            .appendQueryParam({key:"jobTemplateId", value:jobTemplateId.toString()})
            .appendQueryParam({key:"vacancyDate", value: DateFormatter.toApiDateTimeFormat(vacancyDate)})
            .appendQueryParam({key:"price", value:price.toFixed(2)})
            .appendQueryParam({key:"priceGuaranteed", value: String(priceGuaranteed)})
            .appendQueryParam({key:"vehicleId", value:vehicleId.toString()})
            .appendQueryParam({key:"carServiceId", value:carServiceId.toString()})
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.get<ResponseData<VacancyCheck>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData.data;
            })));
        return builder.build();
    }

    createReservation(create: ReservationCreate) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.reservation)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.post<ResponseData<any>>(url, create,{withCredentials: true})
            .pipe(map(responseData => {
                return responseData;
            })));
        return builder.build();
    }

    deleteProfile() {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.profile)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.delete<ResponseData<any>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData;
            })));
        return builder.build();
    }

    markAsSeen(id: string) {
        const url = UrlBuilder.create(`${environment.endpoint.baseUrl}${environment.endpoint.apiUrl}`, environment.endpoint.apiVersion)
            .appendPathParam(Constants.ApiUrl.profile)
            .appendPathParam(Constants.ApiUrl.promoMessage)
            .appendPathParam(id)
            .build();

        let builder = this.apiFactoryService.createBuilder(this.http.delete<ResponseData<any>>(url, {withCredentials: true})
            .pipe(map(responseData => {
                return responseData;
            })));
        return builder.build();
    }
}
