export const environment = {
    production: false,
    endpoint: {
        baseUrl: "/rest",
        apiUrl: "/api",
        apiVersion: "/v1",
    },
    firebase: {
        apiKey: "AIzaSyAuxlSQ__FwlladiAloWNdHc1JvB810ggI",
        authDomain: "ernest-to.firebaseapp.com",
        databaseURL: "https://ernest-to.firebaseio.com",
        projectId: "ernest-to",
        storageBucket: "ernest-to.appspot.com",
        messagingSenderId: "902914427923",
        appId: "1:902914427923:web:8f064136bf2b46093943e6",
        measurementId: "G-747N18YSM6"
    },
    recaptcha: {
        siteKey: '6Lcd1eklAAAAAFgDrAxyN_Ef1yMpdA-p8BCMqZGI',
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
