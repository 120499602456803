import * as moment from 'moment';
import {EtoTranslation} from "../translation/eto-translation";

const API_FULL_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

const API_FORMAT = 'yyyy-MM-DD';

const UI_FULL_FORMAT = 'DD.MM.YYYY HH:mm';

const UI_FORMAT = 'DD.MM.yyyy';
const UI_MONTH_FORMAT = 'MMMM yyyy';

export class DateFormatter {

  public static dateToString(date: Date): string {
    return moment(date).locale(EtoTranslation.getSelectedLang()).format("DD. MMMM");
  }

  public static dateToStringShort(date: Date): string {
    return moment(date).locale(EtoTranslation.getSelectedLang()).format("DD. MMM");
  }

  public static dateToStringWithYear(date: Date): string {
    return moment(date).locale(EtoTranslation.getSelectedLang()).format("DD. MMM yyyy");
  }

  public static timeToString(date: Date): string {
    return moment(date).locale(EtoTranslation.getSelectedLang()).format("HH:mm");
  }

  public static stringToTime(date: string): Date {
    return moment(date, "HH:mm", EtoTranslation.getSelectedLang()).toDate();
  }

  public static toApiDateTimeFormat(date: Date): string {
    return moment(date).format(API_FULL_FORMAT);
  }

  public static toApiDateFormat(date: Date): string {
    return moment(date).format(API_FORMAT);
  }

  public static toApiMonthFormat(date: Date): string {
    return moment(date).format("yyyy-MM");
  }

  public static formatOpeningHour(value: number): string {
    return moment().startOf('day').add(value, 'minutes').format('HH:mm');
  }

  /**
   * Designed for input format 'HH:mm'
   */
  public static minutesOfTime(value: string): number {
    return moment.duration(value).asMinutes();
  }

  public static toUIFormat(date: Date): string {
    return moment(date).format(UI_FULL_FORMAT);
  }

  public static toUIDayFormat(date: Date): string {
    return moment(date).format(UI_FORMAT);
  }

  public static toUIMonthFormat(date: Date): string {
    return moment(date).locale(EtoTranslation.getSelectedLang()).format(UI_MONTH_FORMAT);
  }

  public static fromUIFormat(date: string): Date {
    return moment(date, UI_FULL_FORMAT).toDate();
  }

  public static fromAPIFormat(date: string): Date {
    return moment(date, API_FORMAT).toDate();
  }

  public static formatMonthFromRange(start: Date, end: Date) {
    let diff = moment(end).diff(moment(start), 'days');
    let middleDate = moment(start).add(diff / 2, 'days').toDate();
    return this.toApiMonthFormat(middleDate);
  }

  public static fromFullFormat(date: string): Date {
    return moment(date, API_FULL_FORMAT).toDate();
  }

  public static toFullFormat(date: Date): string {
    return moment(date).format(API_FULL_FORMAT);
  }
}
