import {MenuItem} from "primeng/api";
import {Filter} from "../../model/ui/filter";
import {KeyValue} from "@angular/common";
import {FilterType} from "./filter-type";
import {HttpErrorResponse} from "@angular/common/http";
import {FilterResultType} from "./filter-result-type";

export class FilterResult {
    menuItems: MenuItem[];
    queryParam: string;
    filter: Filter;
    error: HttpErrorResponse;
    type: FilterResultType;

    progress: KeyValue<FilterType, boolean>[] = [
        {key: FilterType.REGION, value: true},
        {key: FilterType.SITE_TYPE, value: true},
        {key: FilterType.JOB, value: true},
        {key: FilterType.TIME_WINDOW, value: true},
        {key: FilterType.START_DATE, value: true},
        {key: FilterType.VEHICLE, value: true},
        {key: FilterType.DESTINATION, value: true}
    ];

    updateProgress(paramItem: KeyValue<FilterType, boolean>) {
        for (let progressItem of this.progress) {
            if (progressItem.key == paramItem.key) {
                progressItem.value = paramItem.value;
            }
        }

    }
}
