import {Injectable} from "@angular/core";
import {ApiService} from "../api/api.service";
import {Filter} from "../../model/ui/filter";
import {Constants} from "../../constants/constants";
import {FilterFactory} from "./filter-factory";
import {Observable, Subject} from "rxjs";
import {FilterResult} from "./filter-result";
import {MenuItem} from "primeng/api";
import {FilterType} from "./filter-type";
import {FilterResultType} from "./filter-result-type";
import {EtoTranslation} from "../translation/eto-translation";
import {TypeMenuProvider} from "../menu/type/type-menu-provider";
import {SiteType} from "../../model/api/response/site-type";
import {Vehicle} from "../../model/api/response/vehicle";

@Injectable()
export class FilterService {
    private filterFactory: FilterFactory;
    private filterSubject: Subject<FilterResult> = new Subject<FilterResult>();
    private filterResult: FilterResult = new FilterResult();

    constructor(private apiService: ApiService) {
        this.filterFactory = new FilterFactory(this.apiService);
    }

    getFilter(){
        return this.filterResult.filter;
    }

    loadFilter(queryParam: string, notifier: Observable<any>) {
        this.filterResult.error = null;
        this.filterResult.filter = queryParam
            ? this.getFilterFromQueryParams(queryParam)
            : this.getFilterFromLocalStorage();
        this.filterResult.queryParam = queryParam;
        if (!this.filterResult.menuItems) {
            this.filterResult.menuItems = this.getDefaultMenuItems();
            this.postUpdate(this.filterResult);
        }
        this.filterFactory.loadAllFilterOptions(notifier, this.filterResult, filterMenu => {
            this.onDone(filterMenu)
        }, () => {
            this.onUpdate()
        }, needsUpdate => {
            this.onSelectionChanged(needsUpdate, notifier);
        });
    }

    private onSelectionChanged(needsUpdate: FilterType[], notifier: Observable<any>) {
        if (needsUpdate.length > 0) {
            this.filterFactory.loadFilterOptionsSelected(needsUpdate, this.filterResult, notifier, filterMenu => {
                this.onDone(filterMenu)
            }, () => {
                this.onUpdate();
            }, needsUpdate1 => {
                this.onSelectionChanged(needsUpdate1, notifier);
            })
        } else if (!!this.filterResult.menuItems) {
            this.onDone(Array.from(this.filterResult.menuItems));
        }
    }

    private onUpdate() {
        this.updateQueryParam();
        this.setFilterToLocalStorage(this.filterResult.filter);
        this.filterResult.type = FilterResultType.UPDATE_UI;
        this.postUpdate(this.filterResult);
    }

    private onDone(filterMenu: MenuItem[]) {
        this.updateResultWithMenuItems(filterMenu);
        this.updateQueryParam();
        this.setFilterToLocalStorage(this.filterResult.filter);
        this.filterResult.type = FilterResultType.LOAD_VACANCIES;
        this.postUpdate(this.filterResult);
    }

    private postUpdate(value: FilterResult) {
        this.filterSubject.next(value);
    }

    public getFilterResultObservable() {
        return this.filterSubject.asObservable();
    }

    private getFilterFromQueryParams(queryParam: string): Filter {
        try {
            return JSON.parse(decodeURIComponent(escape(atob(queryParam))));
        } catch (e) {
            console.error('Cannot parse filter form query param. Returning from localstorage', e);
            return this.getFilterFromLocalStorage();
        }
    }

    private getFilterFromLocalStorage(): Filter {
        let jsonFilter = localStorage.getItem(Constants.Keys.filter);
        try {
            return !!jsonFilter ? JSON.parse(jsonFilter) : new Filter();
        } catch (e) {
            console.error('Cannot parse filter form local storage. Returning empty filter', e);
            return new Filter();
        }
    }

    private setFilterToLocalStorage(filter: Filter) {
        try {
            localStorage.setItem(Constants.Keys.filter, JSON.stringify(filter));
        } catch (e) {
            console.error('Cannot store filter to local storage.', e);
        }
    }

    private filterToQueryParam(filter: Filter) {
        if (!filter) {
            return null;
        }
        return btoa(unescape(encodeURIComponent(JSON.stringify(filter))));
    }

     getDefaultMenuItems():MenuItem[] {
        return  [
            {
                label: EtoTranslation.getInstant('select_region'),
                disabled: true,
                automationId: FilterType.REGION,
                items: []
            },
            {
                label: EtoTranslation.getInstant('select_service_type'),
                disabled: true,
                automationId: FilterType.SITE_TYPE,
                items: TypeMenuProvider.createMenuItems(Object.values(SiteType), null)
            },
            {
                label: EtoTranslation.getInstant('add_vehicle'),
                icon: 'vehicle-icon',
                disabled: true,
                automationId: FilterType.VEHICLE,
                items: []
            },
            {
                label: EtoTranslation.getInstant('select_job'),
                icon: 'job-icon',
                disabled: true,
                automationId: FilterType.JOB,
                items:[]
            },
            {
                label: EtoTranslation.getInstant('select_date'),
                icon: 'date-icon',
                disabled: true,
                automationId: FilterType.START_DATE,
                items:[]
            },
            {
                label: EtoTranslation.getInstant('select_time'),
                icon: 'time-icon',
                disabled: true,
                automationId: FilterType.TIME_WINDOW,
                items:[]
            },
            {
                label: EtoTranslation.getInstant('select_site'),
                icon: 'destination-icon',
                disabled: true,
                automationId: FilterType.DESTINATION,
                items:[]
            }
        ];
    }

    private updateResultWithMenuItems(filterMenu: MenuItem[]) {
        this.filterResult.menuItems = filterMenu;
        for (const filterOption of filterMenu) {
            switch (filterOption.automationId) {
                case FilterType.REGION:
                    this.filterResult.filter.selectedRegionId = this.findSelectedId(filterOption.items);
                    break;
            }
        }
    }

    private updateQueryParam() {
        this.filterResult.queryParam = this.filterToQueryParam(this.filterResult.filter);
    }

    private findSelectedId(items: MenuItem[]): number {
        let selected = items.filter(value => value.disabled);
        return selected.length > 0 ? Number.parseInt(selected[0].id) : undefined;
    }

    dateSelected(date: Date, notifier: Observable<any>) {
        this.filterResult.filter.selectedDay = date;
        this.onSelectionChanged([FilterType.START_DATE], notifier)
    }

    vehicleChanged(notifier: Observable<any>) {
        this.onSelectionChanged([FilterType.VEHICLE], notifier)
    }
}
