<div>
    <div *ngIf="shouldDisplaySimpleCard()" (click)="onVacancyClicked()" class="vacancy-simple-card">
        <div class="vacancy-group">
            <span class="vacancy-text">{{getReadableDate()}}</span>
            <span class="vacancy-text">{{getTime()}}</span>
        </div>
        <span class="vacancy-price">{{getPrice()}}</span>
        <div class="vacancy-group">
            <span class="vacancy-text">{{getServiceName()}}</span>
            <span class="vacancy-text">{{getAddress()}}</span>
        </div>
        <img class="vacancy-arrow" src="../../../../assets/images/arrow.svg"/>
    </div>

    <div *ngIf="shouldDisplayMultiCard()" (click)="onVacancyClicked()" class="vacancy-multi-card">
        <div class="vacancy-group">
            <span class="vacancy-text">{{getReadableDate()}}</span>
            <span class="vacancy-text">{{getTime()}}</span>
        </div>
        <span class="vacancy-price">{{getPrice()}}</span>
        <div class="vacancy-group">
            <span class="vacancy-text">{{getSitesCountText()}}</span>
        </div>
        <img class="vacancy-arrow" src="../../../../assets/images/arrow.svg"/>
    </div>

    <div *ngIf="shouldDisplayGroupCard()" (click)="onVacancyClicked()" class="vacancy-group-card">
        <div class="vacancy-group">
            <span class="vacancy-text-blue">{{getGroupTitle()}}</span>
            <span class="vacancy-text-blue">{{getGroupVacanciesCount()}}</span>
        </div>
        <span class="vacancy-price-blue">{{getPrice()}}</span>
    </div>

    <div *ngIf="shouldDisplayMarketingCard()" (click)="onVacancyClicked()" [ngClass]="shouldRenderSmallMarketingCard() ? 'vacancy-marketing-card-small' : 'vacancy-marketing-card'">
        <div class="promo" [ngStyle]="{'background-image': 'url(\''+getBackgroundImageUrl()+'\')'}">
            <div class="promo-gradient gradient-blue" >
                <h2 *ngIf="!isHandsetPortrait" style="color:white;margin:2px">{{getMarketingCardTitle()}}</h2>
                <h4 *ngIf="isHandsetPortrait" style="color:white;margin:2px">{{getMarketingCardTitle()}}</h4>
                <p style="color:white; margin-top:8px">{{getMarketingCardText()}}</p>
            </div>
        </div>
    </div>

    <div *ngIf="shouldDisplayShimmerCard()">
        <shimmer style="margin:16px" *ngIf="!isHandsetPortrait" borderRadius="10px" height="296px" width="248px"></shimmer>
        <shimmer style="margin:8px" *ngIf="isHandsetPortrait" borderRadius="10px" height="200px" width="150px"></shimmer>
    </div>
</div>
