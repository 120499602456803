import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {SimpleReservation} from "../../../model/api/response/simple-reservation";
import {EtoTranslation} from "../../../service/translation/eto-translation";
import {DateFormatter} from "../../../service/util/date-formatter";
import {LabelUtil} from "../../../service/util/label-util";

@Component({
    selector: 'app-reservations-list',
    templateUrl: './reservations-list.component.html',
    styleUrls: ['./reservations-list.component.scss'],
    encapsulation:ViewEncapsulation.None
})
export class ReservationsListComponent extends EtoComponent {

    @Input()
    items: SimpleReservation[];
    @Output()
    onReservationSelected: EventEmitter<SimpleReservation> = new EventEmitter<SimpleReservation>();

    getHeaders(): string[] {
        return [
            EtoTranslation.getInstant('site'),
            EtoTranslation.getInstant('reservation_job'),
            EtoTranslation.getInstant('date_time'),
            EtoTranslation.getInstant('state'),
            EtoTranslation.getInstant('price'),
        ]
    }

    getReservationDateAndTime(item: SimpleReservation) {
        return LabelUtil.getReservationDateFull(item.startDate);
    }

    getPrice(item: SimpleReservation){
        const guaranteedPrice = !!item.priceMetadata && item.priceMetadata.guaranteedPrice;
        if (!guaranteedPrice ){
            return LabelUtil.formatPriceFrom(item.price.toString());
        }
        else{
            return LabelUtil.formatPrice(item.price.toString());
        }
    }

    getStateText(item: SimpleReservation) {
        return LabelUtil.getReservationStateLabel(item.state);
    }

    onRowClicked(item: SimpleReservation) {
        this.onReservationSelected.emit(item);
    }
}
