<div [formGroup]="phoneForm">
    <ngx-intl-tel-input
            formControlName = "phone"
            (ngModelChange)="onPhoneChange($event)"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [customPlaceholder]="'0912 123 456'"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Slovakia"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.National">
    </ngx-intl-tel-input>
</div>
