import {VacancyCard, VacancyItemType} from "./vacancy-card";
import {PromoMessage} from "../../api/response/promo-message";

export class MarketingVacancyCard extends VacancyCard{
    message: PromoMessage;
    small: boolean;

    constructor() {
        super(VacancyItemType.MARKETING_CARD);
    }
}
