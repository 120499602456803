import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {EtoTranslationPipe} from "./eto-translation";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";




@NgModule({
    imports: [
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      })
    ],
  exports: [
    EtoTranslationPipe,
  ],
  declarations: [
    EtoTranslationPipe
  ]
})

export class TranslationModule {
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

