import {MenuItem} from "primeng/api";
import {EtoTranslation} from "../../translation/eto-translation";
import {MenuListener} from "./menu-listener";
// @ts-ignore
import versionJson from "src/environments/version.json";

export class MenuProvider {
    private listener: MenuListener;

    constructor(listener: MenuListener) {
        this.listener = listener;
    }

    getMenu(isSessionActive: boolean, userName: string, isMobile: boolean): MenuItem[] {
        let menuItems = isSessionActive ? this.menuForLoggedInUser(userName) : this.menuForLoggedOutUser();
        if (isMobile) {
            menuItems = menuItems.concat(this.menuForAboutSection());
        }
        return menuItems;
    }

    private menuForLoggedOutUser() {
        let items: MenuItem[] = [{
            items: [
                {
                    label: EtoTranslation.getInstant('login'),
                    icon: 'pi pi-user',
                    command: () => {
                        this.listener.onLogin();
                    }
                },
                {
                    label: EtoTranslation.getInstant('language', {language: EtoTranslation.getSelectedLang().toUpperCase()}),
                    icon: 'pi pi-flag',
                    command: () => {
                        this.listener.onLanguage();
                    }
                }
            ]
        }
        ];
        // items.push({
        //     separator: true
        // });
        // items = items.concat(this.menuForAboutSection());
        return items;
    }

    private menuForLoggedInUser(userName: string): MenuItem[] {
        let items: MenuItem[] = [{
            label: userName,
            items: [
                {
                    label: EtoTranslation.getInstant('your_profile'),
                    icon: 'pi pi-user',
                    command: () => {
                        this.listener.onProfile();
                    }
                },
                {
                    label: EtoTranslation.getInstant('your_reservations'),
                    icon: 'pi pi-list',
                    command: () => {
                        this.listener.onReservations();
                    }
                },
                {
                    label: EtoTranslation.getInstant('language', {language: EtoTranslation.getSelectedLang().toUpperCase()}),
                    icon: 'pi pi-flag',
                    command: () => {
                        this.listener.onLanguage();
                    }
                },
                {
                    label: EtoTranslation.getInstant('sign_out'),
                    icon: 'pi pi-power-off',
                    command: () => {
                        this.listener.onSignout();
                    }
                }
            ]
        }
        ];
        // items.push({
        //     separator: true
        // });
        // items = items.concat(this.menuForAboutSection());
        return items;
    }

    public menuForAboutSection() {
        return [{
            label: EtoTranslation.getInstant('app_name'),
            items: [
                {
                    label: EtoTranslation.getInstant('customer_support'),
                    icon: 'pi pi-envelope',
                    command: () => {
                        window.location.href = 'https://www.ernest.to/ernestto#kontakt';
                    }
                },
                {
                    label: EtoTranslation.getInstant('menu_about'),
                    icon: 'pi pi-info-circle',
                    command: () => {
                        window.location.href = 'https://www.ernest.to';
                    }
                },
                {
                    label: EtoTranslation.getInstant('android_app'),
                    icon: 'pi pi-android',
                    command: () => {
                        window.location.href = 'https://play.google.com/store/apps/details?id=sk.ernestto.portal';
                    }
                },
                {
                    label: EtoTranslation.getInstant('ios_app'),
                    icon: 'pi pi-mobile',
                    command: () => {
                        window.location.href = 'https://itunes.apple.com/app/id1641186217/';
                    }
                },
                {
                    label: EtoTranslation.getInstant('terms_and_conditions'),
                    icon: 'pi pi-briefcase',
                    command: () => {
                        window.location.href = 'https://www.ernest.to/vop';
                    }
                },
                {
                    label: EtoTranslation.getInstant('gdpr'),
                    icon: 'pi pi-key',
                    command: () => {
                        window.location.href = 'https://www.ernest.to/gdpr';
                    }
                },
                {
                    label: EtoTranslation.getInstant('blog'),
                    icon: 'pi pi-book',
                    command: () => {
                        window.location.href = 'https://www.ernest.to/blog-novinky';
                    }
                },
                {
                    label: versionJson.version,
                    disabled: true,
                },

            ]
        }];
    }
}
