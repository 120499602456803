import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../eto-component";

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent extends EtoComponent {
  code: string = '';
  @Output()
  codeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  codeCompleted: EventEmitter<string> = new EventEmitter<string>();
}
