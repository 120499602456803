import {Component, Injector, Input, OnInit} from '@angular/core';
import {EtoComponent} from "../../eto-component";
import {Site} from "../../../model/api/response/site";
import {LabelUtil} from "../../../service/util/label-util";
import {EtoTranslation} from "../../../service/translation/eto-translation";

@Component({
  selector: 'app-site-item',
  templateUrl: './site-item.component.html',
  styleUrls: ['./site-item.component.scss']
})
export class SiteItemComponent extends EtoComponent{


  constructor(injector: Injector) {
    super(injector);
  }

  @Input()
  site: Site;

  getSiteTypes(){
    if (!!this.site) {
      return LabelUtil.formatCarServiceTypes(this.site.type);
    }
    return EtoTranslation.getInstant('empty');
  }

  getAddress(){
    return LabelUtil.formatFullAddress(this.site.address, this.site.zipCode, this.site.city);
  }
}
