import {AbstractFilterLoader} from "./abstract-filter-loader";
import {JobTemplate} from "../../../model/api/response/job-template";
import {FilterResult} from "../filter-result";
import {MenuItem} from "primeng/api";
import {FilterType} from "../filter-type";
import {FilterMenuProvider} from "../../menu/filter/filter-menu-provider";
import {EtoTranslation} from "../../translation/eto-translation";

export class JobsFilterLoader extends AbstractFilterLoader<JobTemplate[]>{

    protected convertDataToMenuItems(data: JobTemplate[], filterResult: FilterResult): MenuItem {
        let items = FilterMenuProvider.getJobTemplatesMenu(data, jobTemplate => {
            this.templateSelected(jobTemplate, true);
        });
        this.result =  {
            automationId: FilterType.JOB,
            icon:'job-icon',
            items: items
        };
        if (data.length == 0){
            this.templateSelected(null, false);
        }
        let selectedJobId = this.filterResult.filter.selectedJobTemplateId;
        const selection = !!selectedJobId && data.flatMap(value => value.id).indexOf(selectedJobId) !=-1
            ? data.filter(value => value.id == selectedJobId)[0]
            : data[0];
        this.templateSelected(selection, false);
        return this.result;
    }

    private templateSelected(jobTemplate: JobTemplate, manual: boolean) {
        if (jobTemplate == null){
            this.result.label = EtoTranslation.getInstant('no_jobs');
            this.result.disabled = true;
            this.filterResult.filter.selectedJobTemplateId = null;
            this.filterResult.filter.selectedJobName = null;
            return;
        }
        this.result.label = EtoTranslation.getName(jobTemplate);
        this.result.items.forEach(value => value.disabled = false);
        let selected = this.result.items.filter(value => value.id == jobTemplate.id.toString());
        if (selected.length > 0) {
            selected[0].disabled = true;
            this.filterResult.filter.selectedJobTemplateId = Number.parseInt(selected[0].id);
            this.filterResult.filter.selectedJobName = selected[0].label;
        }
        if (manual){
            this.onItemSelectedManually();
        }
        else{
            this.onItemSelected();
        }
    }

    protected getDataFromApi(onSuccess: (data: JobTemplate[]) => void): void {
        this.updateProgress(true);
        this.apiService.getJobTemplates(this.filterResult.filter.selectedType).execute(this.notifier, data => {
            this.updateProgress(false);
            onSuccess(data);
        }, apiError => {
            this.updateWithError(apiError)
            this.updateProgress(false);
        })
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [];
    }

}
