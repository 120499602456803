import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {Constants} from "../../constants/constants";
import {SiteDetail} from "../../model/api/response/site-detail";
import {OpeningHour} from "../../model/api/response/opening-hour";
import {LabelUtil} from "../../service/util/label-util";
import {DayIndex} from "../../model/api/response/day-index";
import {takeUntil} from "rxjs/operators";
import {MenuItem} from "primeng/api";
import {FilterType} from "../../service/filter/filter-type";
import {VacanciesComponent} from "../../components/vacancies-component";
import {Filter} from "../../model/ui/filter";
import {SiteType} from "../../model/api/response/site-type";
// @ts-ignore
import Marker = google.maps.Marker;

@Component({
  selector: 'app-site',
  templateUrl: './site.screen.html',
  styleUrls: ['./site.screen.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteScreen extends VacanciesComponent{


  site: SiteDetail;
  filterMenuItems:MenuItem[];
  options: any;
  overlays: any;


  constructor(injector: Injector) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;
    const id = this.route.snapshot.paramMap.get(Constants.PathParam.id);
    this.loadSite(id);
  }

  private prepareFilter() {
    this.filterService.getFilterResultObservable().pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.processFilterResult(value);
    })
    this.initFilter();
  }


  protected override bindMenuItems(items: MenuItem[]) {
    super.bindMenuItems(items);
    this.filterMenuItems = this.filterFilterItems(items);
  }


  protected override getProcessedFilter(result: Filter): Filter {
    const copy: Filter = JSON.parse(JSON.stringify(result));
    copy.selectedRegionId = null;
    copy.selectedSiteId = this.site.id;
    if (this.site.type.length == 1){
      copy.selectedType = this.site.type[0];
    }
    return copy;
  }

  private filterFilterItems(menuItems: MenuItem[]) {
    return menuItems.filter(value => {
      if (this.site.type.length == 1 && value.automationId == FilterType.SITE_TYPE){
        let menuItemToSelect = value.items.filter(typeItem => typeItem.id == Object.values(SiteType).indexOf(this.site.type[0]).toString())[0];
        let selectedMenuItem = value.items.filter(typeItem => typeItem.disabled)[0];
        if (!!menuItemToSelect && selectedMenuItem && menuItemToSelect.id != selectedMenuItem.id) {
          menuItemToSelect.command();
        }
        return false;
      }
      return [FilterType.REGION, FilterType.DESTINATION].indexOf(value.automationId) == -1;
    });
  }

  private loadSite(id: string) {
    this.apiService.getSite(id).execute(this.destroyed$, data => {
      this.site = data;
      this.isLoading = false;
      this.prepareFilter();
      this.prepareMap();
    },apiError => {
      this.showGenericError();
      this.isLoading = false;
    })
  }

  getHourText(hour: OpeningHour) {
      return LabelUtil.formatOpeningHour(hour);
  }

  getSoredHours() {
    return this.site.openingHours
        .sort((a, b) => Object.values(DayIndex).indexOf(a.dayIndex) - Object.values(DayIndex).indexOf(b.dayIndex));
  }

  private prepareMap() {
    this.options = {
      center: {lat: this.site.lat, lng: this.site.lng},
      zoom: 16
    };
    this.overlays = [
        new Marker({position: {lat: this.site.lat, lng: this.site.lng}, title:this.site.name}),
    ];
  }

  onSiteMapClicked($event: any) {
    window.location.href = `https://www.google.com/maps/search/?api=1&query=${this.site.name},${this.site.lat},${this.site.lng}`;
  }
}
