<p-toast></p-toast>
<app-calendar-modal [(display)]="calendarDisplayed" (onDateSelected)="onDateSelected($event)"></app-calendar-modal>
<app-vehicle-modal [vehicle]="vehicle" [(display)]="vehicleModalDisplayed" (onVehicleCreatedOrUpdate)="onVehicleUpdated($event)"></app-vehicle-modal>
<p-sidebar [dismissible]="true" [blockScroll]="true" *ngIf="!!groupSections" [(visible)]="groupVacanciesResultVisible" position="right" [baseZIndex]="10000">
    <app-vacancies-list [loadingAll]="false"
                        [vehicleCreated]="isVehicleCreated()"
                        (onSingleCardClicked)="onSingleCarClicked($event)"
                        (onMultiCardClicked)="onMultiCardClicked($event)"
                        [sections]="groupSections">
    </app-vacancies-list>
</p-sidebar>
<p-sidebar [dismissible]="true" [blockScroll]="true" *ngIf="vacancyPreviewVisible"
           [(visible)]="vacancyPreviewVisible" position="right" [baseZIndex]="10000">
    <app-vacancy-preview [vacancyPreview]="vacancyPreview" (onVacancyConfirmed)="onVacancySelected($event)">
    </app-vacancy-preview>
</p-sidebar>
<app-empty-state *ngIf="!isLoading && !site" [text]="'error_generic'|etotranslate"></app-empty-state>
<div *ngIf="!isLoading">
    <div class="hero-container" *ngIf="!!site">
        <button pButton pRipple type="button" icon="pi pi-angle-left"
                *ngIf="isHandsetPortrait"
                class="p-button-rounded p-button-text"
                (click)="onBack()"
                style="position:absolute; top:8px; left:8px">
        </button>
        <div class="site-title-bar">
            <button pButton pRipple type="button" icon="pi pi-angle-left"
                    *ngIf="!isHandsetPortrait"
                    class="p-button-rounded p-button-text"
                    (click)="onBack()">
            </button>
            <img *ngIf="site.logo" class="rounded-image-site" [src]="site.logo.photoUrl" [alt]="site.name"/>
            <span *ngIf="!site.logo" class="rounded-text-site">{{site.name}}</span>
            <div style="display:flex;flex-direction:column">
                <h1 *ngIf="!isHandsetPortrait"
                    style="margin-left: 64px; margin-top:16px;margin-bottom:0">{{site.name}}</h1>
                <h3 *ngIf="!isHandsetPortrait"
                    style="margin-left: 64px; margin-top:16px;margin-bottom:0">{{site.city}}</h3>
                <h4 *ngIf="isHandsetPortrait" style="margin-left: 32px; margin-top:0;margin-bottom:0">{{site.name}}</h4>
                <p *ngIf="isHandsetPortrait" style="margin-left: 32px; margin-top:0;margin-bottom:0">{{site.city}}</p>
                <div class="types-container">
                    <span *ngFor="let type of site.type" [ngClass]="'icon-'+type.toString()" style="width: 18px;height: 18px;"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="site-content-container">
        <div class="site-side-section" id="site-gallery">
            <div class="site-section-item" *ngIf="!!site.gallery && site.gallery.length > 0">
                <h4>{{'gallery'|etotranslate}}</h4>
                <div class="site-gallery" style="min-width:220px">
                    <p-image *ngFor="let photo of site.gallery"
                             [src]="photo.photoUrl"
                             [alt]="site.name"
                             width="100"
                             height="100"
                             styleClass="site-gallery-image"
                             [preview]="true">
                    </p-image>
                </div>
            </div>
        </div>
        <div class="site-vacancies-container" id="site-vacancies">
            <h4>{{'vacancies'|etotranslate}}</h4>
            <p-menubar [model]="filterMenuItems">
                <ng-template pTemplate="end">
                    <i class="pi pi-spin pi-spinner font-icon" *ngIf="loadingFilter"></i>
                </ng-template>
            </p-menubar>
            <app-vacancies-list [sections]="vacancySections"
                                [vehicleCreated]="isVehicleCreated()"
                                [loadMoreEnabled]="true"
                                (onSingleCardClicked)="onSingleCarClicked($event)"
                                (onMultiCardClicked)="onMultiCardClicked($event)"
                                (onGroupCardClicked)="onGroupCardClicked($event)"
                                (onShowMoreClicked)="loadMoreVacancies()"
                                [isLoading]="loadingFilter"
                                [loadingAll]="loadingAllVacancies">
            </app-vacancies-list>
        </div>
        <div class="site-side-section" id="site-info">
            <div class="site-section-item">
                <h4>{{'address'|etotranslate}}</h4>
                <p>{{site.name}}</p>
                <p>{{site.address}}</p>
                <p>{{site.zipCode}}, {{site.city}}</p>
            </div>
            <div class="site-section-item">
                <h4>{{'contact'|etotranslate}}</h4>
                <p>{{site.phoneNumber}}</p>
                <p>{{site.email}}</p>
            </div>
            <div class="site-section-item">
                <div class="site-section-item">
                    <h4>{{'opening_hours'|etotranslate}}</h4>
                    <p *ngFor="let hour of getSoredHours()">{{getHourText(hour)}}</p>
                </div>
            </div>

            <div class="site-section-item">
                <h4>{{'map'|etotranslate}}</h4>
                <p-gmap *ngIf="!!options && !!overlays"
                        [options]="options" [overlays]="overlays"
                        (onOverlayClick)="onSiteMapClicked($event)"
                        [style]="{'width':'320px','height':'320px'}" ></p-gmap>
            </div>
        </div>
    </div>
</div>
