import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {ApiService} from "../api/api.service";
import {Constants} from "../../constants/constants";
import {Injectable} from "@angular/core";

@Injectable()
export class AuthGuard implements CanActivate{

    constructor(private apiService: ApiService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.apiService.isUserLoggedIn()){
            console.error('Auth guard stopped processing route', route.url);
            this.router.navigate([Constants.Route.home]);
            return false;
        }
        return true;
    }
}
