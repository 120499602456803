import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {EtoComponent} from "../../components/eto-component";
import {Vehicle} from "../../model/api/response/vehicle";
import {VehicleCategory} from "../../model/api/response/vehicle-category";
import {EtoTranslation} from "../../service/translation/eto-translation";
import {VehicleCategoryMenuItem} from "../../model/ui/vehicle-category-menu-item";
import {VehicleOptionsMenuProvider} from "../../service/menu/options/vehicle-options-menu-provider";
import {VehicleOption} from "../../model/api/response/vehicle-option";

@Component({
    selector: 'app-vehicle-modal',
    templateUrl: './vehicle-modal.component.html',
    styleUrls: ['./vehicle-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VehicleModalComponent extends EtoComponent implements OnChanges{
    @Input()
    display: boolean;
    @Input()
    vehicle: Vehicle;
    @Output()
    displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    onVehicleCreatedOrUpdate: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();

    options: VehicleCategory[];

    loadingOptions: boolean;

    license: string;
    description: string;
    optionsMenuItems: VehicleCategoryMenuItem[];


    override ngOnInit() {
        super.ngOnInit();
        this.loadOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.vehicle){
            this.license = this.vehicle.license;
            this.description = this.vehicle.description;
        }
        this.updateOptions();
    }

    private updateOptions() {
        if (!!this.options) {
            this.optionsMenuItems = VehicleOptionsMenuProvider.getMenu(this.vehicle, this.options);
        }
    }

    isLocalVehicle(){
        return !this.apiService.isUserLoggedIn();
    }

    private loadOptions() {
        this.loadingOptions = true;
        this.apiService.getVehicleOptions().execute(this.destroyed$, data => {
                this.loadingOptions = false;
                this.options = data;
                this.updateOptions();
            },
            apiError => {
                this.loadingOptions = false;
                this.showGenericError()
            })
    }

    getTitle() {
        return EtoTranslation.getInstant(!this.vehicle ? 'add_vehicle' : 'edit')
    }

    saveEnabled(){
        if (this.apiService.isUserLoggedIn()){
            return !!this.license && !!this.description;
        }
        else{
            return true;
        }
    }

    onSaveClicked() {
        if (!this.vehicle){
            this.vehicle = new Vehicle();
        }
        this.vehicle.license = this.license;
        this.vehicle.description = this.description;
        this.menuItemsToVehicleItems();
        this.onVehicleCreatedOrUpdate.emit(this.vehicle);
        this.display = false;
        this.displayChange.emit(this.display);
    }

    menuItemsToVehicleItems(){
        this.vehicle.categories = [];
        this.optionsMenuItems.forEach(value => {
            let vehicleCategory = new VehicleCategory();
            vehicleCategory.category = new VehicleOption();
            vehicleCategory.category.id = value.value.category.id;
            vehicleCategory.category.skName = value.value.category.skName;
            vehicleCategory.category.enName = value.value.category.enName;
            vehicleCategory.category.skSubtitle = value.value.category.skSubtitle;
            vehicleCategory.category.enSubtitle = value.value.category.enSubtitle;
            let option = new VehicleOption();
            option = new VehicleOption();
            option.id = value.selected.value.id;
            option.skName = value.selected.value.skName;
            option.enName = value.selected.value.enName;
            option.skSubtitle = value.selected.value.skSubtitle;
            option.enSubtitle = value.selected.value.enSubtitle;
            vehicleCategory.items = [option];
            this.vehicle.categories.push(vehicleCategory);
        })
    }
}
