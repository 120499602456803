import {AbstractFilterLoader} from "./abstract-filter-loader";
import {Vehicle} from "../../../model/api/response/vehicle";
import {FilterResult} from "../filter-result";
import {MenuItem} from "primeng/api";
import {FilterType} from "../filter-type";
import {Constants} from "../../../constants/constants";
import {EtoTranslation} from "../../translation/eto-translation";

export class VehicleFilterLoader extends AbstractFilterLoader<Vehicle> {

    protected convertDataToMenuItems(data: Vehicle, filterResult: FilterResult): MenuItem {
        this.result = {
            automationId: FilterType.VEHICLE,
            icon: 'vehicle-icon',
            label: this.getTitleForVehicle(data),
            id: this.getSafeId(data),
            items: [{
                label: this.getActionLabelForVehicle(data),
                id: `vehicle-action${this.getSafeId(data)}`,
            }]
        };
        if (!!data) {
            this.filterResult.filter.selectedCategoryItemsIds = data.categories.flatMap(value => value.items).map(value => value.id);
        }
        else{
            this.filterResult.filter.selectedCategoryItemsIds = [];
        }
        return this.result;
    }

    private getSafeId(data: Vehicle) {
        return !!data && !!data.id ? data.id.toString() : '0';
    }

    private getTitleForVehicle(vehicle: Vehicle) {
        if (!vehicle) {
            return EtoTranslation.getInstant('no_vehicle');
        }
        if (!vehicle.license) {
            return this.getTitleForLocalVehicle(vehicle);
        }
        return vehicle.license;
    }

    private getTitleForLocalVehicle(vehicle: Vehicle) {
        return vehicle.categories.flatMap(value => value.items)
            .map(value => EtoTranslation.getName(value))
            .join(' / ');
    }

    private getActionLabelForVehicle(data: Vehicle) {
        if (!!data) {
            return EtoTranslation.getInstant('edit');
        }
        return EtoTranslation.getInstant('add_vehicle');
    }

    protected getDataFromApi(onSuccess: (data: Vehicle) => void): void {
        if (this.apiService.isUserLoggedIn()) {
            this.updateProgress(true);
            this.apiService.getVehicles().execute(this.notifier, data => {
                this.updateProgress(false);
                onSuccess(data.length > 0 ? data[0] : this.getLocalVehicle())
            }, apiError => {
                this.updateProgress(false);
                this.updateWithError(apiError);
            })
        } else {
            onSuccess(this.getLocalVehicle());
        }
    }

    private getLocalVehicle(): Vehicle {
        let item = localStorage.getItem(Constants.Keys.localVehicle);
        if (!item) {
            return null;
        }
        try {
            return JSON.parse(item);
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    protected getFiltersToUpdateAfterChange(): FilterType[] {
        return [];
    }
}
