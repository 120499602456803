import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {EtoTranslation} from 'src/app/service/translation/eto-translation';
import {EtoComponent} from "../../eto-component";
import {Button} from "primeng/button";

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent extends EtoComponent implements OnInit {

  @ViewChild('langbutton') langbutton: ElementRef<HTMLElement>;

  @Input()
  hidden:boolean;

  languages: MenuItem[];

  selectedLanguage: string;

  override ngOnInit(): void {
    super.ngOnInit();
    this.updateSelectedLanguage(EtoTranslation.getSelectedLang().toLowerCase());
  }

  private updateSelectedLanguage(language: string) {
    this.selectedLanguage = language;
    this.languages = [
      {label: 'Slovenčina', icon: 'flag_sk', disabled: language == 'sk', command: ()=> this.onSelectedLanguage("sk")},
      {label: 'English', icon: 'flag_en', disabled: language == 'en', command: ()=> this.onSelectedLanguage("en")}
    ];
  }

  onSelectedLanguage(selectedLanguage: any) {
    EtoTranslation.setLanguage(selectedLanguage);
    window.location.reload();
  }

  getSelectedLangIcon() {
    let menuItems = this.languages.filter(value => value.disabled);
    return menuItems[0].icon;
  }

  simulateClick(){
    this.langbutton.nativeElement.click();
  }
}
